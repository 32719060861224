import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TempoOauthSuccessComponent } from './components/tempo-oauth-success/tempo-oauth-success.component';


const routes: Routes = [
  { path: 'tempo_oauth_success', component:  TempoOauthSuccessComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
