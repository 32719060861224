import { Component, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, HostListener, OnDestroy, OnInit } from '@angular/core';
import { JiraconnectorService } from './jiraconnector/jiraconnector.service';

import { MatDialog } from '@angular/material/dialog';
import { ProjectService } from './services/project.service';
import { DataDescription } from './model/DataDescription';
import { SocketMessage } from './model/SocketMessage';
import { LocalStorageWorker } from './model/LocalStorageWorker';
import { REPORT_MODE, SOCKET_SUBJECT, DATA_TYPE } from './model/enums';
import { LiquidityTableComponent } from './components/liquidity-table/liquidity-table.component';
import { SocketService } from './services/socket.service';
import { environment } from './../environments/environment';
import { LiquidityService } from './services/liquidity.service';
import { AP$ } from 'src/polyfills';
import { TempoIoService } from './services/tempo.io.service';

interface DateMonth {
  value: Date;
  viewValue: string;
}


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AppComponent implements OnDestroy, OnInit {

  @ViewChild('LiquidityTable') oLiquidityTable: LiquidityTableComponent;

  REPORT_MODE = REPORT_MODE;
  environment = environment;

  public tabIndex = 0;

  message: string;
  messages: string[] = [];

  private _dateMonths: DateMonth[];
  public get dateMonths(): DateMonth[] {

    if (this._dateMonths === undefined) {
      const ar_oDateMonth: DateMonth[] = [];

      const startDate = new Date(2019, 6, 1);
      const endDate = new Date(new Date().getFullYear() + 3, 0, 1);

      let actDate = new Date(startDate);
      do {
        const viewValue: string = '' + actDate.getFullYear() + (actDate.getMonth() >= 6 ? '/' + (actDate.getFullYear() - 1999) : '');

        ar_oDateMonth.push({ value: actDate, viewValue });

        startDate.setMonth(startDate.getMonth() + 6);

        actDate = new Date(startDate);

      } while (actDate < endDate);

      this._dateMonths = ar_oDateMonth;
    }
    return this._dateMonths;
  }

  private _datesLiquidity: DateMonth[];
  public get datesLiquidity(): DateMonth[] {

    if (this._datesLiquidity === undefined) {
      const ar_oDateLiquidity: DateMonth[] = [];

      const startDate = new Date(2020, 0, 1);
      const endDate = new Date(new Date().getFullYear() + 5, 0, 1);

      let actDate = new Date(startDate);
      do {
        const viewValue: string = '' + actDate.getFullYear();

        ar_oDateLiquidity.push({ value: actDate, viewValue });

        startDate.setMonth(startDate.getMonth() + 12);

        actDate = new Date(startDate);

      } while (actDate < endDate);

      this._datesLiquidity = ar_oDateLiquidity;
    }
    return this._datesLiquidity;
  }

  selectedDateMonthView: Date = this.dateMonths[3].value;
  selectedDateMonthViewLabel: string = this.dateMonths[3].viewValue;

  selectedDateLiquidityView: Date = this.datesLiquidity[1].value;
  selectedDateLiquidityViewLabel: string = this.datesLiquidity[1].viewValue;

  constructor(
    public _jiraconnector: JiraconnectorService,
    private _ChangeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,

    public projectService: ProjectService,
    public liquidityService: LiquidityService,
    public socketService: SocketService,

    public tempoIoService: TempoIoService,
  ) {
  }

  public ngOnInit(): void {
    const _self = this;

    this.socketService
      .getMessages()
      .subscribe((message: any) => {
        this.messages.push(message.body);
        this._ChangeDetectorRef.detectChanges();
      });

    this.socketService
      .handleUserManagement()
      .subscribe((message: any) => {
        this.messages.push(message.body);
        this._ChangeDetectorRef.detectChanges();
      });

    this.socketService
      .handleDataChange()
      .subscribe((message: any) => {

        const dataDescription: DataDescription = JSON.parse(message.body);

        if (dataDescription.uuid !== this.socketService.sessionUser.getUUID()) {

          switch (message.subject) {
            case SOCKET_SUBJECT.DATA_CHANGE_PROJECT_LIST:

              this.projectService.getProjectsFromDataStore();

              break;

            case SOCKET_SUBJECT.DATA_CHANGE_PROJECT_DETAIL:

              break;

            case SOCKET_SUBJECT.DATA_CHANGE_LIQUIDITY_TABLE:

              this.oLiquidityTable.reloadLiqudityTableData();

              break;

              case SOCKET_SUBJECT.REQUEST_TEMPO_ACCESS:

                AP$.cookie.read('tempo_access_token', (tempo_access_token) => {
                  AP$.cookie.read('tempo_refresh_token', (tempo_refresh_token) => {
                    const dataDescription: DataDescription = new DataDescription();
                    dataDescription.type = DATA_TYPE.SEND_TEMPO_ACCESS;
                    dataDescription.body = {
                      tempo_access_token,
                      tempo_refresh_token
                    };
                    _self.socketService.dataChange(dataDescription);

                  });
                });

                break;

            default:

              break;
          }

        }

        this.messages.push(message.body);
        this._ChangeDetectorRef.detectChanges();
      });

    this.connectSessionUser();

    this.tabIndex = parseInt(LocalStorageWorker.instance.get('tabIndex'), 10);

    const projectListSubscribtions = this.projectService.projectSubject.subscribe(() => {
      projectListSubscribtions.unsubscribe();

      const monthDate_selectedDate = LocalStorageWorker.instance.get('monthDate_selectedDate');
      if (monthDate_selectedDate !== undefined && monthDate_selectedDate !== null) {

        const dateMonth = new Date(monthDate_selectedDate);

        const item = this.dateMonths.find(item => item.value.getTime() === dateMonth.getTime());

        if (item) {
          this.selectedDateMonthView = item.value;
          this.selectedDateMonthViewLabel = item.viewValue;
        }
      }

      const liquidityDate_selectedDate = LocalStorageWorker.instance.get('liquidityDate_selectedDate');
      if (liquidityDate_selectedDate !== undefined && liquidityDate_selectedDate !== null) {

        const dateLiquidity = new Date(liquidityDate_selectedDate);

        const item = this.datesLiquidity.find(item => item.value.getTime() === dateLiquidity.getTime());

        if (item) {
          this.selectedDateLiquidityView = item.value;
          this.selectedDateLiquidityViewLabel = item.viewValue;
        }
      }

      this._ChangeDetectorRef.detectChanges();

    });

    this.projectService.getProjectsFromDataStore();

    this._ChangeDetectorRef.detectChanges();


    /*
    AP$.getLocation(function(location){
      if (location.indexOf('code') === -1) {

        setTimeout(() => {

          var parse_url = /^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/;
          var parts = parse_url.exec( location );
          var baseUrl = parts[1] + ':' + parts[2] + parts[3];

          _self.openWindow(baseUrl +
            '/plugins/servlet/ac/io.tempo.jira/oauth-authorize/?client_id=BfiISvGS9wh1JqNgUoD0h94bIV1NcNgQJhUjMIGJ&redirect_uri=' +
            'https://projektionisten.atlassian.net/plugins/servlet/ac/dev-ml-p-ressources-dev-addon/' +
            'dev-ml-p-ressources-jira/tempo_oauth_success&access_type=tenant_user');
          //this.oTempoOauthDialog.openDialog();
        }, 500);

      } else {
        const regex = /.*code=/

        _self._jiraconnector.tempoOauthToken = location.replace(regex, '');
      }

    });
    */
  }

  @HostListener('window:beforeunload')
  async ngOnDestroy() {
    this.disconnectSessionUser();
  }

  changeMonthDate(dateMonth: any) {
    const item = this.dateMonths.find(item => item.value === dateMonth.value);

    if (item) {
      this.selectedDateMonthViewLabel = item.viewValue;

      LocalStorageWorker.instance.set('monthDate_selectedDate', item.value.toString());

      this._ChangeDetectorRef.detectChanges();
    }
  }

  changeLiquidityDate(dateLiquidity: any) {
    const item = this.datesLiquidity.find(item => item.value === dateLiquidity.value);

    if (item) {
      this.selectedDateLiquidityViewLabel = item.viewValue;

      LocalStorageWorker.instance.set('liquidityDate_selectedDate', item.value.toString());

      this._ChangeDetectorRef.detectChanges();
    }
  }


  connectSessionUser(): void {
    this._jiraconnector.getSessionUser().subscribe(session => {
      session.userAgent = window.navigator.userAgent;

      const oSocketMessage: SocketMessage = new SocketMessage();
      oSocketMessage.subject = SOCKET_SUBJECT.USER_CONNECT;
      oSocketMessage.body = JSON.stringify(session);
      this.socketService.userManagement(oSocketMessage);
    });
  }

  disconnectSessionUser(): void {
    const oSocketMessage: SocketMessage = new SocketMessage();
    oSocketMessage.subject = SOCKET_SUBJECT.USER_DISCONNECT;
    oSocketMessage.body = JSON.stringify(this.socketService.sessionUser);
    this.socketService.userManagement(oSocketMessage);
  }

  sendMessage(): void {
    const oSocketMessage: SocketMessage = new SocketMessage();
    oSocketMessage.body = this.message;
    this.socketService.sendMessage(oSocketMessage);
    this.message = '';
  }

  onSelectedTabChange(event): void {
    console.log(this.tabIndex);
    LocalStorageWorker.instance.set('tabIndex', this.tabIndex.toString());
    if (this.tabIndex === 2 || this.tabIndex === 3 || this.tabIndex === 4) {
      this.oLiquidityTable.reloadLiqudityTableData();
    }
  }

  copyProdToDev(): void {
    this.projectService.copyProdToDev();
    this.liquidityService.copyProdToDev();
  }

  openWindow(url) {
    const win = window.open(url, '_top');
    win.focus();
  }

}
