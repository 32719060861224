import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Project } from 'src/app/model/Project';
import { TimeSheetRow } from 'src/app/model/Timesheet';

@Component({
  selector: 'app-timesheet-row-funding-selector',
  templateUrl: './timesheet-row-funding-selector.component.html',
  styleUrls: ['./timesheet-row-funding-selector.component.scss']
})
export class TimesheetRowFundingSelectorComponent implements OnInit {

  @Input() timesheetRow: TimeSheetRow;
  @Input() fundingProjects: Project[];

  @Output() refresh = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o1 === o2.selectedFundingProjectId) {
      return true;
    } else if (o1 === o2.projectId) {
      return true;
    } else {
      return false;
    }
  }

  selectFundingProject(timesheetRow: TimeSheetRow, event) {
    timesheetRow.selectedFundingProjectId = event.value;

    this.refresh.emit();
  }

}
