
<div *ngFor="let settingItem of settings.items">
    <span>
        {{settingItem.label}}
    </span>

    <app-jira-group-selector groups="groups"></app-jira-group-selector>

</div>

