<div class="table-container mat-elevation-z8">
    <table mat-table #ProjectSubBudgetTable [dataSource]="subBudgets" multiTemplateDataRows>
        <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
            <td mat-cell *matCellDef="let row" class="column-{{columnsToDisplayInfo[column].class}}" [class.text-center]="column != 'name'">
                <div *ngIf="column == 'name'">
                    <b>{{row['name']}}</b> | <b>{{row['budgetString']}}</b> ({{row['sum_invoices_nettoString']}}) | {{row['startDateString']}} - {{row['endDateString']}} | Zahlungsziel: {{row['clearingAddDays']}} Tage | {{row['pt']}} PT | {{row['ptBudgetString']}}
                    <!--{{row['nameString']}}-->
                </div>
                <app-cell-item-renderer [valueObject]="row" [key]="columnsToDisplayInfo[column].key" [editable]="false" *ngIf="column != 'tools' && column != 'name'"></app-cell-item-renderer>
                <!--
                <mat-chip [class.chip-color-green]="row.getValue(columnsToDisplayInfo[column].key) > 200000" *ngIf="column != 'tools' && column != 'name'">
                    <span>{{row.getValue(columnsToDisplayInfo[column].key) | currency:'EUR':'€':'0.2':'de'}}</span>
                </mat-chip>
                -->
                <!-- Tools Definition -->
                <button mat-button (click)="removeSubBudgetFromList(row); $event.stopPropagation();" *ngIf="column == 'tools'"><mat-icon>delete</mat-icon></button>
            </td>
        </ng-container>
        <!-- Expanded Content Column - Edit SubBudget -->
        <ng-container matColumnDef="editSubBudget">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                <!--<div style="height: 100px;" *ngIf="element == expandedElement">xx</div>-->
                <app-sub-budget-input [type]="VIEW_TYPE.EDIT" [project]="project" [oProjectSubBudgetTable]="oProjectSubBudgetTable" [oSubBudget]="element" [state]="element == expandedElement ? VIEW_STATE.EDIT : VIEW_STATE.HIDE"
                    [columnsToDisplay]="columnsToDisplay" [columnsToDisplayInfo]="columnsToDisplayInfo" *ngIf="element == expandedElement"></app-sub-budget-input>
            </td>
        </ng-container>
        <tr mat-row
            class="sub-budget-row" [class.prognose-0]="element.prognoseNumber == 0" [class.prognose-25]="element.prognoseNumber == 0.25" [class.prognose-50]="element.prognoseNumber == 0.5" [class.prognose-75]="element.prognoseNumber == 0.75"
            (click)="expandedElement = expandedElement === element ? null : element; this._ChangeDetectorRef.detectChanges();"
            *matRowDef="let element; columns: columnsToDisplay;"></tr>
        <tr mat-row *matRowDef="let row; columns: ['editSubBudget']" class="edit-row"></tr>
    </table>
    <app-sub-budget-input [type]="VIEW_TYPE.NEW" [project]="project" [oProjectSubBudgetTable]="oProjectSubBudgetTable" [columnsToDisplay]="columnsToDisplay" [columnsToDisplayInfo]="columnsToDisplayInfo" *ngIf="project.type != PROJECT_TYPE.GROUP"></app-sub-budget-input>
    <app-project-group-input [oProjectTable]="oProjectTable" [project]="project" *ngIf="project.type != PROJECT_TYPE.GROUP"></app-project-group-input>
</div>

<ng-template #deleteDialog>
    <h2 matDialogTitle>Löschen?</h2>
    <mat-dialog-content>
    <p>Soll das SubBudget</p>
    <p><b>{{selectedSubBudget.nameString}}</b></p>
    <p>wirklich aus der Liste gelöscht werden?</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
    <button mat-button matDialogClose="cancel">Abbrechen</button>
    <button mat-button matDialogClose="confirm" color="warn">Löschen</button>
    </mat-dialog-actions>
</ng-template>

