import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JiraconnectorModule } from './jiraconnector/jiraconnector.module';
import { JIRA_URL, JIRA_AUTH_URL } from './jiraconnector/jiraconnector.service';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SubBudgetInputComponent } from './components/sub-budget-input/sub-budget-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { SelectProjectDialogComponent, DialogContentSelectProjectComponent } from './components/select-project-dialog/select-project-dialog.component';
import { ProjectService } from './services/project.service';
import * as moment from 'moment';
import { SubBudgetListComponent } from './components/sub-budget-list/sub-budget-list.component';
import { InvoiceListComponent } from './components/invoice-list/invoice-list.component';
import { InvoiceInputComponent } from './components/invoice-input/invoice-input.component';
import { CustomDateAdapter } from './adapter/custom.date.adapter';
import { ProjectListComponent } from './components/project-list/project-list.component';
import { ProjectGroupInputComponent } from './components/project-group-input/project-group-input.component';
import { LiquidityTableComponent } from './components/liquidity-table/liquidity-table.component';
import { CellItemRendererComponent } from './components/cell-item-renderer/cell-item-renderer.component';
import { SocketService } from './services/socket.service';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { ChartsModule } from 'ng2-charts';

import { OpenLiquidityChartDialogComponent, LiquidityChartComponent } from './components/liquidity-chart/liquidity-chart.component';
import { RessourcesTableComponent } from './components/ressources-table/ressources-table.component';

import { SatDatepickerModule, SatNativeDateModule, MAT_DATE_LOCALE as SAT_MAT_DATE_LOCALE } from 'saturn-datepicker';
import { ProjectWorkloadItemRendererComponent } from './components/project-workload-item-renderer/project-workload-item-renderer.component';
import { TempoOauthSuccessComponent } from './components/tempo-oauth-success/tempo-oauth-success.component';
import { TempoIoService, TEMPO_IO_URL, TEMPO_IO_AUTH_URL } from './services/tempo.io.service';
import { AllInvoicesListComponent } from './components/all-invoices-list/all-invoices-list.component';
import { TimesheetsTableComponent } from './components/timesheets-table/timesheets-table.component';
import { TimesheetFundingProjectSelectorComponent } from './components/timesheet-funding-project-selector/timesheet-funding-project-selector.component';
import { NgxPrintModule } from 'ngx-print';
import { SettingsComponent } from './components/settings/settings.component';
import { JiraGroupSelectorComponent } from './components/jira-group-selector/jira-group-selector.component';
import { JiraUserSelectorComponent } from './components/jira-user-selector/jira-user-selector.component';
import { TimeStatementTableComponent } from './components/time-statement-table/time-statement-table.component';
import { TimeStatementHourlyRateTableComponent } from './components/time-statement-hourly-rate-table/time-statement-hourly-rate-table.component';
import { TimesheetRowFundingSelectorComponent } from './components/timesheet-row-funding-selector/timesheet-row-funding-selector.component';
import { TimesheetRowTableComponent } from './components/timesheet-row-table/timesheet-row-table.component';
import { TimeStatementTableGroupComponent } from './components/time-statement-table-group/time-statement-table-group.component';
import { TimeStaffCostsOverviewComponent } from './components/time-staff-costs-overview/time-staff-costs-overview.component';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';

// the second parameter 'fr-FR' is optional
registerLocaleData(localeDe);

const config: SocketIoConfig = { url: 'wss://' + window.location.hostname, options: {} };

// AOT compilation support
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    SubBudgetInputComponent,
    SelectProjectDialogComponent,
    DialogContentSelectProjectComponent,
    SubBudgetListComponent,
    InvoiceListComponent,
    InvoiceInputComponent,
    ProjectListComponent,
    ProjectGroupInputComponent,
    LiquidityTableComponent,
    CellItemRendererComponent,
    LiquidityChartComponent,
    OpenLiquidityChartDialogComponent,
    RessourcesTableComponent,
    ProjectWorkloadItemRendererComponent,
    TempoOauthSuccessComponent,
    AllInvoicesListComponent,
    TimesheetsTableComponent,
    TimesheetFundingProjectSelectorComponent,
    SettingsComponent,
    JiraGroupSelectorComponent,
    JiraUserSelectorComponent,
    TimeStatementTableComponent,
    TimeStatementHourlyRateTableComponent,
    TimesheetRowFundingSelectorComponent,
    TimesheetRowTableComponent,
    TimeStatementTableGroupComponent,
    TimeStaffCostsOverviewComponent
  ],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'DE'
    }),
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    JiraconnectorModule,
    MatTableModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatListModule,
    MatTabsModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    SatDatepickerModule,
    SatNativeDateModule,
    MatTooltipModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ChartsModule,
    FormsModule,
    NgxPrintModule,
    SocketIoModule.forRoot(config),
    StoreModule.forRoot(reducers, {
      metaReducers
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([])
  ],
  entryComponents: [
    DialogContentSelectProjectComponent,
    LiquidityChartComponent,
  ],
  providers: [
    { provide: JIRA_URL, useValue: '/rest/api/3/' },
    { provide: JIRA_AUTH_URL, useValue: '/rest/' },
    { provide: TEMPO_IO_URL, useValue: '/api.tempo.io/core/3/' },
    { provide: TEMPO_IO_AUTH_URL, useValue: '/api.tempo.io/' },
    { provide: 'moment', useFactory: (): any => moment },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: SAT_MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    ProjectService,
    SocketService,
    TempoIoService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
