<form [class.invoice-input-form]="state!=VIEW_STATE.HIDE" (ngSubmit)="saveInvoice(true)" >
    <button mat-raised-button *ngIf="state==VIEW_STATE.ADD">+ Neue Rechnung</button>
  
    <div *ngIf="state!=VIEW_STATE.ADD && state!=VIEW_STATE.HIDE">
      
      <mat-checkbox [(ngModel)]="oInvoice.incoming" [ngModelOptions]="{standalone: true}" Checked="false" (ngModelChange)="saveInvoice()">Eingang</mat-checkbox>

      <mat-form-field class="width-invoice-number" *ngIf="!oInvoice.planed"> 
        <mat-label>R-Nr</mat-label>
        <input matInput [(ngModel)]="oInvoice.documentNumber" [ngModelOptions]="{standalone: true, updateOn:'blur'}" placeholder="Rechnungsnummer" (focusout)="saveInvoice()">
        <mat-hint>Nummer der Rechnung.</mat-hint>
    </mat-form-field>

    <mat-form-field>
            <mat-label>R-Bez</mat-label>
            <input matInput [(ngModel)]="oInvoice.name" [ngModelOptions]="{standalone: true, updateOn:'blur'}" placeholder="Rechnung-Bezeichnung" (focusout)="saveInvoice()">
            <mat-hint>Bezeichnung für die Rechnung.</mat-hint>
        </mat-form-field>
  
        <mat-checkbox [(ngModel)]="oInvoice.planed" [ngModelOptions]="{standalone: true}" Checked="true" (ngModelChange)="saveInvoice()">geplant</mat-checkbox>

        <mat-form-field class="width-invoice-date">
            <mat-label>R-Datum</mat-label>
            <input matInput [matDatepicker]="pickerInvoiceDate" placeholder="R-Datum" [(ngModel)]="oInvoice.invoiceDate" [ngModelOptions]="{standalone: true, updateOn:'blur'}" (ngModelChange)="updateClearingDate(); saveInvoice()">
            <mat-datepicker-toggle matSuffix [for] = "pickerInvoiceDate"></mat-datepicker-toggle>
            <mat-datepicker [startAt]="oInvoice.invoiceDate" #pickerInvoiceDate></mat-datepicker>
        
            <mat-hint>Rechnungsdatum.</mat-hint>
        </mat-form-field>
  
        <mat-form-field class="width-clearing-date">
            <mat-label>Bezahl-Datum</mat-label>
            <input matInput [matDatepicker]="pickerClearingDate" placeholder="Bezahl-Datum" [(ngModel)]="oInvoice.clearingDate" [ngModelOptions]="{standalone: true, updateOn:'blur'}" (ngModelChange)="saveInvoice()">
            <mat-datepicker-toggle matSuffix [for] = "pickerClearingDate"></mat-datepicker-toggle>
            <mat-datepicker [startAt]="oInvoice.clearingDate" #pickerClearingDate></mat-datepicker>
        
            <mat-hint>Bezahl-datum.</mat-hint>
        </mat-form-field>

        <mat-form-field class="width-customer-number"> 
            <mat-label>K-Nr</mat-label>
            <input matInput [(ngModel)]="oInvoice.customerNumber" [ngModelOptions]="{standalone: true, updateOn:'blur'}" placeholder="Kundennummer" (focusout)="saveInvoice()">
            <mat-hint>Nummer des Kunden.</mat-hint>
        </mat-form-field>
 
        <mat-form-field class="width-netto">
          <mat-label>Betrag (netto)</mat-label>
          <input matInput type="text" [ngModel]="oInvoice.netto | currency:'EUR':'':'0.2':'de'" [ngModelOptions]="{standalone: true, updateOn:'blur'}" (ngModelChange)="oInvoice.setNetto($event); saveInvoice()" class="right-align">
          <span matPrefix></span>
          <span matSuffix>&nbsp;€</span>
        </mat-form-field>
  
        <mat-form-field class="width-vat">
          <mat-label>Umsatzsteuer</mat-label>
          <mat-select [(ngModel)]="oInvoice.vat" [ngModelOptions]="{standalone: true, updateOn:'blur'}" (ngModelChange)="saveInvoice()">
            <mat-option value="0.0">ohne</mat-option>
            <mat-option value="0.05">5 %</mat-option>
            <mat-option value="0.07">7 %</mat-option>
            <mat-option value="0.16">16 %</mat-option>
            <mat-option value="0.19">19 %</mat-option>
          </mat-select>
        </mat-form-field>
          
        <button mat-raised-button [disabled]="!edited">
          <span *ngIf="!showSpinner">OK</span>
          <mat-icon *ngIf="showSpinner"><mat-spinner color="primary" diameter="20"></mat-spinner></mat-icon>
        </button>

      </div>
    </form>
  
