<span><strong>Rechnungen:</strong></span>

<br/>
<form>
<table mat-table #InvoicesTable [dataSource]="oSubBudget.invoices" multiTemplateDataRows>

	<ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
	    <td mat-cell *matCellDef="let row" class="column-{{columnsToDisplayInfo[column].class}}" [class.text-center]="column != 'name'"> 
            <div *ngIf="column == 'name'">
                
                <div style="width: 5.5%; float: left;">
                    <mat-checkbox [(ngModel)]="row.paid" [ngModelOptions]="{standalone: true}" Checked="true" (ngModelChange)="saveInvoicePaid(row)" (click)="$event.stopPropagation();" *ngIf="row.planed !== true"></mat-checkbox>&nbsp;
                </div>
                <div style="width: 5.5%; float: left;" *ngIf="row.incoming == true">
                    <mat-icon style="font-size: 20px;">cloud_upload</mat-icon>
                </div>

                <div style="width: 89%; float: right;">
                    <b>{{row.name}}</b> | <b>{{row.nettoString}}</b> | <span *ngIf="row.planed === true">geplant</span><span *ngIf="row.planed !== true">R-Nr: {{row.documentNumber}}</span> | R-Datum: {{row.invoiceDateString}} | Bezahl-Datum: {{row.clearingDateString}} | K-Nr: {{row.customerNumber}}
                </div>

            </div>
            <mat-chip [class.chip-color-green]="row.getValue(columnsToDisplayInfo[column].key) > 200000" *ngIf="column != 'tools' && column != 'name'">
                <span>{{row.getValue(columnsToDisplayInfo[column].key) | currency:'EUR':'€':'0.2':'de'}}</span>
            </mat-chip>
            <!-- Tools Definition -->
            <button mat-button class="button-tools" (click)="copyInvoice(row); $event.stopPropagation();" *ngIf="column == 'tools'"><mat-icon>file_copy</mat-icon></button>
            <button mat-button class="button-tools" (click)="removeInvoiceFromList(row); $event.stopPropagation();" *ngIf="column == 'tools'"><mat-icon>delete</mat-icon></button>

        </td>
    </ng-container>

    <!-- Expanded Content Column - Edit Invoice -->
    <ng-container matColumnDef="editInvoice">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
            <app-invoice-input [type]="VIEW_TYPE.EDIT" [project]="project" [oSubBudget]="oSubBudget" [oInvoice]="element" [oInvoicesTable]="oInvoicesTable" 
                [state]="element == expandedElement ? VIEW_STATE.EDIT : VIEW_STATE.HIDE" *ngIf="element === expandedElement"></app-invoice-input>
        </td>
    </ng-container>

    <tr mat-row 
        class="invoice-row" [class.planed]="element.planed" [class.color-green]="element.paid && !element.planed"  [class.color-yellow]="element.notPaidJustLikeThat" [class.color-red]="element.notPaidYet"
        (click)="expandedElement = expandedElement === element ? null : element; this._ChangeDetectorRef.detectChanges();" 
        *matRowDef="let element; columns: columnsToDisplay;"></tr>
    <tr mat-row *matRowDef="let row; columns: ['editInvoice']" class="edit-row"></tr>

</table>
</form>

<app-invoice-input [type]="VIEW_TYPE.NEW" [project]="project" [oSubBudget]="oSubBudget" [oInvoicesTable]="oInvoicesTable"></app-invoice-input>

<ng-template #deleteDialog>
    <h2 matDialogTitle>Löschen?</h2>
    <mat-dialog-content>
    <p>Soll die Rechnung</p> 
    <p><b>{{selectedInvoice.nameString}}</b></p> 
    <p>wirklich aus der Liste gelöscht werden?</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
    <button mat-button matDialogClose="cancel">Abbrechen</button>
    <button mat-button matDialogClose="confirm" color="warn">Löschen</button>
    </mat-dialog-actions>
</ng-template>