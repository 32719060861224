import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatTable } from '@angular/material/table';
import { SubBudget } from '../../model/SubBudget';
import { VIEW_STATE, VIEW_TYPE, PROJECT_TYPE } from '../../model/enums';
import { Project } from '../../model/Project';
import { JiraconnectorService } from 'src/app/jiraconnector';
import { ProjectService } from 'src/app/services/project.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-sub-budget-input',
  templateUrl: './sub-budget-input.component.html',
  styleUrls: ['./sub-budget-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubBudgetInputComponent implements OnInit {

  constructor(
    private _jiraconnector: JiraconnectorService,
    private _ChangeDetectorRef: ChangeDetectorRef,

    public projectService: ProjectService
  ) { }

  public VIEW_STATE = VIEW_STATE;
  public VIEW_TYPE = VIEW_TYPE;

  public edited = false;
  public showSpinner = false;

  public yearString = new Date().getFullYear();

  @Input() public project: Project;
  @Input() public oProjectSubBudgetTable: MatTable<any>;
  @Input() oSubBudget: SubBudget = new SubBudget();
  @Input() state: VIEW_STATE = VIEW_STATE.ADD;
  @Input() type: VIEW_TYPE;

  @Input() public columnsToDisplay: any[];
  @Input() public columnsToDisplayInfo: any[];

  selectedSubBudget: SubBudget;

  subBudgetInputControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  matcher = new MyErrorStateMatcher();

  ngOnInit() {
    setTimeout(() => {
      this._ChangeDetectorRef.detectChanges();
    }, 100);

  }

  saveSubBudget(submit = false) {
    this._ChangeDetectorRef.detectChanges();

    if (this.state === VIEW_STATE.ADD) {
      this.state = VIEW_STATE.NEW;
      this.type = VIEW_TYPE.NEW;
    } else {
     this.edited = true;
     console.log('saveSubBudget: ' + this.oSubBudget);

     if (!this.oSubBudget.isValid()) { return; }

     this.project.setType(PROJECT_TYPE.FULL);

     if (this.type === VIEW_TYPE.NEW) {
        this.project.subBudgets.push(this.oSubBudget);
        this.type = VIEW_TYPE.EDIT;
      }

     if (submit) {
        this.showSpinner = true;

        this.projectService.saveProject(this.project, () => {
          this.state = (this.state === VIEW_STATE.NEW) ? VIEW_STATE.ADD : this.state;
          if (this.state === VIEW_STATE.ADD) {
            this.oSubBudget = new SubBudget();
          }
          this.edited = false;
          this.oProjectSubBudgetTable.renderRows();
          this.showSpinner = false;
          this._ChangeDetectorRef.detectChanges();
        });
      }

    }
  }

}
