import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable, Subject } from 'rxjs';
import { DataDescription } from '../model/DataDescription';
import { User } from '../model/User';
import { SocketMessage } from '../model/SocketMessage';
import { SOCKET_SUBJECT, DATA_TYPE } from '../model/enums';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private url = 'wss://';
  private socketSubject: Subject<MessageEvent>;

  public connectedUsers: User[] = [];

  public sessionUser: User;

  constructor(
    private socket: Socket
  ) {}

  public sendMessage(socketMessage: SocketMessage) {
    console.log('sendMessage: ' + socketMessage);
    this.socket.emit('new-message', socketMessage);
  }

  public userManagement(socketMessage: SocketMessage) {

    switch (socketMessage.subject) {
      case SOCKET_SUBJECT.USER_CONNECT: {
        const user = new User();
        const actUser = JSON.parse(socketMessage.body);
        user.timestamp = actUser.timestamp;
        user.userAgent = actUser.userAgent;
        user.accountId = actUser.accountId;
        user.displayName = actUser.displayName;

        const jiraGroups: string[] = [];
        for (const jiraGroup of actUser.groups.items) {
          jiraGroups.push(jiraGroup.name);
        }
        user.jiraGroups = jiraGroups;

        this.sessionUser = user;
        break;
      }
      default: { break; }
    }

    this.socket.emit('user-management', socketMessage);
  }

  public dataChange(dataDescription: DataDescription) {

    dataDescription.uuid = this.sessionUser.getUUID();

    const socketMessage: SocketMessage = new SocketMessage();
    socketMessage.body = JSON.stringify(dataDescription);

    switch (dataDescription.type) {
      case DATA_TYPE.PROJECT: {
        socketMessage.subject = SOCKET_SUBJECT.DATA_CHANGE_PROJECT_DETAIL;
        break;
      }
      case DATA_TYPE.PROJECT_LIST: {
        socketMessage.subject = SOCKET_SUBJECT.DATA_CHANGE_PROJECT_LIST;
        break;
      }
      case DATA_TYPE.LIQUIDITY_TABLE: {
        socketMessage.subject = SOCKET_SUBJECT.DATA_CHANGE_LIQUIDITY_TABLE;

        break;
      }
      case DATA_TYPE.REQUEST_TEMPO_ACCESS: {
        socketMessage.subject = SOCKET_SUBJECT.REQUEST_TEMPO_ACCESS;

        break;
      }
      case DATA_TYPE.SEND_TEMPO_ACCESS: {
        socketMessage.subject = SOCKET_SUBJECT.SEND_TEMPO_ACCESS;

        break;
      }
      case DATA_TYPE.GET_SERVER_TEMPO_ACCESS_TOKEN: {
        socketMessage.subject = SOCKET_SUBJECT.GET_SERVER_TEMPO_ACCESS_TOKEN;

        break;
      }
      case DATA_TYPE.SET_SERVER_TEMPO_ACCESS_TOKEN: {
        socketMessage.subject = SOCKET_SUBJECT.SET_SERVER_TEMPO_ACCESS_TOKEN;

        break;
      }
      case DATA_TYPE.REFRESH_RESSOURCES_TABLE: {
        socketMessage.subject = SOCKET_SUBJECT.REFRESH_RESSOURCES_TABLE;

        break;
      }
      default: { break; }
    }
    this.socket.emit('data-change', socketMessage);
  }


  public handleUserManagement = () => {

    return new Observable((observer) => {
      this.socket.on('user-management', (message: SocketMessage) => {

        switch (message.subject) {
          case SOCKET_SUBJECT.USER_CONNECT: {
            const user = new User();
            const actUser = JSON.parse(message.body);
            user.timestamp = actUser.timestamp;
            user.userAgent = actUser.userAgent;
            user.accountId = actUser.accountId;
            user.displayName = actUser.displayName;

            user.jiraGroups = actUser.jiraGroups;

            this.sessionUser = user;
            break;
          }
          case SOCKET_SUBJECT.USER_LIST: {
            const userList = JSON.parse(message.body);
            const ar_user = [];
            for (const actUser of userList) {
              const user = new User();
              user.timestamp = actUser.timestamp;
              user.userAgent = actUser.userAgent;
              user.accountId = actUser.accountId;
              user.displayName = actUser.displayName;
              user.avatarUrl = actUser.avatarUrl;

              user.jiraGroups = actUser.jiraGroups;

              ar_user.push(user);
            }
            this.connectedUsers = [...ar_user];
            break;
          }
          default: { break; }
        }
        observer.next(message);
      });
    });
  }

  public handleDataChange = () => {

    return new Observable((observer) => {
      this.socket.on('data-change', (message: SocketMessage) => {

        observer.next(message);

      });
    });
  }

  public getMessages = () => {
    return new Observable((observer) => {
      this.socket.on('new-message', (message: SocketMessage) => {
        switch (message.subject) {
          case SOCKET_SUBJECT.USER_CONNECT: {
            const user = new User();
            const actUser = JSON.parse(message.body);
            user.timestamp = actUser.timestamp;
            user.userAgent = actUser.userAgent;
            user.accountId = actUser.accountId;
            user.displayName = actUser.displayName;
            user.avatarUrl = actUser.avatarUrls['48x48'];
            this.connectedUsers.push(user);
            observer.next(message);
            break;
          }
          default: {
            observer.next(message);
            break;
          }
        }
      });
    });
  }

}

