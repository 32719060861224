<div class="container pagebreak">
    <div class="timesheet-table-header" style="clear: both;" (click)="toggleHeaderExpand()">
        <div style="float: left;">
            <mat-icon class="font-size-16" *ngIf="headerExpanded != true">
                keyboard_arrow_right</mat-icon>
            <mat-icon class="font-size-16" *ngIf="headerExpanded == true">
                keyboard_arrow_down</mat-icon>
        </div>

        <div style="float: left;">
            <span *ngFor="let fundingProject of fundingProjects; trackBy: trackByFn">
                <span class='aui-avatar aui-avatar-xsmall'>
                    <span class='aui-avatar-inner'>
                        <img src='{{fundingProject.avatar}}' alt='Project Avatar'>
                    </span>
                </span> 
                {{fundingProject.name}}&nbsp;|&nbsp; 
            </span>   
            <span>
                {{moment(timesheet.monthDate).format('MMM YYYY')}}
            </span>     
        </div>
    </div>
    <!--
    <div style="width: 100%; text-align: left; margin-bottom: 1em;">
        <button
            mat-raised-button
            printTitle="{{moment(timesheet.monthDate).format('YYYY')}}_{{fundingProjectsAllId}}_stundennachweis_{{userRow.lastName}}_{{moment(timesheet.monthDate).format('MM')}}"
            [useExistingCss]="true"
            printSectionId="print-section-time-statement_{{userRow.accountId}}_{{fundingProjectsAllId}}_{{moment(timesheet.monthDate).format('YYYY_MM')}}"
            ngxPrint>Drucken{{printButtonLabel}} "{{moment(timesheet.monthDate).format('MMM YYYY')}}"
        </button>
    </div>
-->
    <div id="print-section-time-statement_{{userRow.accountId}}_{{fundingProjectsAllId}}_{{moment(timesheet.monthDate).format('YYYY_MM')}}" [class.timesheet-table-content]="!headerExpanded"> 
        <div style="position:absolute; width: 30%; left: 0px; padding-left: 2%; padding-top: 1%;">
            <div style="padding-left: 3px; text-align: left; font-size: 1em; font-weight: bold;">PROJEKTIONISTEN GmbH</div>
            <div style="padding-left: 3px; text-align: left; font-size: 1em; width: 20em; border-top-color: black; border-top-style: solid; border-top-width: 1px;">Zuwendungsempfänger (Firmenstempel)</div>
        </div>
        <div style="position:absolute; width: 30%; right: 0px; padding-right: 2%; padding-top: 1%;">
            <div style="position: relative; top: -1px; height: 2em; line-height: 2em; display: table-row; white-space: nowrap; display: flex; justify-content: right;" *ngFor="let fundingProject of fundingProjects; trackBy: trackByFn">
                <div style="padding-left: 3px; text-align: left; font-size: 1em; float: right; " title="{{timestamp}}">Förderkennzeichen:&nbsp;</div>
                <div style="padding-left: 10px; height: 2em; width: 60%; padding-right: 10px; text-align: center; font-size: 1em; border: 1px solid black;float: right; ">{{fundingProject.fundingNumber}} {{fundingProject.name}}</div>
            </div>     
        </div>
        <div style="display: flex; justify-content: center; width: 100%; padding-top: 9%; font-size: 1.5rem; font-weight: bold;" class="headline">Stundennachweis</div> 
        <div style="display: flex; justify-content: center; font-size: 1em; font-weight: bold;">für pauschalierte Abrechnung gemäß NKBF (Anlage 1 zum Verwendungsnachweis)</div> 
        <div style="padding-left: 2%; padding-top: 1em; text-align: left; font-size: 1em; font-weight: bold;">Der Original-Stundennachweis verbleibt beim Zuwendungsempfänger.</div>

        <div style="padding-left: 2%; padding-top: 1em; text-align: left; font-size: 1em;">Vorhabenthema</div>

        <div style="padding-left: 2%; margin-top: 1px; width: 96%; height: 2em; line-height: 2em;" *ngFor="let fundingProject of fundingProjects; trackBy: trackByFn">
            <div style="padding-left: 3px; text-align: left; font-size: 1em; border: 1px solid black;">{{fundingProject.fundingTitle}}</div>
        </div> 
        
        <div style="width: 100%; margin-top: 2.5em; position:relative;">
            <div style="float: left; margin-left: 2%;">
                <div style="padding-left: 2%; text-align: left; font-size: 1em;">Monat</div>

                <div style="padding-left: 2%; margin-top: 1px; width: 10em; height: 2em; line-height: 2em;">
                    <div style="padding-left: 3px; text-align: left; font-size: 1em; border: 1px solid black;">{{moment(timesheet.monthDate).format('MMM YYYY')}}</div>
                </div> 
            </div>

            <div style="float: right; margin-right: 2%;">
                <div style="text-align: left; font-size: 1em;">Mitarbeiter(in) [Name, Vorname]</div>

                <div style="margin-top: 1px; width: 50em; height: 2em; line-height: 2em;">
                    <div style="padding-left: 3px; text-align: left; font-size: 1em; border: 1px solid black;">{{userRow.lastName}}, {{userRow.surName}}</div>
                </div> 
            </div>                                        
        </div>

        <div style="padding-left: 2%; padding-top: 1.5em; text-align: left; font-size: 1em; clear: both;">Die zu Lasten des Vorhabens abzurechnenden Personalstunden sind täglich eigenhändig von der betreffenden Person zu erfassen. Nur die produktiven, für das Vorhaben geleisteten Stunden sind zuwendungsfähig.</div>
        
        <table style="margin-left: 2%; margin-top: 1.5em; width: 96%; line-height: 2em; border: 1px solid black; min-width: auto;">
            <tr style=" border: 1px solid black;">
                <td style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em;"></td>
                <td style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em; font-weight: bold;" [attr.colspan]="timesheet.columnIds.length - 2">Arbeitszeiten in Stunden je Kalendertag:</td>
                <td style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em;"></td>
                
            </tr>
            <tr style=" border: 1px solid black;">
                <td  *ngFor="let column of timesheet.columnIds; trackBy: trackByFn" 
                    style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em;" 
                    [class.holiday]="isHolidayClass(timesheet, column)"
                    [class.weekend]="timesheet.isDayWeekend(timesheet.columnsInfo[column].title) == true">
                    &nbsp;
                </td>
            </tr>
            <tr style=" border: 1px solid black;">
                <td  *ngFor="let column of timesheet.columnIds; trackBy: trackByFn" 
                    style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em;" 
                    [class.text-center]="column.indexOf('name') == -1" 
                    [class.timesheet-print-name]="column.indexOf('name') != -1">
                    <span *ngIf="column.indexOf('name') != -1"
                        style="font-weight: bold;">
                        Tätigkeiten
                    </span>
                    <span *ngIf="column.indexOf('name') == -1">
                        {{timesheet.columnsInfo[column].title}}
                    </span>
                </td>
            </tr>
            <tr style=" border: 1px solid black;" *ngFor="let fundingTimeSheetRow of filterRows(fundingTimeSheetRows, 'projectId', 'HOLIDAY'); trackBy: trackByFn">
                <td  *ngFor="let column of timesheet.columnIds; trackBy: trackByFn" 
                    style="width: 2%; border: 1px solid black; padding: 4px 4px;" 
                    [class.text-center]="column.indexOf('name') == -1" 
                    [class.timesheet-print-name]="column.indexOf('name') != -1"
                    [class.bold]="timesheet.columnsInfo[column].key == 'SUM' || fundingTimeSheetRow.projectName.indexOf('umme') != -1 || fundingTimeSheetRow.projectName.indexOf('esamt') != -1">

                    <app-cell-item-renderer *ngIf="timesheet.columnsInfo[column].key.indexOf('day') != -1"
                        [valueObject]="fundingTimeSheetRow"
                        [key]="timesheet.columnsInfo[column].key"
                        [type]="CELL_TYPE.TIMESHEET_NUMBER"
                        [editable]="isFundingTimeSheetRowEditable(timesheet, column, fundingTimeSheetRow)"
                        [viewUnit]="UNIT.HOURS"
                        (refresh)="handleRefresh()"
                        >
                    </app-cell-item-renderer>
                    <span  *ngIf="timesheet.columnsInfo[column].key.indexOf('day') == -1">
                        {{fundingTimeSheetRow.getValue(timesheet.columnsInfo[column].key)}}<sup *ngIf="column.indexOf('name') != -1 && fundingTimeSheetRow.getValue(timesheet.columnsInfo[column].key).indexOf('onstige') != -1">1)</sup>
                    </span>

                </td>                                            
            </tr>
            <tr style=" border: 1px solid black;">
                <td style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em; font-weight: bold;" [attr.colspan]="timesheet.columnIds.length">
                    &nbsp;
                </td>
            </tr>
            <tr style=" border: 1px solid black;" *ngFor="let fundingTimeSheetRow of filterRows(fundingTimeSheetRows, 'projectId', 'HOLIDAY', true); trackBy: trackByFn">
                <td  *ngFor="let column of timesheet.columnIds; trackBy: trackByFn" 
                    style="width: 2%; border: 1px solid black; padding: 4px 4px;" 
                    [class.text-center]="column.indexOf('name') == -1" 
                    [class.timesheet-print-name-small]="column.indexOf('name') != -1"
                    [class.bold]="timesheet.columnsInfo[column].key == 'SUM' || fundingTimeSheetRow.projectName.indexOf('umme') != -1 || fundingTimeSheetRow.projectName.indexOf('esamt') != -1">
                    <app-cell-item-renderer *ngIf="timesheet.columnsInfo[column].key.indexOf('day') != -1"
                        [valueObject]="fundingTimeSheetRow"
                        [key]="timesheet.columnsInfo[column].key"
                        [type]="CELL_TYPE.TIMESHEET_NUMBER"
                        [editable]="isFundingTimeSheetRowEditable(timesheet, column, fundingTimeSheetRow)"
                        [viewUnit]="UNIT.HOURS"
                        (refresh)="handleRefresh()"
                        >
                    </app-cell-item-renderer>
                    <span  *ngIf="timesheet.columnsInfo[column].key.indexOf('day') == -1">
                        {{fundingTimeSheetRow.getValue(timesheet.columnsInfo[column].key)}}
                    </span>
                </td>                                            
            </tr>
        </table>

        <div style="padding-left: 2%; padding-top: 1.5em; text-align: left;"><sup>1)</sup>ggf. Angabe des FKZ. anderer vom BMBF geförderter Projekte:</div>

        <div style="margin-left: 20%; margin-right: 20%; margin-top: 8em; margin-bottom: 5em;">
            <div style="float: left; padding-left: 5px; width: 20em; text-align: left; font-size: 1em; border-top-color: black; border-top-style: solid; border-top-width: 1px;">
                Unterschrift Vorgesetzter
            </div>
            <div style="float: right; padding-left: 5px; width: 20em; text-align: left; font-size: 1em; border-top-color: black; border-top-style: solid; border-top-width: 1px;">
                Unterschrift Mitarbeiter
            </div>
        </div>
    </div>
</div>