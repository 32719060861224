import { Component, OnInit, ChangeDetectorRef, Inject, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { JiraconnectorService } from 'src/app/jiraconnector';
import { VIEW_STATE } from 'src/app/model/enums';
import { Project } from 'src/app/model/Project';
@Component({
  selector: 'app-select-project-dialog',
  templateUrl: './select-project-dialog.component.html',
  styleUrls: ['./select-project-dialog.component.scss']
})

export class SelectProjectDialogComponent implements OnInit {

  @Output() selectedProjects = new EventEmitter<any>();

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
    // do nothing
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogContentSelectProjectComponent);

    dialogRef.afterClosed().subscribe(result => {
      // console.debug(`Dialog result: ${result}`);
    });

    const dialogSubmitSubscription =
    dialogRef.componentInstance.submitClicked.subscribe(result => {
      dialogSubmitSubscription.unsubscribe();
      // console.debug('Submit result: ' + JSON.stringify(result));

      this.selectedProjects.emit(result);
    });

  }
}

@Component({
  selector: 'app-dialog-content-select-project',
  templateUrl: 'dialog-content.html',
  styleUrls: ['./select-project-dialog.component.scss']
})
export class DialogContentSelectProjectComponent implements OnInit{

  @ViewChild('projectList', {static: true}) oProjectList: MatSelectionList;
  @Output() submitClicked = new EventEmitter<any>();

  public VIEW_STATE = VIEW_STATE;

  public state: VIEW_STATE = VIEW_STATE.READY;
  public projects: Project[] = [];

  public selectedOptions: Project[] = [];
  public selectedOption;
  public searchInput;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _jiraconnector: JiraconnectorService,
    private _ChangeDetectorRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<DialogContentSelectProjectComponent>
  ) {}

  public ngOnInit(): void {
    this.search();
  }

  public search(nameOrKey?: string): void {
    if (this.state === VIEW_STATE.PENDING) { return; }

    this.state = VIEW_STATE.PENDING;
    this._ChangeDetectorRef.detectChanges();

    let querySuffix = '';
    if (nameOrKey !== null && nameOrKey !== '') {
      querySuffix = '&query=' + nameOrKey;
    }
    // console.debug('query: ' + querySuffix);
    const result = this._jiraconnector.searchProjects(querySuffix);
    result.subscribe((res: string) => {
      this.addProjects(res);
    });
  }

  public addProjects(json: any): void {
    // console.debug(json);

    const results: Project[] = [];
    for (const item of json.values) {

      // console.debug(item);

      const oProject: Project = new Project();
      Object.assign(oProject, { name: item.name, id: item.key, avatar: item.avatarUrls['24x24'] });

      results.push(oProject);
    }
    this.projects = results;

    // console.debug(this.projects);

    this.state = VIEW_STATE.READY;

    this._ChangeDetectorRef.detectChanges();

  }

  public insertProjects(): void {
    const data = this.oProjectList.selectedOptions.selected;
    this.submitClicked.emit(this.selectedOption);
    this.dialogRef.close();
  }

  onNgModelChange($event){
    console.log($event);
    this.selectedOption = $event;
  }
}
