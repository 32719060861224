<div class="dialog-content-min-width">
    <h2 mat-dialog-title>Liquidität</h2>

    <mat-dialog-content>

        <div class="flex">
            <div class="flex-item">
            <div style="display: block;">
            <canvas baseChart width="1500" height="600"
                        [datasets]="lineChartData"
                        [labels]="lineChartLabels"
                        [options]="lineChartOptions"
                        [colors]="lineChartColors"
                        [legend]="lineChartLegend"
                        [chartType]="lineChartType"
                        [plugins]="lineChartPlugins"></canvas>
            </div>
            </div>
        </div>
    </mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>OK</button>
  </mat-dialog-actions>

</div>

