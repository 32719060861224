import { formatDate, formatNumber } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Injectable, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { JiraconnectorService } from 'src/app/jiraconnector';
import { CELL_TYPE, UNIT } from 'src/app/model/enums';
import { trackByFn } from 'src/app/model/globalFunctions';
import { Project } from 'src/app/model/Project';
import { Timesheet } from 'src/app/model/Timesheet';
import { User } from 'src/app/model/User';
import { ProjectService } from 'src/app/services/project.service';
import { TempoIoService, TEMPO_IO_URL } from 'src/app/services/tempo.io.service';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-time-staff-costs-overview',
  templateUrl: './time-staff-costs-overview.component.html',
  styleUrls: ['./time-staff-costs-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeStaffCostsOverviewComponent implements OnInit {

  public get years(): number[] {
    if (this._years === undefined || this._years === null || this._years.length === 0) {
      try {
        const ar_nYear: number[] = [];
        for (const user of this.userList) {
          try {
            const userTimesheets = this.getUserTimesheets(user.accountId);
            for (const timesheet of Object.values(userTimesheets)) {
              if (ar_nYear.indexOf(timesheet.monthDate.getFullYear()) === -1) {
                ar_nYear.push(timesheet.monthDate.getFullYear());
              }
            }
          } catch (error) {}
        }

        this._years = ar_nYear;

      } catch (error) {

      }

    }
    return this._years;
  }
  public set years(value: number[]) {
    this._years = value;
  }

  constructor(
    @Inject(TEMPO_IO_URL) public tempoIoUrl: string,

    public _ChangeDetectorRef: ChangeDetectorRef,
    private _jiraconnector: JiraconnectorService,

    public tempoIoService: TempoIoService,
    public projectService: ProjectService,
  ) { }

  ar_no_social_insurance = [false, true];

  private _funding_values = {};

  public user_data_rows = {};

  @Input() userList: any = {};
  timesheets: any = {};

  @Input() public startDate;
  @Input() public endDate;

  private _years: number[];

  @Output() refresh = new EventEmitter<any>();

  public loadingData = false;

  userListForYear = {};

  today = new Date();

  moment = moment;
  UNIT = UNIT;
  CELL_TYPE = CELL_TYPE;
  trackByFn = trackByFn;
  formatDate = formatDate;

  get startDateString(): string {
    return formatDate(this.startDate, 'dd.MM.yyyy', 'de');
  }

  get endDateString(): string {
    return formatDate(this.endDate, 'dd.MM.yyyy', 'de');
  }

  public get fundingProjects() {
    return this.projectService.fundingProjects;
  }

  getUserTimesheets(accountId: string): Timesheet[] {
    return this.timesheets[accountId];
  }

  generateMaps() {
    try {
      const _self = this;

      _self._funding_values = {};

      function addDataToFundingValues(user: User, id: string) {
        const userTimesheets = _self.getUserTimesheets(user.accountId);
        for (const key of Object.keys(userTimesheets)) {
          const timesheet = userTimesheets[key];
          let no_social_insurance = (timesheet.no_social_insurance === true || timesheet.no_social_insurance === 'true');

          let timesheet_gross_salary = timesheet.gross_salary;
          let timesheet_total_working_days = timesheet.total_working_days;

          if (user.lastName === 'Löhdefink' || user.lastName === 'Göbel' || user.lastName === 'Johannsen') {
            const project = _self.projectService.projectsById[id];
            if (project && project.flatRateSurcharge === 120) {
              no_social_insurance = false;
              timesheet_gross_salary = 80000;
              timesheet_total_working_days = 2080;
            }
          }

          const tmpfundingProjects = timesheet.fundingProjects;
          timesheet.fundingProjects = _self.fundingProjects;
          timesheet.refresh();

          const month_key = moment(timesheet.monthDate).format('YYYY_MM');

          if (timesheet.gross_salary !== 0) {
            const gross_salary_key =
              user.accountId + '_' + id + '_' + month_key.split('_')[0] + '_' + no_social_insurance + '_GROSS_SALARY';
            if (_self._funding_values[gross_salary_key] === undefined) {
              _self._funding_values[gross_salary_key] = timesheet_gross_salary;
            }
          }

          if (timesheet.total_working_days !== 0) {
            const total_working_days_key =
              user.accountId + '_' + id + '_' + month_key.split('_')[0] + '_' + no_social_insurance + '_TOTAL_WORKING_DAYS';
            if (_self._funding_values[total_working_days_key] === undefined) {
              _self._funding_values[total_working_days_key] = timesheet_total_working_days;
            }
          }

          const timeSheetRow = timesheet.fundingTimeSheetRows.find(
            item => item.selectedFundingProjectId !== 'unallocated' &&
            (item.projectId === id || item.selectedFundingProjectId === id)
          );
          if (timeSheetRow) {
            const value = timeSheetRow.getValue('SUM');
            if (!_self._funding_values[user.accountId + '_' + id + '_' + month_key + '_' + no_social_insurance]) {
              _self._funding_values[user.accountId + '_' + id + '_' + month_key + '_' + no_social_insurance] = value;

              const sum_key = user.accountId + '_' + id + '_' + month_key.split('_')[0] + '_' + no_social_insurance + '_SUM';
              _self._funding_values[sum_key] = (_self._funding_values[sum_key] || 0) + value;
            }
          }

          timesheet.fundingProjects = tmpfundingProjects;
          timesheet.refresh();

        }
      }

      this.user_data_rows = {};
      this.userListForYear = {};

      if (this.fundingProjects !== undefined && this.fundingProjects !== null) {
        for (const fundingProject of this.fundingProjects) {
          for (const user of this.userList) {

            if (user.lastName === 'Löhdefink' || user.lastName === 'Göbel' || user.lastName === 'Johannsen') {
              user.isCEO = true;
            }

            try {
              addDataToFundingValues(user, fundingProject.id);
              addDataToFundingValues(user, 'Other');
              addDataToFundingValues(user, 'SummeProd');
              addDataToFundingValues(user, 'HOLIDAY');

              for (const year of this.years) {

                for (const no_social_insurance of this.ar_no_social_insurance) {

                  const hourly_rate = this.getHourlyRateForUser(user, fundingProject, year, no_social_insurance);
                  const workload = this.getFundingProjectWorkloadForUser(user, fundingProject, year, no_social_insurance);

                  const sum = hourly_rate * workload;

                  if (sum !== 0) {
                    const data_row = {
                      user,
                      hourly_rate: formatNumber(hourly_rate, 'de', '1.0-2'),
                      workload: formatNumber(workload, 'de', '1.0-2'),
                      sum: formatNumber(sum, 'de', '1.2-2'),
                    };

                    this.user_data_rows[user.accountId + '_' + fundingProject.id + '_' + year + '_' + no_social_insurance] = data_row;

                    this.user_data_rows[fundingProject.id + '_' + year + '_' + no_social_insurance] =
                    (this.user_data_rows[fundingProject.id + '_' + year + '_' + no_social_insurance] !== undefined) ?
                      this.user_data_rows[fundingProject.id + '_' + year + '_' + no_social_insurance] + sum : sum;

                    if (fundingProject.flatRateSurcharge === 100 && user.isCEO) {
                      this.user_data_rows['CEO' + fundingProject.id + '_' + year + '_' + no_social_insurance] =
                      (this.user_data_rows['CEO' + fundingProject.id + '_' + year + '_' + no_social_insurance] !== undefined) ?
                        this.user_data_rows['CEO' + fundingProject.id + '_' + year + '_' + no_social_insurance] + sum : sum;
                    } else if (fundingProject.flatRateSurcharge !== 100) {
                      this.user_data_rows['CEO' + fundingProject.id + '_' + year + '_' + no_social_insurance] = 0;
                    }

                    if (this.userListForYear[year + '_' + fundingProject.id + '_' + no_social_insurance] === undefined) {
                      this.userListForYear[year + '_' + fundingProject.id + '_' + no_social_insurance] = [];
                    }
                    this.userListForYear[year + '_' + fundingProject.id + '_' + no_social_insurance].push(user);

                  }

                }

              }

            } catch (error) {
              console.log(error);
            }

          }

          for (const year of this.years) {

            for (const no_social_insurance of this.ar_no_social_insurance) {
              if (this.userListForYear[year + '_' + fundingProject.id + '_' + no_social_insurance] !== undefined) {
                this.userListForYear[year + '_' + fundingProject.id + '_' + no_social_insurance] =
                  this.userListForYear[year + '_' + fundingProject.id + '_' + no_social_insurance].sort((a, b) =>
                    a.lastName.localeCompare(b.lastName, undefined, { sensitivity: 'base' }));
              }
            }
          }

        }
      }

    } catch (error) {
      console.log(error);
    }
    this._ChangeDetectorRef.detectChanges();

  }

  ngOnInit(): void {
  }

  getDataRow(user: User, fundingProject: Project, year: number, no_social_insurance: boolean = false) {
    return this.user_data_rows[user.accountId + '_' + fundingProject.id + '_' + year + '_' + no_social_insurance] ?? {};
  }

  getFundingProjectWorkloadForUser(user: User, fundingProject: Project, year: number, no_social_insurance: boolean = false) {
    const userTimesheets = this.getUserTimesheets(user.accountId);
    let sum = 0;

    try {
      for (const timesheet of Object.values(userTimesheets)) {
        const month_key = moment(timesheet.monthDate).format('YYYY_MM');
        if (timesheet.monthDate.getFullYear() === year) {
          const value = this._funding_values[user.accountId + '_' + fundingProject.id + '_' + month_key + '_' + no_social_insurance];
          if (value) {
            sum += value;
          }
        }
      }
    } catch (error) {}

    return sum;
  }

  getGrossSalaryForUser(user: User, fundingProject: Project, year: number, no_social_insurance: boolean = false) {
    const gross_salary_key = user.accountId + '_' + fundingProject.id + '_' + year + '_' + no_social_insurance + '_GROSS_SALARY';
    return this._funding_values[gross_salary_key] || 0;
  }

  getTotalWorkingDaysForUser(user: User, fundingProject: Project, year: number, no_social_insurance: boolean = false) {
    const total_working_days_key =
      user.accountId + '_' + fundingProject.id + '_' + year + '_' + no_social_insurance + '_TOTAL_WORKING_DAYS';
    return this._funding_values[total_working_days_key] || 0;
  }

  getHourlyRateForUser(user: User, fundingProject: Project, year: number, no_social_insurance: boolean = false) {
    const gross_salary = this.getGrossSalaryForUser(user, fundingProject, year, no_social_insurance) || 0;
    const total_working_days = this.getTotalWorkingDaysForUser(user, fundingProject, year, no_social_insurance) || 0;
    const hourly_rate = total_working_days !== 0 ? gross_salary / total_working_days : 0;
    return hourly_rate;
  }

  getWorkloads() {
    const startDateString = moment(this.startDate).format('YYYY-MM-DD');
    const endDateString = moment(this.endDate).format('YYYY-MM-DD');

    this.loadingData = true;
    this._ChangeDetectorRef.detectChanges();

    const observableWorkloads = this.tempoIoService.getAll<any>(
      this.tempoIoUrl + 'worklogs?from=' + startDateString + '&to=' + endDateString + '&limit=14000', {}
      );

    observableWorkloads.subscribe(res => {

      this.timesheets = {};

      const ar_oItem = res.results;

      const accountIdWithWorkload = {};

      for (const item of ar_oItem) {

        const month = moment(item.startDate).format('YYYY_MM');
        const day = moment(item.startDate).format('DD');
        const monthDate = moment(item.startDate).toDate();

        const accountId = item.author.accountId;
        const issueId = item.issue.key;
        let projectId = issueId.split('-')[0];

        if (issueId === 'POR-57') {
          projectId = 'Urlaub';
        }
        if (issueId === 'POR-58') {
          projectId = 'Krank';
        }
        if (projectId === 'PIN') {
          projectId = 'Internes';
        }

        const accountTimesheets: any = this.timesheets[accountId] || {};
        const accountMonthTimesheet: Timesheet = accountTimesheets[month] || new Timesheet();

        accountMonthTimesheet.accountId = accountId;
        accountMonthTimesheet.monthDate = monthDate;

        const project: Project = this.projectService.projectsById[projectId];

        accountMonthTimesheet.projectNames[projectId] = project ? project.name : projectId;

        accountMonthTimesheet.addWorkload(projectId, day, item.timeSpentSeconds * 1);

        if (projectId !== 'Urlaub' && projectId !== 'Krank') {
          accountMonthTimesheet.addWorkload('Summe Arbeitszeit', day, item.timeSpentSeconds * 1);
        }

        accountTimesheets[month] = accountMonthTimesheet;
        this.timesheets[accountId] = accountTimesheets;

        accountIdWithWorkload[accountId] = true;
      }

      const ar_observables = [];

      for (const accountId of Object.keys(accountIdWithWorkload)) {
        if (accountIdWithWorkload[accountId] === true) {
          const observable = this.getUserTimeSheet(accountId);
          ar_observables.push(observable);
        }
      }

      forkJoin(ar_observables).subscribe((res) => {
        //
      }, (error) => {
        //
      }).add(() => {
        for (const accountId of Object.keys(accountIdWithWorkload)) {
          const timesheetsForUserId = this.timesheets[accountId];

          for (const month of Object.keys(timesheetsForUserId)) {
            const timesheet = timesheetsForUserId[month];
            timesheet.fundingProjects = this.fundingProjects;
            timesheet.refresh();
          }
        }

        this.loadingData = false;
        this.years = null;
        this._ChangeDetectorRef.detectChanges();

        this.generateMaps();

      });

      console.log(this.timesheets);

    }, (error) => {
      this.loadingData = false;
      this._ChangeDetectorRef.detectChanges();
    });

  }

  getUserTimeSheet(accountId: string): Observable<any> {

    const observable = new Observable<any>((observer) => {

      this._jiraconnector.getUserTimeSheet(accountId).subscribe((res) => {
        if (res.value) {

          for (const month of Object.keys(res.value)) {
            const oTimeSheet = res.value[month];

            const oExistingTimeSheet: Timesheet = this.timesheets[accountId][month];

            if (oExistingTimeSheet) {
              oExistingTimeSheet.gross_salary_crypt = oTimeSheet.gross_salary_crypt;
              oExistingTimeSheet.total_working_days_crypt = oTimeSheet.total_working_days_crypt;
              oExistingTimeSheet.no_social_insurance = oTimeSheet.no_social_insurance;
              oExistingTimeSheet.funding_manually_data = {...oExistingTimeSheet.funding_manually_data, ...oTimeSheet.funding_manually_data};
            }

            if (oExistingTimeSheet && oTimeSheet.timeSheetRows) {
              for (const oActTimeSheetRow of oTimeSheet.timeSheetRows) {

                const oExistingTimeSheetRow = oExistingTimeSheet.timeSheetRows.find(
                  (item) => item.projectId === oActTimeSheetRow.projectId);

                if (oExistingTimeSheetRow) {
                  oExistingTimeSheetRow.selectedFundingProjectId = oActTimeSheetRow.selectedFundingProjectId;
                }

              }

            }

            if (oExistingTimeSheet) {
                oExistingTimeSheet.refresh();
            }

          }

        }

        observer.next(res);
        observer.complete();

      }, (error) => {
        observer.next();
        observer.complete();
      });

    });

    return observable;

  }

  handleRefresh(event) {
    this.refresh.emit();
  }

}


