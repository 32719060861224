import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { JiraconnectorService } from 'src/app/jiraconnector';
import { MatDialog } from '@angular/material/dialog';
import { ProjectService } from 'src/app/services/project.service';
import { Invoice } from 'src/app/model/Invoice';
import { formatCurrency } from '@angular/common';
import { trackByFn } from 'src/app/model/globalFunctions';

@Component({
  selector: 'app-all-invoices-list',
  templateUrl: './all-invoices-list.component.html',
  styleUrls: ['./all-invoices-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AllInvoicesListComponent implements OnInit {

  public expandedElement;

  public columnsToDisplay     = [];
  public columnsToDisplayInfo = {};

  selectedInvoice: Invoice;

  netto: number;
  brutto: number;

  trackByFn = trackByFn;

  get nettoString(): string {
    return this.netto ? formatCurrency(this.netto, 'de', '€') : '---';
  }

  get bruttoString(): string {
    return this.netto ? formatCurrency(this.brutto, 'de', '€') : '---';
  }

  get invoices(): Invoice[] {
    let ar_oInvoices: Invoice[] = [];

    ar_oInvoices = this.projectService.invoices.filter((invoice: Invoice) => invoice.incoming === false &&
                                                                             invoice.paid !== true &&
                                                                             invoice.planed !== true &&
                                                                             invoice.virtual !== true &&
                                                                             invoice.isValid());

    ar_oInvoices.sort((a, b) => {
      if (a.invoiceDate < b.invoiceDate) { return -1; }
      if (a.invoiceDate > b.invoiceDate) { return 1; }
      if (a.documentNumber < b.documentNumber) { return -1; }
      if (a.documentNumber > b.documentNumber) { return 1; }
      return (a.name && b.name) ? a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }) : 0;
    });

    this.netto = 0;
    this.brutto = 0;
    for (const invoice of ar_oInvoices) {
      this.netto += invoice.netto;
      this.brutto += invoice.netto * (1 + invoice.vatNumber);
    }

    return ar_oInvoices;
  }
  set invoices(invoices: Invoice[]) {
    //
  }


  constructor(
    _jiraconnector: JiraconnectorService,
    public _ChangeDetectorRef: ChangeDetectorRef,
    dialog: MatDialog,

    public projectService: ProjectService
  ) { }

  ngOnInit() {

    const columns = [];
    const columnsInfo = {};

    let colName = 'name';
    columns.push(colName);
    columnsInfo[colName] = {class: 'c-name', title: 'Name'};

    colName = 'documentNumber';
    columns.push(colName);
    columnsInfo[colName] = {class: 'c-text', title: 'R-Nr.'};

    colName = 'invoiceDateString';
    columns.push(colName);
    columnsInfo[colName] = {class: 'c-text', title: 'R-Datum'};

    colName = 'clearingDateString';
    columns.push(colName);
    columnsInfo[colName] = {class: 'c-text', title: 'Bez-Datum'};

    colName = 'customerNumber';
    columns.push(colName);
    columnsInfo[colName] = {class: 'c-text', title: 'K-Nr.'};

    colName = 'projectLongName';
    columns.push(colName);
    columnsInfo[colName] = {class: 'c-text', title: 'Projekt'};

    colName = 'nettoString';
    columns.push(colName);
    columnsInfo[colName] = {class: 'c-number', title: 'Netto'};

    colName = 'bruttoString';
    columns.push(colName);
    columnsInfo[colName] = {class: 'c-number', title: 'Brutto'};


    colName = 'tools';
    columns.push(colName);
    columnsInfo[colName] = {class: 'c-tools', title: ''};

    this.columnsToDisplay = columns;
    this.columnsToDisplayInfo = columnsInfo;

    this.invoices = [];

    const intervalId = setInterval(() => {
      if (this.projectService.invoices && this.projectService.invoices.length > 0) {
        this.invoices = [];
        // clearInterval(intervalId);
      }
      this._ChangeDetectorRef.detectChanges();
    }, 300);


    this.projectService.projectSubject.subscribe(res => {
      this.invoices = [];
      this._ChangeDetectorRef.detectChanges();
    });

  }
}
