<div *ngFor="let year of years; trackBy: trackByFn">
    <div *ngFor="let fundingProject of fundingProjects; trackBy: trackByFn">
        <div style="width: 100%; text-align: left; margin-bottom: 1em;">
            <button
                mat-raised-button
                printTitle="{{year}}_{{fundingProject.name}}_jahresstundensatzberechnung_{{userRow.lastName}}"
                [useExistingCss]="true"
                printSectionId="print-section-time-statement-hourly-rate_{{userRow.accountId}}_{{fundingProject.name}}_{{year}}"
                ngxPrint>Drucke "{{fundingProject.name}} {{year}}"
            </button>
        </div>

        <div id="print-section-time-statement-hourly-rate_{{userRow.accountId}}_{{fundingProject.name}}_{{year}}" class="container section-to-print"> 
            <div style="position:absolute; width: 30%; left: 0px; padding-left: 2%; padding-top: 1%;">
                <div style="padding-left: 3px; text-align: left; font-size: 1em; font-weight: bold;">PROJEKTIONISTEN GmbH</div>
                <div style="padding-left: 3px; text-align: left; font-size: 1em; width: 20em; border-top-color: black; border-top-style: solid; border-top-width: 1px;">Zuwendungsempfänger (Firmenstempel)</div>
            </div>
            <div style="position:absolute; width: 30%; right: 0px; padding-right: 2%; padding-top: 1%;">
                <div style="position: relative; top: -1px; height: 2em; line-height: 2em; display: table-row; white-space: nowrap; display: flex; justify-content: right;">
                    <div style="padding-left: 3px; text-align: left; font-size: 1em; float: right; ">Förderkennzeichen:&nbsp;</div>
                    <div style="padding-left: 10px; height: 2em; width: 60%; padding-right: 10px; text-align: center; font-size: 1em; border: 1px solid black;float: right; ">{{fundingProject.fundingNumber}} {{fundingProject.name}}</div>
                </div>     
            </div>
            <div style="padding-left: 2%; display: flex; justify-content: left; width: 100%; padding-top: 4%; font-size: 1.5rem; font-weight: bold;" class="headline">Ermittlung des Jahresstundensatzes</div> 
            <div style="padding-left: 2%; display: flex; justify-content: left; font-size: 1em; font-weight: bold;">für pauschalierte Abrechnung gemäß NKBF (Anlage 2 zum Verwendungsnachweis)</div> 

            <div style="padding-left: 2%; padding-top: 1em; text-align: left; font-size: 1em;">Vorhabenthema</div>

            <div style="padding-left: 2%; margin-top: 1px; width: 96%; height: 2em; line-height: 2em;">
                <div style="padding-left: 3px; text-align: left; font-size: 1em; border: 1px solid black;">{{fundingProject.fundingTitle}}</div>
            </div> 
            
            <div style="width: 100%; margin-top: 2.5em; position:relative;">
                <div style="float: left; margin-left: 2%;">
                    <div style="padding-left: 2%; text-align: left; font-size: 1em;">Jahr</div>

                    <div style="padding-left: 2%; margin-top: 1px; width: 10em; height: 2em; line-height: 2em;">
                        <div style="padding-left: 3px; text-align: left; font-size: 1em; border: 1px solid black;">{{year}}</div>
                    </div> 
                </div>

                <div style="float: right; margin-right: 2%;">
                    <div style="text-align: left; font-size: 1em;">Mitarbeiter(in) [Name, Vorname]</div>

                    <div style="margin-top: 1px; width: 50em; height: 2em; line-height: 2em;">
                        <div style="padding-left: 3px; text-align: left; font-size: 1em; border: 1px solid black;">{{userRow.lastName}}, {{userRow.surName}}</div>
                    </div> 
                </div>                                        
            </div>

            <div style="padding-left: 2%; padding-top: 1.5em; text-align: left; font-size: 1em; clear: both; font-weight: bold;">1. Jahresstunden laut monatlichen Stundennachweisen</div>

            <table style="margin-left: 2%; margin-top: 1.5em; width: 96%; line-height: 2em; border: 1px solid black; min-width: auto;">
                <tr style=" border: 1px solid black;">
                    <td style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em;"></td>
                    <td style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em;" [attr.colspan]="12">Arbeitszeiten in Stunden je Monat</td>
                    <td style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em;"></td>
                    
                </tr>
                <tr style=" border: 1px solid black;">
                    <td  style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em;">
                        Tätigkeiten
                    </td>
                    <td  *ngFor="let monthDate of getMonthsByYear(year); trackBy: trackByFn" 
                        style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em; text-align: right;">
                        {{moment(monthDate).format('MMMM')}}
                    </td>
                    <td  style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em; text-align: right;">
                        Summe der Jahresstunden
                    </td>
                </tr>
                <tr style=" border: 1px solid black;">
                    <td  style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em;">
                        Vorhabenbezogen
                    </td>
                    <td  *ngFor="let monthDate of getMonthsByYear(year); trackBy: trackByFn" 
                        style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em; text-align: right;">
                        {{getFundingValueByMonth(moment(monthDate).format('YYYY_MM'), fundingProject.id)}}
                    </td>
                    <td  style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em; text-align: right;">
                        {{getFundingValueByMonth(year + '_SUM', fundingProject.id)}}
                    </td>
                </tr>
                <tr style=" border: 1px solid black;">
                    <td  style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em;">
                        Sonstige
                    </td>
                    <td  *ngFor="let monthDate of getMonthsByYear(year); trackBy: trackByFn" 
                        style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em; text-align: right;">
                        {{getFundingValueByMonth(moment(monthDate).format('YYYY_MM'), 'Other')}}
                    </td>
                    <td  style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em; text-align: right;">
                        {{getFundingValueByMonth(year + '_SUM', 'Other')}}
                    </td>
                </tr>
                <tr style=" border: 1px solid black;">
                    <td  style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em;">
                        Produktive Gesamtstunden
                    </td>
                    <td  *ngFor="let monthDate of getMonthsByYear(year); trackBy: trackByFn" 
                        style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em; text-align: right;">
                        {{getFundingValueByMonth(moment(monthDate).format('YYYY_MM'), 'SummeProd')}}
                    </td>
                    <td  style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em; text-align: right; font-weight: bold;">
                        {{getFundingValueByMonth(year + '_SUM', 'SummeProd')}}
                    </td>
                </tr>
                <tr style=" border: 1px solid black;">
                    <td  style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em;">
                        &nbsp;
                    </td>
                    <td  *ngFor="let monthDate of getMonthsByYear(year); trackBy: trackByFn" 
                        style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em; text-align: right;">
                        &nbsp;
                    </td>
                    <td  style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em; text-align: right;">
                        &nbsp;
                    </td>
                </tr>
                <tr style=" border: 1px solid black;">
                    <td  style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em;">
                        Fehlzeiten (z.B. Urlaub, Krankheit, Fortbildung)
                    </td>
                    <td  *ngFor="let monthDate of getMonthsByYear(year); trackBy: trackByFn" 
                        style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em; text-align: right;">
                        {{getFundingValueByMonth(moment(monthDate).format('YYYY_MM'), 'HOLIDAY')}}
                    </td>
                    <td  style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em; text-align: right;">
                        {{getFundingValueByMonth(year + '_SUM', 'HOLIDAY')}}
                    </td>
                </tr>

            </table>

            <div style="padding-left: 2%; padding-top: 0.5em; text-align: left;">(Zahlen in Klammern Fall in dem die Fußnote 2) zutrifft)</div>

            <div style="padding-left: 2%; padding-top: 1.5em; text-align: left; font-size: 1em; clear: both; font-weight: bold;">2. Berechnung des Jahresstundensatzes</div>

            <div style="padding-top: 1.5em;">
                <div style="margin-left: 2%; text-align: left; width: 15em; padding-bottom: 0.2em; border-bottom: 2px solid black;">Bruttojahreslohn /-gehalt 1)</div>
                <div style="margin-left: 2%; padding-top: 0.2em; text-align: left; width: 15em; padding-bottom: 0.2em;">Jahresarbeitsstunden laut <br>Arbeitsvertrag 2)</div>    

                <div style="position:relative; margin-left: 20em; top: -5.0em; text-align: right; width: 5em; padding-bottom: 0.2em; border-bottom: 2px solid black; font-weight: bold;">
                    <app-cell-item-renderer
                        [valueObject]="getTimeSheetByYear(year)"
                        [key]="'gross_salary'"
                        [type]="CELL_TYPE.TIMESHEET_GROSS_SALARY"
                        (refresh)="handleRefresh($event)"
                        >
                    </app-cell-item-renderer>

                </div>
                <div style="position:relative; margin-left: 20em; top: -5.0em; padding-top: 0.2em; text-align: right; width: 5em; padding-bottom: 0.2em; font-weight: bold;">
                    <app-cell-item-renderer
                        [valueObject]="getTimeSheetByYear(year)"
                        [key]="'total_working_days'"
                        [type]="CELL_TYPE.TIMESHEET_TOTAL_WORKING_DAYS"
                        (refresh)="handleRefresh($event)"
                        >
                    </app-cell-item-renderer>

                </div>    

                <div style="position:relative; margin-left: 30em; top: -10.5em; text-align: right; width: 10em; padding-bottom: 0.2em; font-weight: bold;">Jahresstundensatz</div>
                <div style="position:relative; margin-left: 30em; top: -10.5em; padding-top: 0.2em; text-align: right; width: 10em; padding-bottom: 0.2em; font-weight: bold;">{{getTimeSheetByYear(year) ? getTimeSheetByYear(year).hourly_rate : 0}} Euro</div>    

            </div>
            <div style="margin-left: 2%; text-align: left;">
                1) Personalkosten i.S. der NKBF und der ergänzenden Grundsätze (vergl. BMBF-Merkbaltt Vorkalkulation).
            </div>

            <div style="margin-left: 2%; text-align: left;">
                2) Bei tatsächlich mehr geleisteten produktiven Gesamtstunden (vorhabenbezogene und sonstige), gelten diese Gesamtstunden als Divisor.
            </div>
        </div>
    </div>
    <br>
    <br>
</div>