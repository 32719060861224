import { Component, OnInit } from '@angular/core';
import { JiraconnectorService } from 'src/app/jiraconnector';
import { SocketMessage } from 'src/app/model/SocketMessage';
import { SocketService } from 'src/app/services/socket.service';

@Component({
  selector: 'app-tempo-oauth-success',
  templateUrl: './tempo-oauth-success.component.html',
  styleUrls: ['./tempo-oauth-success.component.scss']
})
export class TempoOauthSuccessComponent implements OnInit {

  constructor(
    private _jiraconnector: JiraconnectorService,
    public socketService: SocketService,
  ) { }

  ngOnInit(): void {
    this._jiraconnector.tempoOauthToken = window.location.href;

    const oSocketMessage: SocketMessage = new SocketMessage();
    oSocketMessage.body = this._jiraconnector.tempoOauthToken;
    this.socketService.sendMessage(oSocketMessage);

  }

}
