import { CLASS_TYPE } from './enums';
import { User } from './User';

export class TempoTeam {
    type = CLASS_TYPE.TEMPO_TEAM;
    id: number;
    name: string;

    memberUserIds: string[];

    memberUsers: User[];

    static create(obj: any = null): TempoTeam {
        const tempoTeam = new TempoTeam();

        Object.assign(tempoTeam, obj);

        return tempoTeam;
    }
}
