<button 
    mat-raised-button 
    (click)="getWorkloads()">
    Refresh Übersicht Personalkosten&nbsp;
    <mat-icon>
        <mat-spinner 
            color="primary" 
            diameter="20" 
            [class.show]="loadingData"
            [class.hide]="!loadingData">
        </mat-spinner>
    </mat-icon>
</button>

<div *ngFor="let year of years" style="clear: both;">

    <div *ngFor="let fundingProject of fundingProjects; trackBy: trackByFn" style="clear: both;">
        <br>
        <br>
            <div style="width: 100%; text-align: left; margin-top: 1em; margin-bottom: 1em;">
            <button
                mat-raised-button
                printTitle="{{year}}_{{fundingProject.name}}_uebersicht_personalkosten"
                [useExistingCss]="true"
                printSectionId="print-section-time-staff-costs_{{fundingProject.name}}_{{year}}"
                ngxPrint>Drucke "{{fundingProject.name}} {{year}}"
            </button>
        </div>

        <div id="print-section-time-staff-costs_{{fundingProject.name}}_{{year}}" class="container section-to-print" style="clear: both;"> 
            <div style="margin-left: 7em; margin-right: 7em; float: left;" class="container pagebreak half-page" *ngFor="let no_social_insurance of ar_no_social_insurance">
                <div style="position:absolute; width: 30%; left: 0px; padding-left: 2%; padding-top: 1%;">
                    <div style="padding-left: 3px; text-align: left; font-size: 1em; font-weight: bold;">PROJEKTIONISTEN GmbH</div>
                    <div style="padding-left: 3px; text-align: left; font-size: 1em; width: 20em; border-top-color: black; border-top-style: solid; border-top-width: 1px;">Zuwendungsempfänger (Firmenstempel)</div>
                </div>
                <div style="position:absolute; width: 30%; right: 0px; padding-right: 2%; padding-top: 1%;">
                    <div style="position: relative; top: -1px; height: 2em; line-height: 2em; display: table-row; white-space: nowrap; display: flex; justify-content: right;">
                        <div style="padding-left: 3px; text-align: left; font-size: 1em; width: 50%; float: right; ">Förderkennzeichen&nbsp;</div>
                        <div style="padding-left: 10px; height: 2em; width: 50%; padding-right: 10px; text-align: left; font-size: 1em; float: right; ">{{fundingProject.fundingNumber}} {{fundingProject.name}}</div>
                    </div>     
                    <div style="position: relative; top: -1px; height: 2em; line-height: 2em; display: table-row; white-space: nowrap; display: flex; justify-content: right;">
                        <div style="padding-left: 3px; text-align: left; font-size: 1em; width: 50%; float: right; ">Jahr&nbsp;</div>
                        <div style="padding-left: 10px; height: 2em; width: 50%; padding-right: 10px; text-align: left; font-size: 1em; float: right; ">{{year}}</div>
                    </div>     
                </div>
                <div style="padding-left: 2%; display: flex; justify-content: left; width: 100%; padding-top: 10%; font-size: 1.5rem; font-weight: bold;" class="headline">Übersicht Personalkosten bei pauschalierter Abrechnung</div> 
                <div style="padding-left: 2%; display: flex; justify-content: left; font-size: 1em; font-weight: bold;">gemäß Nr. 5.6 NKBF (Anlage 3 zum Verwendungsnachweis)</div> 

                <table style="margin-left: 2%; margin-top: 2.5em; width: 96%; line-height: 2em; border: 0px solid white; min-width: auto;">
                    <tr style=" border: 1px solid black;">
                        <td style="width: 20%; border: 1px solid black; padding: 4px 4px; line-height: 1em;">Mitarbeiter</td>
                        <td style="width: 20%; border: 1px solid black; padding: 4px 4px; line-height: 1em;">Abrechnungsfähiger Zeitraum von bis</td>
                        <td style="width: 20%; border: 1px solid black; padding: 4px 4px; line-height: 1em;">Jahresstundensatz laut Anlage 2</td>
                        <td style="width: 20%; border: 1px solid black; padding: 4px 4px; line-height: 1em;">Abgerechnete <b>vorhabenbezogene</b> produktive Jahresstunden laut Anlage 2</td>
                        <td style="width: 20%; border: 1px solid black; padding: 4px 4px; line-height: 1em;">Abrechnungsfähige Personaleinzelkosten</td>    
                    </tr>

                    <tr style=" border: 1px solid black;">
                        <td  style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em;">
                            &nbsp;
                        </td>
                        <td  style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em; text-align: right;">
                            &nbsp;
                        </td>
                        <td  style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em; text-align: right;">
                            &nbsp;
                        </td>
                        <td  style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em; text-align: right;">
                            &nbsp;
                        </td>
                        <td  style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em; text-align: right;">
                            &nbsp;
                        </td>    
                    </tr>

                    <tr style=" border: 1px solid black;" *ngFor="let user of userListForYear[year + '_' + fundingProject.id + '_' + no_social_insurance]">
                        <td  style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em;">
                            {{user.lastName}}, {{user.surName}}
                        </td>
                        <td  style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em; text-align: right;">
                            {{startDateString}} - {{endDateString}}
                        </td>
                        <td  style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em; text-align: right;">
                            {{getDataRow(user, fundingProject, year, no_social_insurance).hourly_rate}}
                        </td>
                        <td  style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em; text-align: right;">
                            {{getDataRow(user, fundingProject, year, no_social_insurance).workload}}
                        </td>
                        <td  style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em; text-align: right;">
                            <span *ngIf="user.isCEO && fundingProject.flatRateSurcharge == 100">(</span>
                            {{getDataRow(user, fundingProject, year, no_social_insurance).sum}}
                            <span *ngIf="user.isCEO && fundingProject.flatRateSurcharge == 100">)</span>
                        </td>
                    </tr>

                    <tr style=" border: 1px solid black;">
                        <td  style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em;">
                            &nbsp;
                        </td>
                        <td  style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em; text-align: right;">
                            &nbsp;
                        </td>
                        <td  style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em; text-align: right;">
                            &nbsp;
                        </td>
                        <td  style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em; text-align: right;">
                            &nbsp;
                        </td>
                        <td  style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em; text-align: right;">
                            &nbsp;
                        </td>    
                    </tr>

                    <tr>
                        <td  style="width: 2%; padding: 4px 4px; line-height: 1em; text-align: right;" colspan="4">
                            <b>Summe der Personaleinzelkosten</b>
                        </td>
                        <td  style="width: 2%; border: 1px solid black; padding: 4px 4px; line-height: 1em; text-align: right;">
                            <b>{{user_data_rows[fundingProject.id + '_' + year + '_' + no_social_insurance] | number:'1.2-2':'de'}}</b>
                        </td>
                    </tr>
                    <tr>
                        <td  style="width: 2%; padding: 4px 4px; line-height: 1em; text-align: right;" colspan="4">
                            Pauschalzuschlag ({{ ( (!no_social_insurance) ? fundingProject.flatRateSurcharge : 0 ) }} v.H. der Personaleinzelkosten)
                        </td>
                        <td  style="width: 2%; border: 2px solid black; padding: 4px 4px; line-height: 1em; text-align: right;">
                            {{ ( (!no_social_insurance) ? ((user_data_rows[fundingProject.id + '_' + year + '_' + no_social_insurance] - user_data_rows['CEO' + fundingProject.id + '_' + year + '_' + no_social_insurance]) * (fundingProject.flatRateSurcharge / 100.0)) : 0 ) | number:'1.2-2':'de'}}
                        </td>
                    </tr>
                    <tr>
                        <td  style="width: 2%; padding: 4px 4px; line-height: 1em; text-align: right;" colspan="4">
                            <b>Zuwendungsfähige Personalkosten und mit der Pauschale abgegoltene Kosten</b>
                        </td>
                        <td  style="width: 2%; border: 2px solid black; padding: 4px 4px; line-height: 1em; text-align: right;">
                            {{(user_data_rows[fundingProject.id + '_' + year + '_' + no_social_insurance] + ( (!no_social_insurance) ? ((user_data_rows[fundingProject.id + '_' + year + '_' + no_social_insurance] - user_data_rows['CEO' + fundingProject.id + '_' + year + '_' + no_social_insurance]) * (fundingProject.flatRateSurcharge / 100.0)) : 0 )) | number:'1.2-2':'de'}}
                        </td>
                    </tr>

                    <tr *ngIf="fundingProject.flatRateSurcharge == 100">
                        <td  style="width: 2%; padding: 4px 4px; line-height: 1em; text-align: right;" colspan="3">
                            &nbsp;
                        </td>
                        <td  style="width: 2%; padding: 15px 4px; line-height: 1em; font-size: 0.7em;" colspan="2">
                            Mitarbeiter mit Personalkosten in Klammern werden nicht für den Pauschalzuschlag berücksichtigt (z.B. Geschäftsführer, etc.).
                        </td>
                    </tr>

                    <tr>
                        <td  style="width: 2%; padding: 4px 4px; line-height: 1em; text-align: right;" colspan="3">
                            &nbsp;
                        </td>
                        <td  style="width: 2%; padding: 15px 4px; line-height: 1em; font-size: 0.7em;" colspan="2">
                            Ich /Wir bestätige(n), dass die Angaben mit den Belegunterlagen übereinstimmen.
                        </td>
                    </tr>

                    <tr>
                        <td  style="width: 2%; padding: 4px 4px; line-height: 1em; text-align: right;" colspan="3">
                            &nbsp;
                        </td>
                        <td  style="width: 2%; padding: 15px 4px; line-height: 1em;" colspan="2">
                            Hannover, den {{ today | date:'dd.MM.yyyy'}}
                        </td>
                    </tr>
                    <tr>
                        <td  style="width: 2%; padding: 4px 4px; line-height: 1em; text-align: right;" colspan="3">
                            &nbsp;
                        </td>
                        <td  style="width: 2%; border-bottom: 2px solid black; padding: 15px 4px; line-height: 1em;" colspan="2">
                            &nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td  style="width: 2%; padding: 4px 4px; line-height: 1em; text-align: right;" colspan="3">
                            &nbsp;
                        </td>
                        <td  style="width: 2%; padding: 2px 4px; line-height: 1em; font-size: 0.7em;" colspan="2">
                            Unterschrift
                        </td>
                    </tr>

                </table>
            </div>
        </div>
    </div>
    <br>
    <br>
</div>