<div>
    <mat-form-field class="selection-list">
        <mat-label>Jira-Gruppe</mat-label>
        <mat-chip-list #selectionList aria-label="Jira-Gruppe">
          <mat-chip
            *ngFor="let selectionId of selectionIds"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="removeSelection(selectionId)">
            {{selectionId}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input
            placeholder="Jira-Gruppen"
            #selectionInput
            [formControl]="selectionCtrl"
            [matAutocomplete]="selectionAuto"
            [matChipInputFor]="selectionList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
        </mat-chip-list>
        <mat-autocomplete #selectionAuto="matAutocomplete" (optionSelected)="selectedSelection($event)">
          <mat-option *ngFor="let selectionId of filteredSelectionIds | async" [value]="selectionId">
            {{selectionId}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>      
</div>
