export enum VIEW_STATE {
  PENDING = 'PENDING',
  READY = 'READY',
  ADD = 'ADD',
  NEW = 'NEW',
  HIDE = 'HIDE',
  SHOW = 'SHOW',
  EDIT = 'EDIT'
}

export enum VIEW_TYPE {
  NEW = 'NEW',
  EDIT = 'EDIT'
}

export enum PROJECT_TYPE {
  SHORT = 'SHORT',
  FULL = 'FULL',
  GROUP = 'GROUP'
}
export enum CLASS_TYPE {
    JIRA_EPIC = 'JIRA_EPIC',
    JIRA_ISSUE = 'JIRA_ISSUE',
    JIRA_COMPONENT = 'JIRA_COMPONENT',
    JIRA_VERSION = 'JIRA_VERSION',
    VIRTUAL_GROUP = 'VIRTUAL_GROUP',
    TEMPO_TEAM = 'TEMPO_TEAM',
    SETTING_ITEM_VIEW_PART = 'SETTING_ITEM_VIEW_PART'
}

export enum REPORT_MODE {
  MONTH_FULL_YEAR = 'MONTH_FULL_YEAR',
  QUARTER_FULL_YEAR = 'QUARTER_FULL_YEAR',
  RESSOURCE_HOURS = 'RESSOURCE_HOURS',
  RESSOURCE_DAYS = 'RESSOURCE_DAYS'
}

export enum CELL_TYPE {
  NUMBER = 'NUMBER',
  EURO_VALUE = 'EURO_VALUE',
  STRING = 'STRING',
  WORKLOAD_NUMBER = 'WORKLOAD_NUMBER',
  PLANNED_NUMBER = 'PLANNED_NUMBER',
  TIMESHEET_NUMBER = 'TIMESHEET_NUMBER',
  TIMESHEET_GROSS_SALARY = 'TIMESHEET_GROSS_SALARY',
  TIMESHEET_TOTAL_WORKING_DAYS = 'TIMESHEET_TOTAL_WORKING_DAYS',
}

export enum SOCKET_SUBJECT {
  CONNECTED_USER_OBJECT = 'CONNECTED_USER_OBJECT',
  USER_CONNECT = 'USER_CONNECT',
  USER_DISCONNECT = 'USER_DISCONNECT',
  USER_LIST = 'USER_LIST',
  DATA_CHANGE_PROJECT_LIST = 'DATA_CHANGE_PROJECT_LIST',
  DATA_CHANGE_PROJECT_DETAIL = 'DATA_CHANGE_PROJECT_DETAIL',
  DATA_CHANGE_LIQUIDITY_TABLE = 'DATA_CHANGE_LIQUIDITY_TABLE',
  REQUEST_TEMPO_ACCESS = 'REQUEST_TEMPO_ACCESS',
  SEND_TEMPO_ACCESS = 'SEND_TEMPO_ACCESS',
  GET_SERVER_TEMPO_ACCESS_TOKEN = 'GET_SERVER_TEMPO_ACCESS_TOKEN',
  RESPONSE_GET_SERVER_TEMPO_ACCESS_TOKEN = 'RESPONSE_GET_SERVER_TEMPO_ACCESS_TOKEN',
  SET_SERVER_TEMPO_ACCESS_TOKEN = 'SET_SERVER_TEMPO_ACCESS_TOKEN',
  REFRESH_RESSOURCES_TABLE = 'REFRESH_RESSOURCES_TABLE',
  TEXT = 'TEXT'
}

export enum DATA_TYPE {
  PROJECT = 'PROJECT',
  PROJECT_LIST = 'PROJECT_LIST',
  LIQUIDITY_TABLE = 'LIQUIDITY_TABLE',
  REQUEST_TEMPO_ACCESS = 'REQUEST_TEMPO_ACCESS',
  SEND_TEMPO_ACCESS = 'SEND_TEMPO_ACCESS',
  GET_SERVER_TEMPO_ACCESS_TOKEN = 'GET_SERVER_TEMPO_ACCESS_TOKEN',
  RESPONSE_GET_SERVER_TEMPO_ACCESS_TOKEN = 'RESPONSE_GET_SERVER_TEMPO_ACCESS_TOKEN',
  SET_SERVER_TEMPO_ACCESS_TOKEN = 'SET_SERVER_TEMPO_ACCESS_TOKEN',
  REFRESH_RESSOURCES_TABLE = 'REFRESH_RESSOURCES_TABLE'
}

export enum WORKLOAD_UNIT {
  MINUTES_PER_WORKING_DAY = 8 * 60,
  MINUTES_PER_WORKING_HOUR = 60
}

export enum UNIT {
  HOURS = 'HOURS',
  DAYS = 'DAYS'
}
