import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import { ChartDataSets, ChartOptions } from 'chart.js';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import { MatDialog } from '@angular/material/dialog';
import { LiquidityService } from 'src/app/services/liquidity.service';
import { formatCurrency } from '@angular/common';

@Component({
  selector: 'app-open-liquidity-chart-dialog',
  templateUrl: './open-liquidity-chart-dialog.component.html',
  styleUrls: ['./open-liquidity-chart-dialog.component.scss']
})
export class OpenLiquidityChartDialogComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
    // do nothing
  }

  openChart() {
    const dialogRef = this.dialog.open(LiquidityChartComponent);

    dialogRef.afterClosed().subscribe(result => {
      // console.debug(`Dialog result: ${result}`);
    });

    /*
    const dialogSubmitSubscription =
    dialogRef.componentInstance.submitClicked.subscribe(result => {
      dialogSubmitSubscription.unsubscribe();
      //console.debug('Submit result: ' + JSON.stringify(result));

      this.selectedProjects.emit(result);
    });
*/
  }
}

@Component({
  selector: 'app-liquidity-chart',
  templateUrl: './liquidity-chart.component.html',
  styleUrls: ['./liquidity-chart.component.scss']
})
export class LiquidityChartComponent implements OnInit {
  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: Label[] = [];
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
          ticks: {
            // Include a dollar sign in the ticks
            callback(value, index, values) {
              try {
                return formatCurrency(
                  value as number,
                  'de',
                  '€',
                  'EUR',
                  '0.2',
                );
              } catch (error) {
                return value;
              }
            }
          }
        },
      ]
    },

    elements: {
      line: {
        tension: 0.3
      }
    },

    tooltips: {
      callbacks: {
          label(tooltipItem, data) {
              let label = data.datasets[tooltipItem.datasetIndex].label || '';

              if (label) {
                  label += ': ';
              }
              try {
                label += formatCurrency(
                  tooltipItem.yLabel as number,
                  'de',
                  '€',
                  'EUR',
                  '0.2',
                );
              } catch (error) {
                // do nothing
              }
              return label;
          }
      }
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno'
          }
        },
      ],
    },
  };
  public lineChartColors: Color[] = [
    { // dark grey
      backgroundColor: 'rgba(117, 209, 148,0.2)',
      borderColor: 'rgba(117, 209, 148,1)',
      pointBackgroundColor: 'rgba(117, 209, 148,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(117, 209, 148,1)'
    },
    { // red
      backgroundColor: 'rgba(250, 123, 123,0.3)',
      borderColor: 'red',
      pointBackgroundColor: 'rgba(250, 123, 123,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(250, 123, 123,0.8)'
    },
    { // black
      backgroundColor: 'rgba(0,0,0,0.0)',
      borderColor: 'rgba(0,0,0,1)',
      pointBackgroundColor: 'rgba(0,0,0,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(0,0,0,0.8)'
    },
    { // blue
      backgroundColor: 'rgba(117, 180, 209,0.5)',
      borderColor: 'rgba(117, 180, 209,1)',
      borderWidth: 2,
      pointBackgroundColor: 'rgba(117, 180, 209,1)',
      pointBorderColor: 'rgba(117, 180, 209,1)',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(117, 180, 209,0.8)'
    },
    { // grey
      backgroundColor: 'rgba(148,159,177,0.0)',
      borderColor: 'rgba(148,159,177,1)',
      borderWidth: 1,
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: 'rgba(148,159,177,0.0)',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [pluginAnnotations];

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  constructor(
    public liquidityService: LiquidityService

  ) { }

  ngOnInit() {
    this.lineChartData = [];

    let lineChartLabels: Label[] = [];

    const ar_oLiqudityTableRowSet = [
      {oLiqudityTableRow: this.liquidityService.liquidityTable[3],
        multiplier: 1,
        type: 'line',
        borderDash: []
      },
      {oLiqudityTableRow: this.liquidityService.liquidityTable[15],
        multiplier: -1,
        type: 'line',
        borderDash: []
      },
      {oLiqudityTableRow: this.liquidityService.liquidityTable[16],
        multiplier: 1,
        type: 'line',
        borderDash: []
      },
      {oLiqudityTableRow: this.liquidityService.liquidityTable[17],
        multiplier: 1,
        type: 'bar',
        borderDash: []
      },
      {oLiqudityTableRow: this.liquidityService.liquidityTable[15],
        multiplier: 1,
        type: 'line',
        borderDash: [2, 2]
      },
    ];

    let iCountMultiplier = 0;
    for (const oItem of ar_oLiqudityTableRowSet) {
      const oLiqudityTableRow = oItem.oLiqudityTableRow;
      lineChartLabels = [];
      const dataset = { data: [], label: oLiqudityTableRow.name, type: oItem.type, borderDash: oItem.borderDash };

      for (const column of this.liquidityService.columnsToDisplay) {
        const key = this.liquidityService.columnsToDisplayInfo[column].key;

        if (key === undefined || key === null || key.indexOf('add') !== -1 || key.indexOf('name') !== -1 || key.indexOf('year') !== -1) {
          continue;
        }

        const title = '  ' + this.liquidityService.columnsToDisplayInfo[column].title.trim() + '  ';
        const value = oItem.multiplier * oLiqudityTableRow.getValue(key);

        dataset.data.push(value.toFixed(2));
        lineChartLabels.push(title);

      }
      iCountMultiplier = iCountMultiplier + 1;

      this.lineChartLabels = lineChartLabels;
      this.lineChartData.push(dataset);
    }

  }

}
