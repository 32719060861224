import { formatNumber } from '@angular/common';
import { UNIT } from './enums';
import { REQUIRED_SECONDS_PER_DAY } from './constants';


export class RessourceCube {
  private _users: Map<string, RessourceUserSlice> = new Map<string, RessourceUserSlice>();

  private _dataLoading: any = {};
  private _dataLoaded: any = {};

  public setDataLoading(accountId: string, loading: boolean = true): void {
    this._dataLoading[accountId] = loading;
    this._dataLoaded[accountId] = !loading;
  }

  public setDataLoaded(accountId: string, loaded: boolean = true): void {
    this._dataLoading[accountId] = !loaded;
    this._dataLoaded[accountId] = loaded;
  }

  public isDataLoading(accountId: string): boolean {
    return this._dataLoading[accountId] === true;
  }

  public isDataLoaded(accountId: string): boolean {
    return this._dataLoaded[accountId] === true;
  }

  public getData(accountId: string, projectId: string, month: string): RessourceMonthData {
    try {
      return this._users[accountId].getSlice(projectId).getData(month);
    } catch (error) {
      return new RessourceMonthData();
    }
  }

  public removeData(accountId: string, month: string) {
    const ressourceUserSlice = this._users[accountId];
    if (ressourceUserSlice) {
      for (const ressourceProjectSlice of ressourceUserSlice.getSlices()) {
        const ressourceMonthData = ressourceProjectSlice.getData(month);
        if (ressourceMonthData) {
          ressourceMonthData.timeSpentSeconds = 0;
        }
      }
    }
  }


  public setData(accountId: string, projectId: string, month: string, value: RessourceMonthData) {
    const ressourceUserSlice = this._users[accountId] || new RessourceUserSlice();
    ressourceUserSlice.setData(projectId, month, value);
    this._users[accountId] = ressourceUserSlice;
  }

  public getPlan(accountId: string, projectId: string, month: string): RessourceMonthPlan {
    try {
      return this._users[accountId].getSlice(projectId).getPlan(month);
    } catch (error) {
      return new RessourceMonthPlan();
    }
  }

  public setPlan(accountId: string, projectId: string, month: string, value: RessourceMonthPlan) {
    const ressourceUserSlice = this._users[accountId] || new RessourceUserSlice();
    ressourceUserSlice.setPlan(projectId, month, value);
    this._users[accountId] = ressourceUserSlice;
  }

  public getPlansForProject(projectId: string, month: string): RessourceMonthPlan[] {
    const ar_oRessourceMonthPlan: RessourceMonthPlan[] = [];

    for (const accountId of Object.keys(this._users)) {
      let ressourceMonthPlan;
      try {
        ressourceMonthPlan = this._users[accountId].getSlice(projectId).getPlan(month);
      } catch (error) { }
      if (ressourceMonthPlan !== undefined && ressourceMonthPlan !== null) {
        ar_oRessourceMonthPlan.push(ressourceMonthPlan);
      }
    }
    return ar_oRessourceMonthPlan;
  }

  public getPlannedTimeForProject(projectId: string, month: string): number {
    let plannedSeconds = 0;

    for (const ressourceMonthPlan of this.getPlansForProject(projectId, month)) {
      plannedSeconds += ressourceMonthPlan.plannedSeconds || 0;
    }

    return plannedSeconds;
  }

  getPlannedTimeForProjectConverted(projectId: string, month: string, format: string = UNIT.HOURS): number {
    const plannedSeconds = this.getPlannedTimeForProject(projectId, month);

    try {
      let factor = 1;
      switch (format) {
        case UNIT.HOURS:
          factor = 8;
          break;

        default:
          break;
      }

      return plannedSeconds ? plannedSeconds / REQUIRED_SECONDS_PER_DAY * factor : 0;
    } catch (error) {
      return 0;
    }
  }

  getPlannedTimeForProjectFormated(projectId: string, month: string, format: string = UNIT.HOURS, digits: number = 2): string {

    const plannedTime = this.getPlannedTimeForProjectConverted(projectId, month, format);

    return plannedTime ? formatNumber(plannedTime, 'de', '1.0-' + digits) : '-';
  }

  public getPlansForUser(accountId: string, month: string): RessourceMonthPlan[] {
    const ar_oRessourceMonthPlan: RessourceMonthPlan[] = [];

    for (const slice of this._users[accountId].getSlices()) {
      let ressourceMonthPlan;
      try {
        ressourceMonthPlan = slice.getPlan(month);
      } catch (error) { }
      if (ressourceMonthPlan !== undefined && ressourceMonthPlan !== null) {
        ar_oRessourceMonthPlan.push(ressourceMonthPlan);
      }
    }
    return ar_oRessourceMonthPlan;
  }

  public getPlannedTimeForUser(accountId: string, month: string): number {
    let plannedSeconds = 0;

    for (const ressourceMonthPlan of this.getPlansForUser(accountId, month)) {
      plannedSeconds += ressourceMonthPlan.plannedSeconds || 0;
    }

    return plannedSeconds;
  }

}

export class RessourceUserSlice {
  private _projects: Map<string, RessourceProjectSlice> = new Map<string, RessourceProjectSlice>();

  public getSlice(projectId: string): RessourceProjectSlice {
    try {
      return this._projects[projectId];
    } catch (error) {
      return new RessourceProjectSlice();
    }
  }

  public getSlices(): RessourceProjectSlice[] {
    const ar_oRessourceProjectSlice: RessourceProjectSlice[] = [];

    for (const projectId of Object.keys(this._projects)) {
      try {
        ar_oRessourceProjectSlice.push(this._projects[projectId]);
      } catch (error) { }
    }

    return ar_oRessourceProjectSlice;
  }

  public setData(projectId: string, month: string, value: RessourceMonthData) {
    const ressourceProjectSlice = this._projects[projectId] || new RessourceProjectSlice();
    ressourceProjectSlice.setData(month, value);
    this._projects[projectId] = ressourceProjectSlice;
  }

  public setPlan(projectId: string, month: string, value: RessourceMonthPlan | number) {
    const ressourceProjectSlice = this._projects[projectId] || new RessourceProjectSlice();

    if (typeof value === 'number') {
      const newValue = ressourceProjectSlice.getPlan(month) || new RessourceMonthPlan();
      newValue.plannedSeconds = value;
      value = newValue;
    }

    ressourceProjectSlice.setPlan(month, value);
    this._projects[projectId] = ressourceProjectSlice;
  }

}

export class RessourceProjectSlice {
  private _months: Map<string, RessourceMonthData> = new Map<string, RessourceMonthData>();
  private _monthsPlan: Map<string, RessourceMonthPlan> = new Map<string, RessourceMonthPlan>();

  public getData(month: string): RessourceMonthData {
    return this._months[month] || new RessourceMonthData();
  }

  public setData(month: string, value: RessourceMonthData) {
    this._months[month] = value;
  }

  public getPlan(month: string): RessourceMonthPlan {
    return this._monthsPlan[month] || new RessourceMonthPlan();
  }

  public setPlan(month: string, value: RessourceMonthPlan) {
    this._monthsPlan[month] = value;
  }

}

export class RessourceMonthData {
  timeSpentSeconds: number;

  get timeSpendPT(): string {
    try {
      return this.timeSpentSeconds ? formatNumber(this.timeSpentSeconds / REQUIRED_SECONDS_PER_DAY, 'de', '1.0-2') : '-';
    } catch (error) {
      return '-';
    }
  }

  getTimeSpendFormated(format: string = UNIT.HOURS, digits: number = 2): string {
    try {
      let factor = 1;
      switch (format) {
        case UNIT.HOURS:
          factor = 8;
          break;

        case UNIT.DAYS:
          factor = 1;
          break;


        default:
          break;
      }

      return this.timeSpentSeconds ? formatNumber(this.timeSpentSeconds / REQUIRED_SECONDS_PER_DAY * factor, 'de', '1.0-' + digits) : '-';
    } catch (error) {
      return '-';
    }
  }

}

export class RessourceMonthPlan {
  plannedSeconds: number;
  ids: number[] = [];

  get plannedPT(): string {
    try {
      return this.plannedSeconds ? formatNumber(this.plannedSeconds / REQUIRED_SECONDS_PER_DAY, 'de', '1.0-2') : '-';
    } catch (error) {
      return '-';
    }
  }

  getPlannedConverted(format: string = UNIT.HOURS): number {
    try {
      let factor = 1;
      switch (format) {
        case UNIT.HOURS:
          factor = 8;
          break;

        case UNIT.DAYS:
          factor = 1;
          break;


        default:
          break;
      }

      return this.plannedSeconds ? this.plannedSeconds / REQUIRED_SECONDS_PER_DAY * factor : 0;
    } catch (error) {
      return 0;
    }
  }

  getPlannedFormated(format: string = UNIT.HOURS, digits: number = 2): string {
    try {
      let factor = 1;
      switch (format) {
        case UNIT.HOURS:
          factor = 8;
          break;

        case UNIT.DAYS:
          factor = 1;
          break;


        default:
          break;
      }

      return this.plannedSeconds ? formatNumber(this.plannedSeconds / REQUIRED_SECONDS_PER_DAY * factor, 'de', '1.0-' + digits) : '-';
    } catch (error) {
      return '-';
    }
  }

}
