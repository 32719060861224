import { Inject, Injectable, InjectionToken } from '@angular/core';
// import { Http } from '@angular/common';
// import { Observable } from 'rxjs/Rx';
import { Project } from '../model/Project';
import { ProjectShort } from '../model/ProjectShort';
import { LiqudityTableRow } from '../model/LiqudityTableRow';
import { environment } from '../../environments/environment';
import { AP$ } from 'src/polyfills';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Timesheet } from '../model/Timesheet';

// var jwt = require('atlassian-jwt');

export interface IProjectPartial {
  id?: string | number;
  key?: string;
  name?: string;
}
export interface IIssuePriority {
  id?: string | number;
  name?: string;
}
export interface IIssueType {
  description?: string;
  id?: string | number;
  name?: string;
  subtask?: boolean;
}
export interface IIssueFields {
  components?: any[];
  customfields?: object;
  description?: string;
  duedate?: any;
  issuetype: IIssueType;
  labels?: any[];
  priority?: IIssuePriority;
  project: IProjectPartial;
  summary: string;
}

export const JIRA_URL = new InjectionToken<string>('JiraUrl');
export const JIRA_AUTH_URL = new InjectionToken<string>('JiraUrl');

@Injectable()
export class JiraconnectorService {
  public headers: Headers = new Headers({ 'Content-type': 'application/json', 'Access-Control-Allow-Origin': '*',
                                          'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, HEAD, OPTIONS',
                                          'X-HTTP-Method-Override': 'GET, POST, PUT, DELETE, HEAD, OPTIONS' });

  private prefix: string = environment.production ? '' : 'dev-';

  public jwtToken = '';

  public tempoOauthToken = '';

  constructor(
    private _http: HttpClient,
    @Inject(JIRA_URL) public jiraUrl: string,
    @Inject(JIRA_AUTH_URL) public jiraAuthUrl: string
  ) {
    // this.setBasicAuth();
  }

  private setBasicAuth() {
    this.setBasicAuthorization(environment.hosts['https://projektionisten.atlassian.net'].username,
                               environment.hosts['https://projektionisten.atlassian.net'].password);
  }

  public setBasicAuthorization(username: string, password: string): void {
    this.headers.delete('Authorization');
    this.headers.append('Authorization', 'Basic ' + window.btoa(`${username}:${password}`));
  }

  public createComment(issueId: string, comment: string): Observable<any> {
    return this._post(`${this.jiraUrl}issue/${issueId}/comment`, JSON.stringify({ body: comment }));
  }

  public createIssue(fields: IIssueFields): Observable<any> {
    if (fields.customfields) {
      this.mapCustomfields(fields);
    }
    return this._post(`${this.jiraUrl}issue`, JSON.stringify({ fields }));
  }

  public editIssue(issueId: string, fields: any): Observable<any> {
    if (fields.customfields) {
      this.mapCustomfields(fields);
    }
    return this._put(`${this.jiraUrl}issue/${issueId}`, JSON.stringify({ fields }));
  }

  public getIssue(issueId: string): Observable<any> {
    return this._get(`${this.jiraUrl}issue/${issueId}`);
  }

  public searchIssues(jqlString: string): Observable<any> {
    return this._post(`${this.jiraUrl}search`, { jql: jqlString });
  }

  public searchProjects(querySuffix: string): Observable<any> {
    return this._get(`${this.jiraUrl}project/search?p-cockpit=true${querySuffix}`);
  }

  public searchGroups(query: string = ''): Observable<any> {
    return this._get(`${this.jiraUrl}groups/picker?query=${query}`);
  }

  public getUsersByGroupname(groupname: string, prefix: string = this.prefix, isJWT: boolean = true): Observable<any> {
    // 		return this._get(`${this.jiraUrl}users/search`, isJWT);
    return this._get(`${this.jiraUrl}group/member?groupname=${groupname}`, isJWT);
  }

  public getProjectProperty(projectIdOrKey: string, propertyKey: string,
                            prefix: string = this.prefix, isJWT: boolean = true): Observable<any> {
    return this._get(`${this.jiraUrl}project/${projectIdOrKey}/properties/${prefix}${propertyKey}`, isJWT);
  }

  public getProject(projectIdOrKey: string,
                    prefix: string = this.prefix, isJWT: boolean = true): Observable<any> {
    return this._get(`${this.jiraUrl}project/${projectIdOrKey}`, isJWT);
  }

  public putProjectProperty(projectIdOrKey: string, propertyKey: string,
                            propertyData: any, prefix: string = this.prefix, isJWT: boolean = true): Observable<any> {
    return this._put(`${this.jiraUrl}project/${projectIdOrKey}/properties/${prefix}${propertyKey}`, JSON.stringify(propertyData), isJWT);
  }

  public getProjectComponents(projectIdOrKey: string, prefix: string = this.prefix, isJWT: boolean = true): Observable<any> {
    return this._get(`${this.jiraUrl}project/${projectIdOrKey}/components`, isJWT);
  }

  public getSearchIssueKeys(jql: string, prefix: string = this.prefix, isJWT: boolean = true): Observable<any> {
    const actJqlQueryObject: any = {
      jql: '',
      fields: [
          'key'
      ],
      validateQuery: 'warn',
      maxResults: 10000
    };
    return this.getSearch(jql, actJqlQueryObject, prefix, isJWT);
  }

  public getSearch(jql: string, jqlQueryObject: any = null , prefix: string = this.prefix, isJWT: boolean = true): Observable<any> {
    let actJqlQueryObject: any = {
        jql: '',
        fields: [
            'summary',
            'issuetype',
            'avatar',
            'parent'

        ],
        validateQuery: 'warn',
        maxResults: 1000
    };
    if (jqlQueryObject !== null) {
      actJqlQueryObject = jqlQueryObject;
    }
    actJqlQueryObject.jql = jql;
    return this._post(`${this.jiraUrl}search`, JSON.stringify(actJqlQueryObject), isJWT);
  }

  public getUserProperty(accountId: string, propertyKey: string,
                         prefix: string = this.prefix, isJWT: boolean = true): Observable<any> {
    return this._get(`${this.jiraUrl}user/properties/${prefix}${propertyKey}?accountId=${accountId}`, isJWT);
  }

  public putUserProperty(accountId: string, propertyKey: string,
                         propertyData: any, prefix: string = this.prefix, isJWT: boolean = true): Observable<any> {
    return this._put(`${this.jiraUrl}user/properties/${prefix}${propertyKey}?accountId=${accountId}`,
          JSON.stringify(propertyData), isJWT);
  }

  public getUserTimeSheet(accountId: string, prefix: string = this.prefix, isJWT: boolean = true): Observable<any> {
    return this.getUserProperty(accountId, 'p-cockpit-time-sheet', prefix, isJWT);
  }

  public putUserTimeSheet(accountId: string, timeSheet: Timesheet, prefix: string = this.prefix, isJWT: boolean = true): Observable<any> {
    return this.putUserProperty(accountId, 'p-cockpit-time-sheet', timeSheet, prefix, isJWT);
  }

  public getCockpitProjectList(prefix: string = this.prefix, isJWT: boolean = false): Observable<any> {
    return this.getPCockpitProperty('p-cockpit-project-list', prefix);
    // 		return this.getGlobalProperty('p-cockpit-project-list', prefix, isJWT);
  }

  public putCockpitProjectList(projectList: Project[] | ProjectShort[],
                               prefix: string = this.prefix, isJWT: boolean = false): Observable<any> {
    return this.putPCockpitProperty('p-cockpit-project-list', projectList, prefix);
    // 		return this.putGlobalProperty('p-cockpit-project-list', projectList, prefix, isJWT);
  }

  public getCockpitLiqidityTable(year: string, prefix: string = this.prefix, isJWT: boolean = false): Observable<any> {
    return this.getPCockpitProperty(`p-cockpit-liqidity-table-` + year, prefix);
    // 		return this.getGlobalProperty(`p-cockpit-liqidity-table-` + year, prefix, isJWT);
  }

  public putCockpitLiqidityTable(year: string, liqudityTable: LiqudityTableRow[],
                                 prefix: string = this.prefix, isJWT: boolean = false): Observable<any> {
    return this.putPCockpitProperty(`p-cockpit-liqidity-table-` + year, liqudityTable, prefix);
    // 		return this.putGlobalProperty(`p-cockpit-liqidity-table-` + year, liqudityTable, prefix, isJWT);
  }

  public getPCockpitProperty(propertyKey: string, prefix: string = this.prefix, isJWT: boolean = true): Observable<any> {
    return this.getProjectProperty('PCOCKPIT', propertyKey, prefix, isJWT);
  }

  public putPCockpitProperty(propertyKey: string, propertyValue: any,
                             prefix: string = this.prefix, isJWT: boolean = true): Observable<any> {
    return this.putProjectProperty('PCOCKPIT', propertyKey, propertyValue, prefix, isJWT);
  }

  // https://your-domain.atlassian.net/jira/rest/atlassian-connect/1/addon/example.app.key/properties/propertyKey

  public getSessionUser(): Observable<any> {

    const _self = this;
    AP$.context.getToken((token) => {
      console.log('JWT token string', token);
      _self.jwtToken = token;
    });

    return this._get(`${this.jiraUrl}myself?expand=groups`);
  }

  public mapCustomfields(fields: IIssueFields): void {
    // tslint:disable-next-line: forin
    for (const key in fields.customfields) {
      fields[key] = fields.customfields[key];
    }

    delete fields.customfields;
  }

  public handleError(error: any): Observable<string> {
    return throwError(error.message || error);
  }

  private _get(url: string, isJWT: boolean = false): Observable<any> {

    const observable = new Observable<any>((observer) => {
      AP$.request({ url, type: 'GET', cache: false, contentType: 'application/json', })
        .then((data) => {

          const body = JSON.parse((data as any).body);

          if (body.value === undefined || (body.value && body.value.position === undefined)) {
            observer.next(data);
            observer.complete();
          } else {
            const parts: string[] = [];

            parts[0] = body.value.value;

            const length = body.value.length;

            let receivedParts = 1;

            for (let iCount = 1; iCount < length; iCount++) {
              const suffix = ((iCount === 0) ? '' : '-part' + iCount);

              AP$.request({ url: url + suffix, type: 'GET', cache: false, contentType: 'application/json', })
                .then((partData) => {
                  const partBody = JSON.parse((partData as any).body);

                  parts[partBody.value.position] = partBody.value.value;

                  receivedParts = receivedParts + 1;
                  if (receivedParts === parts.length) {
                    const concatJson = parts.join('');
                    const realJson = JSON.parse(atob(concatJson));

                    observer.next({ body: JSON.stringify({ value: realJson }) });
                    observer.complete();
                  }
                })
                .catch((error) => {
                  observer.error(error);
                });

            }

          }

        })
        .catch((error) => {
          observer.error(error);
        });
    })
      .pipe(
        map((data) => {
          return JSON.parse((data as any).body);
        }),
        catchError((error) => this.handleError(error))
      );

    return observable;
  }

  private _put(url: string, body: any, isJWT: boolean = false): Observable<any> {

    console.log('COUNT: ' + url + ' -> ' + (body ? body.length : -1));

    if (body.length > 20000) {
      const parts = this.jsonToParts(body);
      console.log(JSON.stringify(parts));

      const observable = new Observable<any>((observer) => {
        let submittedParts = 0;

        let pos = 0;
        for (const part of parts) {
          const partObject = {
            position: pos,
            length: parts.length,
            value: part
          };

          const suffix = ((pos === 0) ? '' : '-part' + pos);

          this._interal_put(url + suffix, JSON.stringify(partObject), isJWT).subscribe(res => {
            submittedParts = submittedParts + 1;
            if (submittedParts === parts.length) {
              observer.next();
              observer.complete();
            }
          }, error => {
            observer.error(error);
            observer.complete();
          });

          pos = pos + 1;

        }
      });

      return observable;
      /*
      const concatJson = parts.join('');
      const realJson = atob(concatJson);

      console.log('CONCATED JSON: ' + realJson);
      */
    } else {
      return this._interal_put(url, body, isJWT);
    }

  }

  private _interal_put(url: string, body: any, isJWT: boolean = false): Observable<any> {

    const observable = new Observable<any>((observer) => {
      AP$.request({ url, type: 'PUT', contentType: 'application/json', data: body, })
        .then(() => {
          observer.next();
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
          observer.complete();
        });
    })
      .pipe(
        catchError((error) => this.handleError(error))
      );

    return observable;

  }

  private _post(url: string, body: any, isJWT: boolean = false): Observable<any> {

    const observable = new Observable<any>((observer) => {
      AP$.request({ url, type: 'POST', contentType: 'application/json', data: body, })
        .then((data) => {
          observer.next(JSON.parse(data.body));
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
          observer.complete();
        });
    })
      .pipe(
        catchError((error) => this.handleError(error))
      );

    return observable;

  }

  private jsonToParts(json: string, length: number = 20000): string[] {
    const parts: string[] = [];

    const jsonBase64 = btoa(json);

    let position = 0;
    for (let iCount = 0; iCount < jsonBase64.length; iCount = iCount + length) {
      parts.push(jsonBase64.substr(position, length));
      position += length;
    }

    return parts;
  }

  /*
    private  old_get(url: string, isJWT: boolean = false): Observable<any> {
      if (isJWT) {
        this.setJWTHeader('GET', url);

        //url = 'https://projektionisten.atlassian.net' + url;
        //this.headers.set('Referrer', 'https://projektionisten.atlassian.net/plugins/servlet/' +
        'ac/dev-p-ressources-dev-addon/dev-p-ressources-jira?project.key=DSW&project.id=14900');

      } else {
        this.setBasicAuth();
      }
      return this._http.get(url, { headers: this.headers, withCredentials: true })
        .map((response) => {
          const json = response.json();
          return json;
        })
        .catch((error) => this.handleError(error));
    }


    private old_post(url: string, body: any, isJWT: boolean = false): Observable<any> {
      if (isJWT) {
        this.setJWTHeader('POST', url);
      } else {
        this.setBasicAuth();
      }
      return this._http.post(url, body, { headers: this.headers, withCredentials: true })
        .map((response) => response.json())
        .catch((error) => this.handleError(error));
    }

    private old_put(url: string, body: any, isJWT: boolean = false): Observable<string> {
      if (isJWT) {
        this.setJWTHeader('PUT', url);
      } else {
        this.setBasicAuth();
      }
      return this._http.put(url, body, { headers: this.headers, withCredentials: true })
        .map((response) => { try { return response.json() } catch (e) {} return null; })
        .catch((error) => this.handleError(error));
    }
  */
  /*
    setJWTHeader(method: string, url: string) {
      const token = this.getJWTToken(method, url);
      this.headers.delete('Authorization');
      this.headers.append('Authorization', 'JWT ' + token);
    }

    getJWTToken(method: string, url: string): string {
      const now = moment().utc();

      // Simple form of [request](https://npmjs.com/package/request) object
      const req = jwt.fromMethodAndUrl(method, url);

      const tokenData = {
        "iss": 'dev-p-ressources-dev-addon',
        "iat": now.unix(),                    // The time the token is generated
        "exp": now.add(3, 'minutes').unix(),  // Token expiry time (recommend 3 minutes after issuing)
        "qsh": jwt.createQueryStringHash(req) // [Query String Hash](https://developer.atlassian.com/' +
        'cloud/jira/platform/understanding-jwt/#a-name-qsh-a-creating-a-query-string-hash)
      };

      const secret = '5oG5SLbGS/221GrbKLw1gSP+vHeaxcrBzxqmoPPdd/XzBceA4m+YlB9a0kFQ8GTA9pmv/cu0S8VoRRvHWT/NdQ';

      const token = jwt.encode(tokenData, secret);

      return token;
    }
  */
}
