import { createReducer } from '@ngrx/store';

export const ressourcesTableFeatureKey = 'ressourcesTable';
// tslint:disable-next-line
export interface State {

}
// tslint:disable-next-line
export const initialState: State = {

};

export const reducer = createReducer(
  initialState
);

