import { Injectable } from '@angular/core';
import { DataDescription } from '../model/DataDescription';
import { DATA_TYPE } from '../model/enums';
import { Project } from '../model/Project';
import { Invoice } from '../model/Invoice';
import { LiqudityTableRow } from '../model/LiqudityTableRow';
import { JiraconnectorService } from '../jiraconnector';
// import { Observable } from 'rxjs';
import { SocketService } from './socket.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LiquidityService {

  liquidityTable: LiqudityTableRow[] = [];
  public columnsToDisplay = [];
  public columnsToDisplayInfo = {};

  public yearString = '2020'; // '' + (new Date().getFullYear());

  constructor(
    private _jiraconnector: JiraconnectorService,
    private socketService: SocketService
  ) { }

  public getLiquidityTableFromDataStore(): Observable<any> {

    const observable = new Observable((observer) => {
      const oResult = this._jiraconnector.getCockpitLiqidityTable(this.yearString);

      oResult.subscribe((json: any) => {
        const results = [];
        let hasKontozugang = false;
        let hasKontoabgang = false;

        for (const item of json.value) {

          // console.debug(item);

          const oLiqudityTableRow: LiqudityTableRow = new LiqudityTableRow();
          Object.assign(oLiqudityTableRow, item);

          const ar_oLiqudityTableRow: LiqudityTableRow[] = [];
          for (const subItem of oLiqudityTableRow.subLiqudityTableRow) {
            const oSubLiqudityTableRow: LiqudityTableRow = new LiqudityTableRow();
            Object.assign(oSubLiqudityTableRow, subItem);
            ar_oLiqudityTableRow.push(oSubLiqudityTableRow);
          }
          oLiqudityTableRow.subLiqudityTableRow = ar_oLiqudityTableRow;

          switch (oLiqudityTableRow.name) {
            case 'Einzahlung':
              oLiqudityTableRow.isHeader = true;
              oLiqudityTableRow.isExpandable = false;
              oLiqudityTableRow.orderNumber = '1';
              break;
            case 'Umsatz (inkl. MwSt.)':
              oLiqudityTableRow.orderNumber = '1.1';
              break;
            case 'Sonstige Einzahlungen':
              oLiqudityTableRow.isExpandable = true;
              oLiqudityTableRow.orderNumber = '1.2';

              for (const subItem of oLiqudityTableRow.subLiqudityTableRow) {
                if (subItem.name.indexOf('Forschung') !== -1) {
                  subItem.isExpandable = true;
                  subItem.editable = false;
                }
              }
              break;
            case 'Summe Liquiditätszugang':
              oLiqudityTableRow.editable = false;
              oLiqudityTableRow.isBold = true;
              oLiqudityTableRow.orderNumber = '1.3';
              break;
            case 'Auszahlungen':
              oLiqudityTableRow.isHeader = true;
              oLiqudityTableRow.isExpandable = false;
              oLiqudityTableRow.orderNumber = '2';
              break;
            case 'Anlageinvestitionen':
              oLiqudityTableRow.isExpandable = true;
              oLiqudityTableRow.orderNumber = '2.1';
              break;
            case 'Personal':
              oLiqudityTableRow.isExpandable = true;
              oLiqudityTableRow.orderNumber = '2.2';
              break;

            case 'Material / Waren':
              oLiqudityTableRow.isExpandable = true;
              oLiqudityTableRow.orderNumber = '2.3';
              break;
            case 'Betriebsausgaben':
              oLiqudityTableRow.isExpandable = true;
              oLiqudityTableRow.orderNumber = '2.4';
              break;
            case 'Kredittilgung':
              oLiqudityTableRow.isExpandable = true;
              oLiqudityTableRow.orderNumber = '2.5';
              break;
            case 'Zinsen':
              oLiqudityTableRow.isExpandable = true;
              oLiqudityTableRow.orderNumber = '2.6';
              break;
            case 'Vorsteuer / Umsatzsteuer':
              oLiqudityTableRow.isExpandable = true;
              oLiqudityTableRow.orderNumber = '2.7';
              break;
            case 'Einkommen- und Gewerbesteuer':
              oLiqudityTableRow.isExpandable = true;
              oLiqudityTableRow.orderNumber = '2.8';
              break;
            case 'Privatentnahme':
              oLiqudityTableRow.isExpandable = true;
              oLiqudityTableRow.orderNumber = '2.9';
              break;
            case 'Sonstige Auszahlung':
              oLiqudityTableRow.isExpandable = true;
              oLiqudityTableRow.orderNumber = '2.10';

              for (const subItem of oLiqudityTableRow.subLiqudityTableRow) {
                if (subItem.name.indexOf('Eingangsrechnungen') !== -1) {
                  subItem.isExpandable = true;
                  subItem.editable = false;
                }
              }

              break;
            case 'Summe Liquiditätsabgang':
              oLiqudityTableRow.editable = false;
              oLiqudityTableRow.isBold = true;
              oLiqudityTableRow.orderNumber = '2.11';
              break;
            case 'Liquiditätssaldo (1.3 minus 2.11)':
              oLiqudityTableRow.editable = false;
              oLiqudityTableRow.isBold = true;
              oLiqudityTableRow.hasAddValue = false;
              oLiqudityTableRow.orderNumber = '3';
              break;
            case 'Liquiditätssaldo (kumuliert)':
              oLiqudityTableRow.editable = false;
              oLiqudityTableRow.isBold = true;
              oLiqudityTableRow.showSum = false;
              oLiqudityTableRow.hasAddValue = true;
              oLiqudityTableRow.orderNumber = '4';
              break;
            case 'Finanzierung':
              oLiqudityTableRow.isHeader = true;
              oLiqudityTableRow.isExpandable = false;
              oLiqudityTableRow.isBold = true;
              oLiqudityTableRow.orderNumber = '5';
              break;
            case 'Eigenkapital':
              oLiqudityTableRow.isExpandable = true;
              oLiqudityTableRow.orderNumber = '5.1';
              break;
            case 'Fremdfinanzierung':
              oLiqudityTableRow.isExpandable = true;
              oLiqudityTableRow.orderNumber = '5.2';
              break;

            case 'Kontozugang':
              oLiqudityTableRow.isExpandable = false;
              oLiqudityTableRow.isBold = true;
              oLiqudityTableRow.isItalic = true;
              oLiqudityTableRow.orderNumber = '6.1';
              hasKontozugang = true;
              break;

            case 'Kontoabgang':
              oLiqudityTableRow.isExpandable = false;
              oLiqudityTableRow.isBold = true;
              oLiqudityTableRow.isItalic = true;
              oLiqudityTableRow.orderNumber = '6.2';
              hasKontoabgang = true;
              break;

            default:
              break;
          }

          results.push(oLiqudityTableRow);

        }

        if (!hasKontozugang) {
          const oLiqudityTableRow = new LiqudityTableRow();
          oLiqudityTableRow.name = 'Kontozugang';
          oLiqudityTableRow.isExpandable = false;
          oLiqudityTableRow.isBold = true;
          oLiqudityTableRow.isItalic = true;
          oLiqudityTableRow.orderNumber = '6.1';
          results.push(oLiqudityTableRow);

        }
        if (!hasKontoabgang) {
          const oLiqudityTableRow = new LiqudityTableRow();
          oLiqudityTableRow.name = 'Kontoabgang';
          oLiqudityTableRow.isExpandable = false;
          oLiqudityTableRow.isBold = true;
          oLiqudityTableRow.isItalic = true;
          oLiqudityTableRow.orderNumber = '6.2';
          results.push(oLiqudityTableRow);

        }

        this.liquidityTable = results;

        observer.next(results);
        observer.complete();
      });
    });

    return observable;
  }

  public saveLiquidityTableToDataStore(dataChanged = false) {
    const oResult = this._jiraconnector.putCockpitLiqidityTable(this.yearString, this.liquidityTable);
    oResult.subscribe((res) => {
      // console.debug('cockpit-liquidity-table: ' + res);

      if (dataChanged === true) {
        const dataDescription: DataDescription = new DataDescription();
        dataDescription.type = DATA_TYPE.LIQUIDITY_TABLE;
        this.socketService.dataChange(dataDescription);
      }

    });
  }

  setLiqidityTableByProjects(projects: Project[]) {

    const liquidityTable: LiqudityTableRow[] = this.liquidityTable; // [];

    const index = liquidityTable[2].subLiqudityTableRow.findIndex(item => item.name.indexOf('Forschung') !== -1);
    const oOtherIncomeLiqudityTableRow = liquidityTable[2].subLiqudityTableRow[index];

    let oLiqudityIncomingTableRow = null;
    try {
      const indexIncoming = liquidityTable[14].subLiqudityTableRow.findIndex(item => item.name.indexOf('Eingangsrechnungen') !== -1);
      if (indexIncoming !== -1) {
        oLiqudityIncomingTableRow = liquidityTable[14].subLiqudityTableRow[indexIncoming];
      }
    } catch (error) {}

    const oLiqudityTableRow: LiqudityTableRow = new LiqudityTableRow();
    /*
        oLiqudityTableRow = new LiqudityTableRow();
        oLiqudityTableRow.name = 'Einzahlung';
        liquidityTable.push(oLiqudityTableRow);
        */
    oLiqudityTableRow.orderNumber = '1.1';
    oLiqudityTableRow.editable = false;
    oLiqudityTableRow.isExpandable = true;

    for (const column of this.columnsToDisplay) {
      let liquiditySum = 0;
      let liquidityIncomingSum = 0;
      const ar_oInvoices: Invoice[] = [];
      const ar_oIncomingInvoices: Invoice[] = [];

      let otherIncomeSum = 0;
      const ar_oOtherIncomeInvoices: Invoice[] = [];

      for (const project of projects) {
        let projectLiquidity = project.getLiquidity(this.columnsToDisplayInfo[column].key, false);
        if (projectLiquidity == null) {
          projectLiquidity = 0;
        }
        // console.log('Col: ' + this.columnsToDisplayInfo[column].title +
        // ' --> NAME: ' + project.name + ' --> ' + projectLiquidity + ' €');
        liquiditySum += projectLiquidity;

        let projectIncomingLiquidity = project.getLiquidity(this.columnsToDisplayInfo[column].key, false, true);
        if (projectIncomingLiquidity == null) {
          projectIncomingLiquidity = 0;
        }
        // console.log('Col: ' + this.columnsToDisplayInfo[column].title +
        // ' --> NAME: ' + project.name + ' --> ' + projectLiquidity + ' €');
        liquidityIncomingSum += projectIncomingLiquidity;

        let projectOtherIncomeLiquidity = project.getLiquidity(this.columnsToDisplayInfo[column].key, true);
        if (projectOtherIncomeLiquidity == null) {
          projectOtherIncomeLiquidity = 0;
        }
        // console.log('Col: ' + this.columnsToDisplayInfo[column].title +
        // ' --> NAME: ' + project.name + ' --> ' + projectOtherIncomeLiquidity + ' €');
        otherIncomeSum += projectOtherIncomeLiquidity;

        try {
          const invoices = project.get_invoices(this.columnsToDisplayInfo[column].key, false);
          const outgoingInvoices = invoices.filter((invoice: Invoice) => invoice.incoming !== true);
          const incomingInvoices = invoices.filter((invoice: Invoice) => invoice.incoming === true);

          ar_oIncomingInvoices.push(...incomingInvoices);
          ar_oInvoices.push(...outgoingInvoices);
        } catch (error) {
          // do nothing
        }
        try {
          const invoices = project.get_invoices(this.columnsToDisplayInfo[column].key, true);
          const outgoingInvoices = invoices.filter((invoice: Invoice) => invoice.incoming !== true);

          ar_oOtherIncomeInvoices.push(...outgoingInvoices);
        } catch (error) {
          // do nothing
        }
      }
      oLiqudityTableRow.setValue(this.columnsToDisplayInfo[column].key, liquiditySum);
      oLiqudityTableRow.setInvoices(this.columnsToDisplayInfo[column].key, ar_oInvoices);

      if (oLiqudityIncomingTableRow !== null) {
        oLiqudityIncomingTableRow.setValue(this.columnsToDisplayInfo[column].key, liquidityIncomingSum);
        oLiqudityIncomingTableRow.setInvoices(this.columnsToDisplayInfo[column].key, ar_oIncomingInvoices);
      }

      oOtherIncomeLiqudityTableRow.setValue(this.columnsToDisplayInfo[column].key, otherIncomeSum);
      oOtherIncomeLiqudityTableRow.setInvoices(this.columnsToDisplayInfo[column].key, ar_oOtherIncomeInvoices);

      // console.log('ar_oInvoices: ' + JSON.stringify(oLiqudityTableRow.getInvoices(this.columnsToDisplayInfo[column].key)));
    }
    oLiqudityTableRow.name = 'Umsatz (inkl. MwSt.)';

    liquidityTable[1] = oLiqudityTableRow;

    /*
    liquidityTable.push(oLiqudityTableRow);
    oLiqudityTableRow = new LiqudityTableRow();
    oLiqudityTableRow.name = 'Sonstige Einzahlungen';
    liquidityTable.push(oLiqudityTableRow);
    oLiqudityTableRow = new LiqudityTableRow();
    oLiqudityTableRow.editable = false;
    oLiqudityTableRow.name = 'Summe Liquiditätszugang';
    liquidityTable.push(oLiqudityTableRow);
    oLiqudityTableRow = new LiqudityTableRow();
    oLiqudityTableRow.name = 'Auszahlungen';
    liquidityTable.push(oLiqudityTableRow);
    oLiqudityTableRow = new LiqudityTableRow();
    oLiqudityTableRow.name = 'Anlageinvestitionen';
    liquidityTable.push(oLiqudityTableRow);
    oLiqudityTableRow = new LiqudityTableRow();
    oLiqudityTableRow.name = 'Personal';
    liquidityTable.push(oLiqudityTableRow);
    oLiqudityTableRow = new LiqudityTableRow();
    oLiqudityTableRow.name = 'Material / Waren';
    liquidityTable.push(oLiqudityTableRow);
    oLiqudityTableRow = new LiqudityTableRow();
    oLiqudityTableRow.name = 'Betriebsausgaben';
    liquidityTable.push(oLiqudityTableRow);
    oLiqudityTableRow = new LiqudityTableRow();
    oLiqudityTableRow.name = 'Kredittilgung';
    liquidityTable.push(oLiqudityTableRow);
    oLiqudityTableRow = new LiqudityTableRow();
    oLiqudityTableRow.name = 'Zinsen';
    liquidityTable.push(oLiqudityTableRow);
    oLiqudityTableRow = new LiqudityTableRow();
    oLiqudityTableRow.name = 'Vorsteuer / Umsatzsteuer';
    liquidityTable.push(oLiqudityTableRow);
    oLiqudityTableRow = new LiqudityTableRow();
    oLiqudityTableRow.name = 'Einkommen- und Gewerbesteuer';
    liquidityTable.push(oLiqudityTableRow);
    oLiqudityTableRow = new LiqudityTableRow();
    oLiqudityTableRow.name = 'Privatentnahme';
    liquidityTable.push(oLiqudityTableRow);
    oLiqudityTableRow = new LiqudityTableRow();
    oLiqudityTableRow.name = 'Sonstige Auszahlung';
    liquidityTable.push(oLiqudityTableRow);
    oLiqudityTableRow = new LiqudityTableRow();
    oLiqudityTableRow.editable = false;
    oLiqudityTableRow.name = 'Liquiditätssaldo (1.3 minus 2.11)';
    liquidityTable.push(oLiqudityTableRow);
    oLiqudityTableRow = new LiqudityTableRow();
    oLiqudityTableRow.editable = false;
    oLiqudityTableRow.name = 'Liquiditätssaldo (kumuliert)';
    liquidityTable.push(oLiqudityTableRow);
    oLiqudityTableRow = new LiqudityTableRow();
    oLiqudityTableRow.name = 'Finanzierung';
    liquidityTable.push(oLiqudityTableRow);
    oLiqudityTableRow = new LiqudityTableRow();
    oLiqudityTableRow.name = 'Eigenkapital';
    liquidityTable.push(oLiqudityTableRow);
    oLiqudityTableRow = new LiqudityTableRow();
    oLiqudityTableRow.name = 'Fremdfinanzierung';
    liquidityTable.push(oLiqudityTableRow);
*/
    this.liquidityTable = liquidityTable;

    this.refreshSums(false);

  }

  refreshSums(dataChanged = true) {
    let cumulativeLiqudityBalanceSum = 0;

    for (const column of this.columnsToDisplay) {
      const key = this.columnsToDisplayInfo[column].key;

      if (key === undefined || key === null || key.indexOf('add') !== -1) {
        continue;
      }

      const oSumLiqudityTableRow = this.liquidityTable[3];
      oSumLiqudityTableRow.name = 'Summe Liquiditätszugang';
      const oSumLiqudityDisposalTableRow = this.liquidityTable[15];
      const oSumLiqudityBalanceTableRow = this.liquidityTable[16];
      const oSumCumulativeLiqudityBalanceTableRow = this.liquidityTable[17];

      let liquiditySum = 0;
      for (let iCount = 1; iCount < 3; iCount++) {
        const oLiqudityTableRow = this.liquidityTable[iCount];
        const value = oLiqudityTableRow.getValue(key);
        if (value != null) {
          liquiditySum += value;
        } else {
          liquiditySum += 0;
        }
      }
      oSumLiqudityTableRow.setValue(key, liquiditySum);

      let liquidityDisposalSum = 0;
      for (let iCount = 5; iCount < 15; iCount++) {
        const oLiqudityTableRow = this.liquidityTable[iCount];
        const value = oLiqudityTableRow.getValue(key);
        if (value != null) {
          liquidityDisposalSum += value;
        } else {
          liquidityDisposalSum += 0;
        }
      }
      oSumLiqudityDisposalTableRow.setValue(key, liquidityDisposalSum);

      oSumLiqudityBalanceTableRow.setValue(key, liquiditySum - liquidityDisposalSum);

      const addValue = oSumCumulativeLiqudityBalanceTableRow.getValue(key + '_add');
      if (addValue !== null) {
        liquiditySum += addValue;
      }

      cumulativeLiqudityBalanceSum += (key.indexOf('year') !== -1) ? 0 : liquiditySum - liquidityDisposalSum;

      oSumCumulativeLiqudityBalanceTableRow.setValue(key, cumulativeLiqudityBalanceSum);

    }

    this.liquidityTable = [...this.liquidityTable];

    if (dataChanged === true) {
      this.saveLiquidityTableToDataStore(dataChanged);
    }
  }

  copyProdToDev() {
    const sYear = '2020';
    this._jiraconnector.getCockpitLiqidityTable(sYear, '').subscribe((json: any) => {
      this._jiraconnector.putPCockpitProperty('p-cockpit-liqidity-table-' + sYear, json.value).subscribe(complete => {
        // this.getProjectsFromDataStore();
      });
    });

  }


}
