import { Component, OnInit, Input, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { SubBudget } from 'src/app/model/SubBudget';
import { VIEW_STATE, VIEW_TYPE, PROJECT_TYPE } from 'src/app/model/enums';
import { Project } from 'src/app/model/Project';
import { Invoice } from 'src/app/model/Invoice';
import { JiraconnectorService } from 'src/app/jiraconnector';
import { ProjectService } from 'src/app/services/project.service';
import { MatTable } from '@angular/material/table';

@Component({
  selector: 'app-invoice-input',
  templateUrl: './invoice-input.component.html',
  styleUrls: ['./invoice-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class InvoiceInputComponent implements OnInit {

  @Input() public project: Project;
  @Input() public oSubBudget: SubBudget;
  @Input() oInvoice: Invoice = new Invoice();
  @Input() state: VIEW_STATE = VIEW_STATE.ADD;
  @Input() type: VIEW_TYPE;

  @Input() oInvoicesTable: MatTable<any>;

  public VIEW_STATE = VIEW_STATE;
  public VIEW_TYPE = VIEW_TYPE;

  public edited = false;
  public showSpinner = false;

  public yearString = new Date().getFullYear();

  constructor(
    public projectService: ProjectService,
    private _jiraconnector: JiraconnectorService,
    private _ChangeDetectorRef: ChangeDetectorRef
  ) {
    if (this.type === VIEW_TYPE.NEW) {
      this.oInvoice.planed = true;
    }

  }

  public ngOnInit(): void {
    try {
      const customerNumber = this.oSubBudget.getCustomerNumber();
      if (customerNumber !== null && (this.oInvoice.customerNumber === undefined || this.oInvoice.customerNumber === null
                                                                                 || this.oInvoice.customerNumber === '')) {
        this.oInvoice.customerNumber = customerNumber;
      }
    } catch (error) {
      // do nothing
    }

    setTimeout(() => {
      this._ChangeDetectorRef.detectChanges();
    }, 100);

  }

  saveInvoice(submit = false) {
    this._ChangeDetectorRef.detectChanges();

    if (this.state === VIEW_STATE.ADD) {
      this.state = VIEW_STATE.NEW;
      this.type = VIEW_TYPE.NEW;
    } else {
      this.edited = true;
    }

    // console.debug(this.oInvoice);

    if (!this.oInvoice.isValid()) { return; }

    this.oInvoice.stored_values = {};

    this.project.setType(PROJECT_TYPE.FULL);

    if (this.type === VIEW_TYPE.NEW) {
      this.oSubBudget.invoices.push(this.oInvoice);
      this.type = VIEW_TYPE.EDIT;
    }

    if (submit) {
      this.showSpinner = true;

      this.projectService.saveProject(this.project, () => {
        this.state = (this.state === VIEW_STATE.NEW) ? VIEW_STATE.ADD : VIEW_STATE.HIDE;
        this.oInvoice = new Invoice();
        this.oInvoice.planed = true;

        try {
          const customerNumber = this.oSubBudget.getCustomerNumber();
          if (customerNumber !== null) {
            this.oInvoice.customerNumber = customerNumber;
          }
        } catch (error) {
          // do nothing
        }

        this.edited = false;

        this.oInvoicesTable.renderRows();

        this.showSpinner = false;
        this._ChangeDetectorRef.detectChanges();

      });
    }

  }

  updateClearingDate() {
    const invoiceDate = this.oInvoice.invoiceDate;
    const addDays = this.oSubBudget.clearingAddDays;

    const clearingDate = new Date(invoiceDate.getTime() + addDays * 24 * 60 * 60 * 1000);

    this.oInvoice.clearingDate = clearingDate;
  }

}
