<table mat-table #TimesheetsTable [dataSource]="userList" multiTemplateDataRows>

    <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay; trackBy: trackByFn">

        <th mat-header-cell *matHeaderCellDef 
            [class.text-left]="column == 'name'" 
            class="column-{{columnsToDisplayInfo[column].class}}" 
            (mouseover)="selectedColumn(column)" 
            [class.selected-column]="column == selectedColumnName" 
            [class.data-column]="column == 'data'">

            <div *ngIf="column == 'name'">
                <span style="width: 30%; float: left;">
                    {{columnsToDisplayInfo[column].title}}
                </span>

                <div style="width: 69%; float: right;">
                    <form [formGroup]="form" >
                        <mat-form-field class="form-field">
                            <input matInput
                                placeholder="Bereich auswählen"
                                [satDatepicker]="picker2"
                                (dateChange)="dateRangeChange($event)"
                                formControlName="date">
                            <sat-datepicker #picker2 [rangeMode]="true"></sat-datepicker>
                            <sat-datepicker-toggle matSuffix [for]="picker2"></sat-datepicker-toggle>
                        </mat-form-field>
                    </form>
                </div>

            </div>
            <div *ngIf="column != 'name'">
                {{columnsToDisplayInfo[column].title}}
            </div>
        </th>
        
        <td mat-cell *matCellDef="let userRow" 
            [class.selected-column]="column == selectedColumnName" 
            [class.text-center]="column != 'name'">

            <div *ngIf="column == 'name'" class="text-left" (click)="expandRow(userRow);">
                <span>
                    <mat-icon class="font-size-16" *ngIf="userExpandMap[userRow.accountId] != true">
                        keyboard_arrow_right</mat-icon>
                    <mat-icon class="font-size-16" *ngIf="userExpandMap[userRow.accountId] == true">
                        keyboard_arrow_down</mat-icon>
                    <mat-icon class="font-size-16" *ngIf="!userRow.isExpandable"></mat-icon>
                </span>
                <span class='aui-avatar aui-avatar-xsmall' *ngIf='userRow.avatar != ""'>
                    <span class='aui-avatar-inner'>
                        <img src='{{userRow.avatarUrl}}' alt='Project Avatar'>
                    </span>
                </span>
                <span>
                    &nbsp;&nbsp;<b>{{userRow.displayName}}</b>
                </span>
                <span *ngIf="loadingData && userExpandMap[userRow.accountId] == true" style="float: right;">
                    <mat-spinner color="primary" diameter="16"></mat-spinner>
                </span>
            </div>
            <div *ngIf="column == 'data' && userExpandMap[userRow.accountId] == true" class="text-left">

                <div> 
                    <div class="flex" style="float: left;" *ngFor="let timesheet of timesheetsForAccountId[userRow.accountId]; trackBy: trackByFn">
                        <div class="flex-item">
                            <div style="display: block; padding-right: 1em;">
                                <canvas baseChart style="background-color: white;"
                                    width="300" [height]="getChartHeightForTimeSheets(userRow)"
                                    type="horizontalBar"
                                    chartType="horizontalBar"
                                    [labels]="getLabelsForTimeSheet(timesheet)"
                                    [datasets]="getDatasetsForTimeSheet(timesheet)"
                                    [options]="getOptionsForTimeSheet(timesheet)"
                                    >
                                </canvas>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <mat-tab-group>

                        <mat-tab *ngFor="let timesheet of timesheetsForAccountId[userRow.accountId]; trackBy: trackByFn" (click)="timesheetsForAccountId = {}; timesheet.refresh(); $event.stopPropagation();">
                            <ng-template mat-tab-label>
                                {{moment(timesheet.monthDate).format('MM / YYYY')}}
                            </ng-template>
                            <ng-template matTabContent>

                                <div>
                                    <app-timesheet-row-table
                                        [timesheet]="timesheet"
                                        [fundingProjects]="projectService.fundingProjects"
                                        [holidayDates]="holidayDates"
                                        (refresh)="putUserTimeSheet(userRow)">
                                    </app-timesheet-row-table>
                                </div>

                                <div>
                                    <form style="text-align: left;">
                                            <mat-checkbox 
                                                [(ngModel)]="timesheet.no_social_insurance" 
                                                [ngModelOptions]="{standalone: true}" 
                                                (ngModelChange)="putUserTimeSheet(userRow); $event.stopPropagation()">
                                                <b>keine</b> Sozaialversicherungspflicht.
                                            </mat-checkbox>
                                    </form>    
                                </div>

                                <div>
                                    <app-timesheet-funding-project-selector 
                                        [timesheet]="timesheet"
                                        (refresh)="putUserTimeSheet(userRow)">
                                    </app-timesheet-funding-project-selector>
                                </div>

                                <div>
                                </div>

                            </ng-template>

                        </mat-tab>
                    
                    </mat-tab-group>

                </div>

                <div>
                    <app-time-statement-table-group 
                        [userRow]="userRow" 
                        [selectedTimesheets]="timesheetsForAccountId[userRow.accountId]" 
                        [timesheets]="timesheets"
                        [holidayDates]="holidayDates"
                        (refresh)="putUserTimeSheet(userRow)">
                    </app-time-statement-table-group>
                </div>

                <div *ngIf="socketService.sessionUser && socketService.sessionUser.hasGroup('p-cockpit-ceos')">
                    <br>
                    <br>
                    <app-time-statement-hourly-rate-table 
                        [userRow]="userRow" 
                        [userTimesheets]="timesheetsForAccountId[userRow.accountId]" 
                        [timesheets]="timesheets"
                        (refresh)="putUserTimeSheet(userRow)">
                    </app-time-statement-hourly-rate-table>
                </div>
            </div>
        </td>

    </ng-container>

    <div>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true" ></tr>
    </div>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
        [class.header-row]="element.isHeader"
        >
    </tr>

</table>

<div style="margin-left: 2em; margin-bottom: 5em;" *ngIf="socketService.sessionUser && socketService.sessionUser.hasGroup('p-cockpit-ceos')">
    <br>
    <br>
    <app-time-staff-costs-overview 
        [userList]="userList" 
        [startDate]="startDate"
        [endDate]="endDate"
        >
    </app-time-staff-costs-overview>
</div>

