export function trackByFn(index: any, item: any): any {
    if (typeof(item) === 'string') {
      return index + '_' + item;
    } else if (typeof(item) === 'number') {
      return index + '_' + item;
    } else if ('key' in item) {
      return index + '_' + item.id;
    } else if ('id' in item) {
        return index + '_' + item.id;
    }
    return index;
  }
