<mat-form-field>
    <mat-label>Forschungsprojekt</mat-label>
    <mat-select 
        (selectionChange)="selectFundingProject(timesheetRow, $event)"
        [compareWith]="compareObjects"
        [ngModel]="timesheetRow">
        <mat-option value="unallocated">nicht zugewiesen</mat-option>
        <mat-option *ngFor="let fundingProject of fundingProjects" [value]="fundingProject.id">
            {{fundingProject.name}} ({{fundingProject.fundingNumber}})
        </mat-option>
    </mat-select>
</mat-form-field>
