<div class="table-container mat-elevation-z8">
    <table mat-table #RessourcesTable [dataSource]="userList" multiTemplateDataRows>

        <ng-container matColumnDef="{{pgColumn}}" *ngFor="let pgColumn of groupColumnsToDisplay; trackBy: trackByFn">
            <th mat-header-cell *matHeaderCellDef 
                class="c-m text-center invoice-truncate" 
                [attr.colspan]="getColspan(pgColumn)" 
                [class.selected-column]="pgColumn == selectedGroupColumnName" 
                matTooltip="{{groupColumnsToDisplayInfo[pgColumn].title}}"
                [class.hide-column]="getColspan(pgColumn) <= 0">
                <button mat-mini-fab 
                    color="primary" 
                    aria-label="Projekte aus-/einblenden" 
                    style="transform: scale(0.6);" 
                    (click)="hideShowProjects()"
                    *ngIf="pgColumn == 'pg-month_column'">
                    <mat-icon>visibility_off</mat-icon>
                </button>        
                {{groupColumnsToDisplayInfo[pgColumn].title}}
            </th>
        </ng-container>

        <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
            <th mat-header-cell *matHeaderCellDef  
                [class.text-center]="column != 'name'" 
                [class.text-left]="column == 'name'" 
                class="column-{{columnsToDisplayInfo[column].class}}" 
                (mouseover)="selectedColumn(column)" 
                [class.hide-column]="projectCollapsedMap[getProjectId(column)] == true || projectCollapsedMap[getProjectId(column).split('___')[0]] == true"
                [class.selected-column]="column == selectedColumnName" 
                [class.max-width]="column != 'name' && column.indexOf('p_') == -1"
                [class.header-collapse]="projectCollapsedMap[getProjectId(column)] == true">
                <div *ngIf="column == 'name'">
                    <form [formGroup]="form">
                        <mat-form-field class="example-form-field">
                            <input matInput
                                placeholder="Bereich auswählen"
                                [satDatepicker]="picker2"
                                (dateChange)="dateRangeChange($event)"
                                formControlName="date">
                            <sat-datepicker #picker2 [rangeMode]="true"></sat-datepicker>
                            <sat-datepicker-toggle matSuffix [for]="picker2"></sat-datepicker-toggle>
                        </mat-form-field>
                    </form>
                </div>
                <div [class.header-name]="column == 'name'" class="{{((column.indexOf('p_') != -1) ? columnsToDisplayInfo[column].colorClass : '')}}">
                    <span [class.header-90deg]="column != 'name' && column != 'tools' && column.indexOf('year') == -1" matTooltip="{{columnsToDisplayInfo[column].tooltip}}" (click)="collapseProject(getProjectId(column))">

                        <button mat-mini-fab 
                            color="primary" 
                            aria-label="Teams/User aus-/einblenden" 
                            style="transform: scale(0.6);" 
                            (click)="hideShowTeamsOrUsers()"
                            *ngIf="column == 'name'">
                            <mat-icon>visibility_off</mat-icon>
                        </button>        
    
                        {{columnsToDisplayInfo[column].title}}

                        <span *ngIf="column.indexOf('p_') != -1">
                            <mat-icon class="font-size-16" *ngIf="projectCollapsedMap[getProjectId(column)] == true">
                                keyboard_arrow_left</mat-icon>
                            <mat-icon 
                                class="font-size-16" 
                                style="transform: rotate(90deg);" 
                                *ngIf="projectCollapsedMap[getProjectId(column)] != true && getProjectId(column).indexOf('___') == -1">
                                visibility_off
                            </mat-icon>
                        </span>

                    </span>

                </div>
                <div class="p-avatar" class="{{columnsToDisplayInfo[column].colorClass}}">
                    <span class='aui-avatar aui-avatar-xsmall' *ngIf='columnsToDisplayInfo[column].avatar != null'>
                        <span class='aui-avatar-inner'>
                            <img src='{{columnsToDisplayInfo[column].avatar}}' alt='Project Avatar'>
                        </span>
                    </span>
                    <div class="add-virtual-project" *ngIf="column.indexOf('p_') != -1" (click)="addOrEditVirtualGroup(column)">
                        <mat-icon class="font-size-16" *ngIf="!isVirtualGroup(column)">
                            library_add
                        </mat-icon>
                        <mat-icon class="font-size-16" *ngIf="isVirtualGroup(column)">
                            edit
                        </mat-icon>
                    </div>
                </div>
                <!--
                <div *ngIf="column == 'name'">
                    <div *ngFor="let monthDescription of selectedMonths">&nbsp;</div>
                </div>
                -->
                <div class="month-column" *ngIf="column == 'month_column'">
                    <div *ngFor="let monthDescription of selectedMonths; trackBy: trackByFn">{{monthDescription.label}}</div>
                </div>
                <div *ngIf="column.indexOf('p_') != -1" class="{{columnsToDisplayInfo[column].colorClass}}">
                    <div class="tempo-info planned-transparent"
                        (click)="toggleReportMode()">
                        <b>{{reportModeLabel()}}</b>
                    </div>
                    <div *ngFor="let monthDescription of selectedMonths; trackBy: trackByFn">
                        <app-cell-item-renderer *ngIf="projectCollapsedMap[getProjectId(column)] != true"
                            [valueObject]="columnsToDisplayInfo[column].project"
                            [key]="monthDescription.month"
                            [type]="CELL_TYPE.WORKLOAD_NUMBER"
                            [threshold1]="ressourceCube.getPlannedTimeForProject(getProjectId(column), monthDescription.month)"
                            [viewUnit]="(reportMode == REPORT_MODE.RESSOURCE_HOURS)?UNIT.HOURS:UNIT.DAYS"
                            (refresh)="saveProject(getProjectId(column))"
                            >
                        </app-cell-item-renderer>
                    </div>
                </div>
            </th>
            <td mat-cell *matCellDef="let row" 
                (mouseover)="selectedColumn(column)" 
                [class.selected-column]="column == selectedColumnName" 
                [class.text-center]="column != 'name'" 
                [class.hide-column]="projectCollapsedMap[getProjectId(column)] == true || projectCollapsedMap[getProjectId(column).split('___')[0]] == true"
                [class.max-width]="column != 'name' && column.indexOf('p_') == -1"
                class="column-{{columnsToDisplayInfo[column].class}} {{(userExpandMap[row.accountId] != true) ? columnsToDisplayInfo[column].colorClass : ''}}" >
                <div *ngIf="column == 'name'" class="text-right">
                    <span>
                        <mat-icon class="font-size-16" *ngIf="userExpandMap[row.accountId] != true">
                            keyboard_arrow_right</mat-icon>
                        <mat-icon class="font-size-16" *ngIf="userExpandMap[row.accountId] == true">
                            keyboard_arrow_down</mat-icon>
                        <mat-icon class="font-size-16" *ngIf="!row.isExpandable"></mat-icon>
                    </span>
                    <span class='aui-avatar aui-avatar-xsmall' *ngIf='row.avatar != ""'>
                        <span class='aui-avatar-inner'>
                            <img src='{{row.avatarUrl}}' alt='Project Avatar'>
                        </span>
                    </span>
                    <span>
                        &nbsp;&nbsp;<b>{{row.displayName}}</b>
                    </span>
                    <span style="min-width: 3em;">
                        <span style="float: right; width: 2em;" [style.visibility]=" ressourceCube.isDataLoading(row.accountId) ? 'visible' : 'hidden'">
                            <mat-spinner color="primary" diameter="16"></mat-spinner>
                        </span>
                        &nbsp; 
                    </span>
                </div>
                <div *ngIf="userExpandMap[row.accountId] == true" class="{{columnsToDisplayInfo[column].colorClass}}">
                    <div class="month-column" *ngIf="column == 'month_column' && ressourceCube.isDataLoaded(row.accountId)">
                        <div *ngFor="let monthDescription of selectedMonths; trackBy: trackByFn"><div>&nbsp;</div><div class="tempo-info">{{monthDescription.label}}</div></div>
                    </div>
                    <div class="month-column" *ngIf="column == 'pot_column' && ressourceCube.isDataLoaded(row.accountId)">
                        <div *ngFor="let monthDescription of selectedMonths; trackBy: trackByFn"
                            title="{{getTooltipString(row.getPotDays(monthDescription.month, UNIT.HOURS, 2))}}">
                            <div>&nbsp;</div>
                            <div class="tempo-info">{{row.getPotDays(monthDescription.month, (reportMode == REPORT_MODE.RESSOURCE_HOURS)?UNIT.HOURS:UNIT.DAYS)}}</div>
                        </div>
                    </div>
                    <div class="month-column" *ngIf="column == 'holiday_column' && ressourceCube.isDataLoaded(row.accountId)">
                        <div *ngFor="let monthDescription of selectedMonths; trackBy: trackByFn"
                            title="{{getTooltipString(row.getHolidayDays(monthDescription.month, UNIT.HOURS, 2))}}">
                            <div>&nbsp;</div>
                            <div class="tempo-info">
                                <button [matMenuTriggerFor]="appMenu" (click)="setHolidaySickStartDate(row.accountId, monthDescription); $event.stopPropagation()">
                                    {{row.getHolidayDays(monthDescription.month, (reportMode == REPORT_MODE.RESSOURCE_HOURS)?UNIT.HOURS:UNIT.DAYS)}}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="month-column" *ngIf="column == 'available_column' && ressourceCube.isDataLoaded(row.accountId)">
                        <div *ngFor="let monthDescription of selectedMonths; trackBy: trackByFn" title="{{getTooltipString(row.getAvailableDays(monthDescription.month, UNIT.HOURS, 2))}}"><div>&nbsp;</div><div class="tempo-info">{{row.getAvailableDays(monthDescription.month, (reportMode == REPORT_MODE.RESSOURCE_HOURS)?UNIT.HOURS:UNIT.DAYS)}}</div></div>
                    </div>
                    <div class="month-column" *ngIf="column == 'real_column' && ressourceCube.isDataLoaded(row.accountId)">
                        <div *ngFor="let monthDescription of selectedMonths; trackBy: trackByFn" title="{{getTooltipString(row.getRealDays(monthDescription.month, UNIT.HOURS, 2))}}"><div>&nbsp;</div><div class="tempo-info">{{row.getRealDays(monthDescription.month, (reportMode == REPORT_MODE.RESSOURCE_HOURS)?UNIT.HOURS:UNIT.DAYS)}}</div></div>
                    </div>
                    <div class="month-column" *ngIf="column == 'rest_column' && ressourceCube.isDataLoaded(row.accountId)">
                        <div *ngFor="let monthDescription of selectedMonths; trackBy: trackByFn" title="{{getTooltipString(row.getRestDays(monthDescription.month, UNIT.HOURS, 2))}}"><div>&nbsp;</div><div class="tempo-info">{{row.getRestDays(monthDescription.month, (reportMode == REPORT_MODE.RESSOURCE_HOURS)?UNIT.HOURS:UNIT.DAYS)}}</div></div>
                    </div>
                    <div class="month-column" *ngIf="column == 'all_column' && ressourceCube.isDataLoaded(row.accountId)">
                        <div *ngFor="let monthDescription of selectedMonths; trackBy: trackByFn" title="{{getTooltipString(row.getAllDays(monthDescription.month, UNIT.HOURS, 2))}}"><div>&nbsp;</div><div class="tempo-info">{{row.getAllDays(monthDescription.month, (reportMode == REPORT_MODE.RESSOURCE_HOURS)?UNIT.HOURS:UNIT.DAYS)}}</div></div>
                    </div>
                    <div *ngIf="column.indexOf('p_') != -1 && ressourceCube.isDataLoaded(row.accountId) && projectCollapsedMap[getProjectId(column)] != true" class="project-cluster-div">
                        <div *ngFor="let monthDescription of selectedMonths; trackBy: trackByFn">
                            <div >
                                <span  *ngIf="ressourceCube.isDataLoaded(row.accountId)" class="{{getWorkloadCssClass(row.accountId, getProjectId(column), monthDescription)}}"
                                    title="{{getTooltipString(ressourceCube.getData(row.accountId, getProjectId(column), monthDescription.month).getTimeSpendFormated())}}">
                                    {{ressourceCube.getData(row.accountId, getProjectId(column), monthDescription.month).getTimeSpendFormated((reportMode == REPORT_MODE.RESSOURCE_HOURS)?UNIT.HOURS:UNIT.DAYS)}}
                                </span>
                            </div>
                            <div title="{{getTooltipString(ressourceCube.getPlan(row.accountId, getProjectId(column), monthDescription.month).getPlannedFormated())}}" [class.planned-updated-glow]="isPlannedTimeUpdated(row.accountId, getProjectId(column), monthDescription)">
                                <span>
                                    <span  *ngIf="ressourceCube.isDataLoaded(row.accountId)">
                                        <b>
                                            <app-cell-item-renderer *ngIf="ressourceCube.isDataLoaded(row.accountId)"
                                                id="{{row.accountId + '_' + getProjectId(column) + '_' + monthDescription.month}}"
                                                [showSpinner]="isPlannedTimeUpdated(row.accountId, getProjectId(column), monthDescription)"
                                                [updated]="isPlannedTimeUpdated(row.accountId, getProjectId(column), monthDescription)"
                                                [valueObject]="ressourceCube"
                                                [key]="row.accountId"
                                                [projectId]="getProjectId(column)"
                                                [month]="monthDescription.month"
                                                [type]="CELL_TYPE.PLANNED_NUMBER"
                                                [viewUnit]="(reportMode == REPORT_MODE.RESSOURCE_HOURS)?UNIT.HOURS:UNIT.DAYS"
                                                (refresh)="handleBlur(row.accountId, getProjectId(column), monthDescription, $event)"
                                                >
                                            </app-cell-item-renderer>   <!--{{ressourceCube.getPlan(row.accountId, getProjectId(column), monthDescription.month).getPlannedFormated()}}-->
                                        </b>
                                    </span>
                                </span>
                            </div>
                            <!--<mat-chip><span>-</span></mat-chip>--> <!--<cell-item-renderer [valueObject]="columnsToDisplayInfo[column].project" [key]="'x'" [type]="CELL_TYPE.WORKLOAD_NUMBER"></cell-item-renderer>-->
                        </div>
                    </div>
                    <div class="month-column" *ngIf="column == 'real_plan_column' && ressourceCube.isDataLoaded(row.accountId)">
                        <div *ngFor="let monthDescription of selectedMonths; trackBy: trackByFn" title="{{getTooltipString(row.getNotYetPlannedTime(monthDescription.month))}} verfügbar"><div>-</div><div class="tempo-info" class="{{cssClassPlannedTime(row.accountId, monthDescription)}}">{{row.getNotYetPlannedTime(monthDescription.month, (reportMode == REPORT_MODE.RESSOURCE_HOURS)?UNIT.HOURS:UNIT.DAYS, 1)}}</div></div>
                    </div>
                </div>
            </td>
            <td mat-footer-cell *matFooterCellDef 
                (mouseover)="selectedColumn(column)" 
                [class.selected-column]="column == selectedColumnName" 
                [class.hide-column]="projectCollapsedMap[getProjectId(column)] == true || projectCollapsedMap[getProjectId(column).split('___')[0]] == true"
                [class.max-width]="column != 'name' && column.indexOf('p_') == -1"
                class="text-center" 
                class="column-{{columnsToDisplayInfo[column].class}} {{columnsToDisplayInfo[column].colorClass}}">
                <div class="month-column" *ngIf="column == 'month_column'">
                    <div *ngFor="let monthDescription of selectedMonths; trackBy: trackByFn">
                        <div class="tempo-info">{{monthDescription.label}}</div>
                    </div>
                </div>
                <div class="month-column" *ngIf="column.indexOf('p_') != -1 && projectCollapsedMap[getProjectId(column)] != true">
                    <div *ngFor="let monthDescription of selectedMonths; trackBy: trackByFn" title="{{getTooltipString(ressourceCube.getPlannedTimeForProjectFormated(getProjectId(column), monthDescription.month))}}">
                        <div class="tempo-info planned-transparent">{{ressourceCube.getPlannedTimeForProjectFormated(getProjectId(column), monthDescription.month, (reportMode == REPORT_MODE.RESSOURCE_HOURS)?UNIT.HOURS:UNIT.DAYS)}}</div>
                    </div>
                </div>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail" sticky>
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
            </td>
        </ng-container>

        <!-- Header and Row Declarations -->
        <div>
            <tr mat-header-row *matHeaderRowDef="groupColumnsToDisplay; " class="header01"></tr>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true" ></tr>
        </div>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
            [class.example-expanded-row]="expandedElement === element"
            [class.header-row]="element.isHeader"
            [class.hide-row]="userShowMap[element.accountId] != true"

            (click)="expandRow(element); this._ChangeDetectorRef.detectChanges();">
        </tr>
        <tr mat-footer-row *matFooterRowDef="columnsToDisplay"></tr>
    </table>

    <mat-menu #appMenu="matMenu" (close)="handleBlurMenu()">
        <div (click)="$event.stopPropagation()" class="calendars">
            <div  class="holiday-calendar">
                <div>URLAUB:</div>
                <mat-calendar #calendarHoliday
                    (selectedChange)="select($event, holidaySickStartUser.holiday_dates, calendarHoliday)"
                    [startAt]="holidaySickStartDate"
                    [dateClass]="isHolidaySelected">
                </mat-calendar>
            </div>
            <div  class="sick-calendar">
                <div>KRANKHEIT:</div>
                <mat-calendar #calendarSick
                    (selectedChange)="select($event, holidaySickStartUser.sick_dates, calendarSick)"
                    [startAt]="holidaySickStartDate"
                    [dateClass]="isSickSelected">
                </mat-calendar>
            </div>
        </div>
    </mat-menu>
</div>

<ng-template #virtualGroupDialog>
	<h2 matDialogTitle>Virtuelle Sektion anlegen</h2>
	<mat-dialog-content>
        <p>Für: {{selectedProject.name}}</p>
        <mat-form-field>
            <mat-label>Epic</mat-label>
            <mat-select #selectEpic (selectionChange)="selectVersion.value = null; selectComponent.value = null;">
              <mat-option *ngFor="let issue of selectedProject.epics; trackBy: trackByFn" [value]="issue">
                {{issue.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          oder
          <mat-form-field>
            <mat-label>Component</mat-label>
            <mat-select #selectComponent (selectionChange)="selectVersion.value = null; selectEpic.value = null;">
              <mat-option *ngFor="let component of selectedProject.components; trackBy: trackByFn" [value]="component">
                {{component.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          oder
          <mat-form-field>
            <mat-label>Version</mat-label>
            <mat-select  #selectVersion 
                        (selectionChange)="selectEpic.value = null; selectComponent.value = null;"
                        [compareWith]="compareSelection">
              <mat-option *ngFor="let version of selectedProject.versions; trackBy: trackByFn" [value]="version">
                {{version.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>


        <div style="display: flex; flex-flow: row wrap;">
            <div style="cursor: pointer;" *ngFor="let color of colors; trackBy: trackByFn" (click)="selectedVirtualGroup.color = color">
                <svg width="35" height="34">
                    <circle cx="17" cy="17" r="8" [attr.stroke]="color" stroke-width="8" [attr.fill]="getFillColor(color)"></circle>
                </svg>
            </div>
        </div>

          <!--
        <mat-form-field>
            <mat-label>Virtuelle Sektion</mat-label>
            <input matInput [(ngModel)]="selectedVirtualGroup.name" [ngModelOptions]="{standalone: true, updateOn:'blur'}" placeholder="Virtuelle Sektion">
        </mat-form-field>
        -->
	</mat-dialog-content>
	<mat-dialog-actions align="end">
        <button mat-fab matDialogClose="delete" color="warn" aria-label="Löschen" style="margin-right: 56%;" *ngIf="showVirtualGroupDeleteButton">
            <mat-icon>delete</mat-icon>
          </button>
		<button mat-raised-button matDialogClose="confirm" color="primary">Speichern</button>
		<button mat-raised-button matDialogClose="cancel">Abbrechen</button>
	</mat-dialog-actions>
</ng-template>

<ng-template #deleteDialog>
	<h2 matDialogTitle>Löschen?</h2>
	<mat-dialog-content>
		<p>Soll die Sektion</p>
		<p><b>{{selectedProject.name}}&nbsp;/&nbsp;{{selectedVirtualGroup.name}}</b></p>
		<p>wirklich gelöscht werden?</p>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-raised-button matDialogClose="confirm" color="warn">Löschen</button>
		<button mat-raised-button matDialogClose="cancel">Abbrechen</button>
	</mat-dialog-actions>
</ng-template>

<ng-template #hideShowProjectDialog>
	<h2 matDialogTitle>Projekte aus-/einblenden</h2>

        <mat-dialog-content>
            <mat-checkbox 
            [ngModel]="allProjectsCollapsed() == 'unchecked'" 
            [indeterminate]="allProjectsCollapsed() == 'mixed'"
            [ngModelOptions]="{standalone: true}" 
            (ngModelChange)="selectShowHideAllProject(!$event)"
            >
            Alle
        </mat-checkbox>
        <br>
        <br>
        <div *ngFor="let project of projectService.projects; trackBy: trackByFn">
            
            <mat-checkbox 
                [(ngModel)]="!projectCollapsedMap[project.id]" 
                [ngModelOptions]="{standalone: true}" 
                *ngIf="project.type != PROJECT_TYPE.GROUP"
                (ngModelChange)="selectShowHideProject(project.id, $event)"
                >
                {{project.name}}
            </mat-checkbox>
            <div *ngIf="project.type == PROJECT_TYPE.GROUP">
                <br>
                <b>{{project.name}}</b>
            </div>
            <div *ngFor="let subProject of project.subProjects; trackBy: trackByFn">
                <mat-checkbox 
                    [(ngModel)]="!projectCollapsedMap[subProject.id]" 
                    [ngModelOptions]="{standalone: true}" 
                    (ngModelChange)="selectShowHideProject(subProject.id, $event)"
                    >
                    {{subProject.name}}
                </mat-checkbox>
            </div>
            <br>
    
        </div>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-raised-button matDialogClose="cancel">OK</button>
	</mat-dialog-actions>
</ng-template>

<ng-template #hideShowTeamsOrUsersDialog>
	<h2 matDialogTitle>Teams/User aus-/einblenden</h2>

        <mat-dialog-content>
            
            <div *ngFor="let tempoTeam of tempoTeams; trackBy: trackByFn">
                <mat-checkbox 
                    [ngModel]="allUsersInTeamShow(tempoTeam) == 'checked'" 
                    [indeterminate]="allUsersInTeamShow(tempoTeam) == 'mixed'"
                    [ngModelOptions]="{standalone: true}" 
                    (ngModelChange)="selectShowHideTeams(tempoTeam, $event)"
                    >
                    <b>{{tempoTeam.name}}</b>
                </mat-checkbox>
                <div *ngFor="let user of tempoTeam.memberUsers; trackBy: trackByFn">
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <mat-checkbox 
                        [(ngModel)]="userShowMap[user.accountId]" 
                        [ngModelOptions]="{standalone: true}" 
                        (ngModelChange)="selectShowHideUsers(user.accountId, $event)"
                        >
                        {{user.displayName}}
                    </mat-checkbox>
                </div>
                <br>
        
            </div>
        </mat-dialog-content>
    
	<mat-dialog-actions align="end">
		<button mat-raised-button matDialogClose="cancel">OK</button>
	</mat-dialog-actions>
</ng-template>
