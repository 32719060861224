import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import * as fromRessourcesTable from './ressources-table.reducer';

/**
 * App State/Reducers
 */
// tslint:disable-next-line: no-empty-interface
export interface AppState {
  [fromRessourcesTable.ressourcesTableFeatureKey]: fromRessourcesTable.State;
}

export const reducers: ActionReducerMap<AppState> = {
  [fromRessourcesTable.ressourcesTableFeatureKey]: fromRessourcesTable.reducer,
};

/**
 * MetaReducers
 */
export const metaReducers: MetaReducer<any>[] = !environment.production ? [] : [];
