import { formatNumber } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { JiraconnectorService } from 'src/app/jiraconnector';
import { CELL_TYPE, UNIT } from 'src/app/model/enums';
import { trackByFn } from 'src/app/model/globalFunctions';
import { FundingProject, Timesheet, TimeSheetRow } from 'src/app/model/Timesheet';
import { User } from 'src/app/model/User';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-time-statement-hourly-rate-table',
  templateUrl: './time-statement-hourly-rate-table.component.html',
  styleUrls: ['./time-statement-hourly-rate-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeStatementHourlyRateTableComponent implements OnInit {
  @Input()
  public get userTimesheets(): Timesheet[] {
    return this._userTimesheets;
  }
  public set userTimesheets(value: Timesheet[]) {

    this._userTimesheets = value;

    this.generateMaps();

    this._years = null;
    this._fundingProjects = null;

  }
  public get years(): number[] {
    if (this._years === undefined || this._years === null) {
      try {
        const ar_nYear: number[] = [];
        for (const timesheet of this.userTimesheets) {
          if (ar_nYear.indexOf(timesheet.monthDate.getFullYear()) === -1) {
            ar_nYear.push(timesheet.monthDate.getFullYear());
          }
        }

        this._years = ar_nYear;

      } catch (error) {

      }

    }
    return this._years;
  }
  public set year(value: number[]) {
    this._years = value;
  }
  public get fundingProjects() {
    // if (this._fundingProjects === undefined || this._fundingProjects === null) {
      try {
        const ar_fundingProjects: FundingProject[] = [];
        const hm_fundingProjects = {};

        for (const timesheet of this.userTimesheets) {
          for (const fundingProject of timesheet.fundingProjects) {
            hm_fundingProjects[fundingProject.id] = fundingProject;
          }
        }
        for (const fundingProjectId of Object.keys(hm_fundingProjects)) {
          ar_fundingProjects.push(hm_fundingProjects[fundingProjectId]);
        }

        this._fundingProjects = ar_fundingProjects;

      } catch (error) {

      }

    // }
      return this._fundingProjects;
  }
  public set fundingProjects(value) {
    this._fundingProjects = value;
  }

  constructor(
    public _ChangeDetectorRef: ChangeDetectorRef,
    private _jiraconnector: JiraconnectorService,
  ) { }

  @Input() userRow: User;

  private _monthmap_timesheets = {};
  private _columnIds = {};
  private _columnInfo = {};
  private _timeSheetRows = {};
  private _fundingTimeSheetRows = {};

  private _funding_values = {};

  private _userTimesheets: Timesheet[];

  @Input() timesheets: any = {};

  @Input() holidayDates: any = {};

  private _years: number[];

  private _fundingProjects;

  @Output() refresh = new EventEmitter<any>();

  moment = moment;
  UNIT = UNIT;
  CELL_TYPE = CELL_TYPE;
  trackByFn = trackByFn;

  generateMaps() {
    try {
      const _self = this;

      _self._funding_values = {};

      function addDataToFundingValues(timesheet: Timesheet, month_key: string, id: string) {
        const timeSheetRow = timesheet.fundingTimeSheetRows.find(item => item.projectId === id);
        if (timeSheetRow) {
          const value = timeSheetRow.getValue('SUM');
          if (!_self._funding_values[id + '_' + month_key]) {
            _self._funding_values[id + '_' + month_key] = value;

            const sum_key = id + '_' + month_key.split('_')[0] + '_SUM';
            _self._funding_values[sum_key] = (_self._funding_values[sum_key] || 0) + value;
          }
        }
      }

      if (this.fundingProjects !== undefined && this.fundingProjects !== null) {
        for (const fundingProject of this.fundingProjects) {
          for (const timesheet of this.userTimesheets) {
            const tmpfundingProjects = timesheet.fundingProjects;
            timesheet.fundingProjects = this.fundingProjects;
            timesheet.refresh();
            const month_key = moment(timesheet.monthDate).format('YYYY_MM');

            this._columnIds[month_key] = timesheet.columnIds;
            this._columnInfo[month_key] = timesheet.columnsInfo;
            this._timeSheetRows[month_key] = timesheet.timeSheetRows;
            this._fundingTimeSheetRows[month_key] = timesheet.fundingTimeSheetRows;
            this._monthmap_timesheets[month_key] = timesheet;

            addDataToFundingValues(timesheet, month_key, fundingProject.id);
            addDataToFundingValues(timesheet, month_key, 'Other');
            addDataToFundingValues(timesheet, month_key, 'SummeProd');
            addDataToFundingValues(timesheet, month_key, 'HOLIDAY');

            timesheet.fundingProjects = tmpfundingProjects;
            timesheet.refresh();
          }
        }
      }

    } catch (error) {

    }
    this._ChangeDetectorRef.detectChanges();

  }

  ngOnInit(): void {
  }

  filterRows(array, key: string, value: string, equals: boolean = false) {
    if (equals) {
      return array.filter(item => item[key] === value);
    } else {
      return array.filter(item => item[key] !== value);
    }
  }

  isFundingTimeSheetRowEditable(timesheet: Timesheet, column: string, fundingTimeSheetRow: TimeSheetRow) {
    if (timesheet.columnsInfo[column].key.indexOf('day') !== -1) {
      if (('' + fundingTimeSheetRow.getValue('name')).indexOf('onstige') !== -1) {
        return false;
      }

      if (fundingTimeSheetRow.projectName.indexOf('umme') !== -1 ||
      fundingTimeSheetRow.projectName.indexOf('esamt') !== -1 ||
      fundingTimeSheetRow.projectName.indexOf('HOLIDAY') !== -1) {
        return true;
      }
      return true;
    } else {
      return false;
    }

  }

  getTimeSheetByYear(year: number) {
    const timeSheet = this.userTimesheets.find(
        item => item.monthDate.getFullYear() === year &&
          (
            (item.hourly_rate !== undefined && item.hourly_rate !== '') ||
            item.total_working_days !== 0 ||
            item.gross_salary !== 0
          )
      );

    if (timeSheet === undefined) {
        this.userTimesheets.find(item => item.monthDate.getFullYear() === year);
    }
    return timeSheet;
  }

  getMonthsByYear(year: number) {
    const ar_month: Date[] = [];
    for (let month = 0; month < 12; month++) {
      const date = new Date(year, month, 1);
      ar_month.push(date);
    }
    return ar_month;
  }

  getFundingValueByMonth(monthDateKey: string, id: string) {
    const month_key = monthDateKey;

    const funding_value = this._funding_values[id + '_' + month_key];
    if (funding_value) {
      return formatNumber(
        funding_value,
        'de',
        '1.0-2',
      );
    }

    return '';
  }

  handleRefresh(event) {

    if ('valueObject' in event) {
      const actTimesheet = event.valueObject;
      for (const timesheet of this.userTimesheets) {
        if (timesheet.monthDate.getFullYear() === actTimesheet.monthDate.getFullYear()) {
          timesheet.gross_salary = actTimesheet.gross_salary;
          timesheet.total_working_days = actTimesheet.total_working_days;
        }
      }
    }

    this.refresh.emit();
  }

}


