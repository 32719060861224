<form>
<table mat-table #InvoicesTable [dataSource]="invoices" multiTemplateDataRows>

	<ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay; trackBy: trackByFn">
        <th mat-header-cell *matHeaderCellDef class="text-center" [class.column-year]="column.indexOf('year') != -1"
        class="column-{{columnsToDisplayInfo[column].class}}">
            <div>
                {{columnsToDisplayInfo[column].title}}
            </div>
            <div *ngIf="column.indexOf('netto') != -1">
                <b>{{nettoString}}</b>
            </div>
            <div *ngIf="column.indexOf('brutto') != -1">
                <b>{{bruttoString}}</b>
            </div>
        </th>
        <td mat-cell *matCellDef="let row" class="column-{{columnsToDisplayInfo[column].class}}" [class.text-center]="column != 'name'"> 
            <div *ngIf="column == 'name'">
                
                <!--<mat-checkbox [(ngModel)]="row.paid" [ngModelOptions]="{standalone: true}" Checked="true" (ngModelChange)="saveInvoicePaid(row)" (click)="$event.stopPropagation();" *ngIf="row.planed !== true"></mat-checkbox>-->
  
                &nbsp;&nbsp;<b>{{row.name}}</b>

            </div>
            <!--<div *ngIf="column != 'tools' && column != 'name'">
                <span>{{row[column]}}</span>
            </div>-->
            <div *ngIf="(column.indexOf('netto') != -1 || column.indexOf('brutto') != -1)">
                <span class="column-{{columnsToDisplayInfo[column].class}}"><b>{{row[column]}}</b></span>
            </div>
            <div *ngIf="column.indexOf('project') != -1">
                <span>{{row[column]}}</span>
            </div>
            <div *ngIf="column.indexOf('DateString') != -1">
                <span>{{row[column]}}</span>
            </div>
            <div *ngIf="column.indexOf('documentNumber') != -1">
                <span *ngIf="row.planed === true">geplant</span><span *ngIf="row.planed !== true">{{row[column]}}</span>
            </div>
            <div *ngIf="column.indexOf('customerNumber') != -1">
                <span>{{row[column]}}</span>
            </div>

            <!-- Tools Definition -->
            <!--<button mat-button class="button-tools" (click)="copyInvoice(row); $event.stopPropagation();" *ngIf="column == 'tools'"><mat-icon>file_copy</mat-icon></button>
            <button mat-button class="button-tools" (click)="removeInvoiceFromList(row); $event.stopPropagation();" *ngIf="column == 'tools'"><mat-icon>delete</mat-icon></button>-->

        </td>
    </ng-container>

    <!-- Expanded Content Column - Edit Invoice -->
    <ng-container matColumnDef="editInvoice">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
            <!--
            <app-invoice-input [type]="VIEW_TYPE.EDIT" [project]="project" [oSubBudget]="oSubBudget" [oInvoice]="element" [oInvoicesTable]="oInvoicesTable" 
                [state]="element == expandedElement ? VIEW_STATE.EDIT : VIEW_STATE.HIDE" *ngIf="element === expandedElement"></app-invoice-input>
                -->
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true" ></tr>
    <tr mat-row 
        class="invoice-row" [class.planed]="element.planed" [class.color-green]="element.paid && !element.planed"  [class.color-yellow]="element.notPaidJustLikeThat" [class.color-red]="element.notPaidYet"
        (click)="expandedElement = expandedElement === element ? null : element; this._ChangeDetectorRef.detectChanges();" 
        *matRowDef="let element; columns: columnsToDisplay;"></tr>
    <tr mat-row *matRowDef="let row; columns: ['editInvoice']" class="edit-row"></tr>

</table>
</form>

<ng-template #deleteDialog>
    <h2 matDialogTitle>Löschen?</h2>
    <mat-dialog-content>
    <p>Soll die Rechnung</p> 
    <p><b>{{selectedInvoice.nameString}}</b></p> 
    <p>wirklich aus der Liste gelöscht werden?</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
    <button mat-button matDialogClose="cancel">Abbrechen</button>
    <button mat-button matDialogClose="confirm" color="warn">Löschen</button>
    </mat-dialog-actions>
  </ng-template>