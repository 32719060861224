import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input, ViewChild, TemplateRef } from '@angular/core';
import { ProjectService } from 'src/app/services/project.service';
import { forkJoin, Observable, Subject, timer } from 'rxjs';
import {
  RessourceCube,
  RessourceMonthData,
  RessourceMonthPlan
} from 'src/app/model/RessourceCube';
import { DataDescription } from 'src/app/model/DataDescription';
import { User } from 'src/app/model/User';
import { LocalStorageWorker } from 'src/app/model/LocalStorageWorker';
import {
  REQUIRED_SECONDS_PER_DAY,
  SECONDS_PER_HOUR
} from 'src/app/model/constants';
import {
  PROJECT_TYPE,
  CELL_TYPE,




  SOCKET_SUBJECT,
  DATA_TYPE, REPORT_MODE,
  UNIT,
  CLASS_TYPE
} from 'src/app/model/enums';
import { Project } from 'src/app/model/Project';
import { JiraconnectorService } from 'src/app/jiraconnector';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { TempoIoService } from 'src/app/services/tempo.io.service';
import { MatTable } from '@angular/material/table';
import { formatNumber } from '@angular/common';
import { SocketService } from 'src/app/services/socket.service';
import { MatCalendar } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { VirtualGroup } from 'src/app/model/VirtualGroup';
import { MatSelect } from '@angular/material/select';
import { JiraVersion } from 'src/app/model/JiraVersion';
import { environment } from 'src/environments/environment';
import { TempoTeam } from 'src/app/model/TempoTeam';
import { trackByFn } from 'src/app/model/globalFunctions';

@Component({
  selector: 'app-ressources-table',
  templateUrl: './ressources-table.component.html',
  styleUrls: ['./ressources-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RessourcesTableComponent implements OnInit {

  public get ressourceCube(): RessourceCube {
    return this._ressourceCube;
  }

  public set ressourceCube(value: RessourceCube) {
    //
  }

  constructor(
    private dialog: MatDialog,
    public fb: FormBuilder,
    public _ChangeDetectorRef: ChangeDetectorRef,
    private _jiraconnector: JiraconnectorService,

    public projectService: ProjectService,
    public tempoIoService: TempoIoService,
    public socketService: SocketService,
  ) {
    this.form = fb.group({
      date: [{begin: this.startDate, end: this.endDate}]
    });

    const ressource_projectCollapsedMap = LocalStorageWorker.instance.get('ressource_projectCollapsedMap');
    if (ressource_projectCollapsedMap !== undefined && ressource_projectCollapsedMap !== null) {
      try {
        this.projectCollapsedMap = JSON.parse(ressource_projectCollapsedMap);
      } catch (error) {}
    }

    const ressource_userShowMap = LocalStorageWorker.instance.get('ressource_userShowMap');
    if (ressource_userShowMap !== undefined && ressource_userShowMap !== null) {
      try {
        this.userShowMap = JSON.parse(ressource_userShowMap);
      } catch (error) {}
    }

    const ressource_reportMode = LocalStorageWorker.instance.get('ressource_reportMode');
    if (ressource_reportMode !== undefined && ressource_reportMode !== null) {
      try {
        this.reportMode = (ressource_reportMode === REPORT_MODE.RESSOURCE_DAYS) ? REPORT_MODE.RESSOURCE_DAYS : REPORT_MODE.RESSOURCE_HOURS;
      } catch (error) {}
    }

  }
  public get selectedMonths(): any[] {

    if (this.ar_selectedMonth.length === 0) {
      const dateBegin = this.form.value.date.begin;
      const dateEnd = this.form.value.date.end;
      let startDate = moment(dateBegin).startOf('month');
      const endDate = moment(dateEnd).endOf('month');

      const ar_month = [];

      while (startDate.isBefore(endDate)) {
        const monthDescription = {
          label: moment(startDate).format('MM/YY'),
          month: moment(startDate).format('YYYY_M'),
          date: moment(startDate).format('YYYY-MM-01'),
        };
        ar_month.push(monthDescription);

        startDate = startDate.add(1, 'month');
      }
      this.ar_selectedMonth = ar_month;
    }

    return this.ar_selectedMonth;

  }

  @ViewChild('RessourcesTable', {static: false}) oRessourcesTable: MatTable<any>;
  @ViewChild('calendarHoliday', {static: false}) calendarHoliday: MatCalendar<Date>;
  @ViewChild('calendarSick', {static: false}) calendarSick: MatCalendar<Date>;
  @ViewChild('virtualGroupDialog', {static: false}) virtualGroupDialog: TemplateRef<any>;
  @ViewChild('deleteDialog', {static: false}) deleteDialog: TemplateRef<any>;
  @ViewChild('hideShowProjectDialog', {static: false}) hideShowProjectDialog: TemplateRef<any>;
  @ViewChild('hideShowTeamsOrUsersDialog', {static: false}) hideShowTeamsOrUsersDialog: TemplateRef<any>;

  @ViewChild('selectEpic', {static: false}) selectEpic: MatSelect;
  @ViewChild('selectComponent', {static: false}) selectComponent: MatSelect;
  @ViewChild('selectVersion', {static: false}) selectVersion: MatSelect;

  @Input() public projectList: Subject<Project[]>;

  public yearString = new Date().getFullYear();
  public nextYearString = new Date().getFullYear() + 1;

  public startDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1, 0, 0, 0, 0);
  public endDate = new Date(new Date(new Date().getFullYear(), new Date().getMonth() + 3, 1, 0, 0, 0, 0).getTime() - 1000);

  public columnsToDisplay     = [];
  public columnsToDisplayInfo = {};

  public groupColumnsToDisplay     = [];
  public groupColumnsToDisplayInfo = {};

  public expandedElement;

  public updatedPlannedTimePosition: any = {};

  private _ressourceCube: RessourceCube = new RessourceCube();

  public selectedColumnName: string;
  public selectedGroupColumnName: string;

  public userList: any = [];
  public userMap: any = {};
  public userExpandMap: any = {};

  public projectCollapsedMap: any = {};

  public teamShowMap: any = {};
  public userShowMap: any = {};

  public selectedProject: Project;
  public selectedVirtualGroup: VirtualGroup;
  public showVirtualGroupDeleteButton = false;

  public colors: string[] = ['#C3CFD9', '#788896', '#4B5C6B', '#4A58FF', '#73539F', '#F258FF', '#18D980', '#498C8A', '#5FFFB5', '#A35454', '#F55265', '#FE9055', '#FFD248'];

  CELL_TYPE = CELL_TYPE;
  REPORT_MODE = REPORT_MODE;
  UNIT = UNIT;
  CLASS_TYPE = CLASS_TYPE;
  PROJECT_TYPE = PROJECT_TYPE;
  trackByFn = trackByFn;

  form: FormGroup;

  public reportMode: REPORT_MODE = REPORT_MODE.RESSOURCE_HOURS;

  private ar_selectedMonth = [];

  holidaySickStartDate: Date;
  holidaySickStartUser: User;

  public tempoTeams: TempoTeam[] = [];

  ngOnInit() {
    console.log('RESSOURCES Component initialised!');

    const _self = this;

    this.socketService.handleDataChange().subscribe((message: any) => {

      const dataDescription: DataDescription = JSON.parse(message.body);

      if (dataDescription.uuid !== _self.socketService.sessionUser.getUUID()) {

        switch (message.subject) {
          case SOCKET_SUBJECT.REFRESH_RESSOURCES_TABLE:

            try {
              this.getWorkloadForSelectedMonthByUser(this.userMap[dataDescription.id], true, () => { // beforeCallback
                this.updatedPlannedTimePosition[dataDescription.body.updatedKey] = true;
              }, () => {  // afterCallback
                setTimeout(() => {
                  this.updatedPlannedTimePosition[dataDescription.body.updatedKey] = false;

                  this._ChangeDetectorRef.detectChanges();
                }, 1000);

              });

              this._ChangeDetectorRef.detectChanges();

            } catch (error) {

            }

            break;

          default:

            break;
        }

      }

    });

    const subscription = this._jiraconnector.getUsersByGroupname('jira-developers').subscribe(result => {
      subscription.unsubscribe();

      const ressource_startDate = LocalStorageWorker.instance.get('ressource_startDate');
      if (ressource_startDate !== undefined && ressource_startDate !== null) {
        this.startDate = new Date(ressource_startDate);
      }
      const ressource_endDate = LocalStorageWorker.instance.get('ressource_endDate');
      if (ressource_endDate !== undefined && ressource_endDate !== null) {
        this.endDate = new Date(ressource_endDate);

        this.form = this.fb.group({
          date: [{begin: this.startDate, end: this.endDate}]
        });
      }

      const users: User[] = [];

      if (result.values) {
        for (const jsonUser of result.values) {
          const user = new User();
          user.displayName = jsonUser.displayName;
          user.avatarUrl = jsonUser.avatarUrls['48x48'];
          user.accountId = jsonUser.accountId;
          users.push(user);

          this.userMap[user.accountId] = user;
        }

        this.userList = users.sort((a, b) => a.displayName.localeCompare(b.displayName, undefined, { sensitivity: 'base' }));

        const ressource_userExpandedMap = LocalStorageWorker.instance.get('ressource_userExpandedMap');
        if (ressource_userExpandedMap !== undefined && ressource_userExpandedMap !== null) {
          try {
            this.userExpandMap = JSON.parse(ressource_userExpandedMap);

          } catch (error) {}
        }

        this.getWorkloadForSelectedMonth();

        setTimeout(() => {
          this.getTeams();
        }, 1000);

      }

      setTimeout(() => {
          this.getFullProjectsForTableData().subscribe(() => {
          // this.tempoIoService.connectedSubject.subscribe(res => {
            this.getWorkloadForSelectedMonth();

          // });
        });
      }, 1500);

    });

    setInterval(() => {
//      this._ChangeDetectorRef.detectChanges();
    }, 700);

  }

  getTeams() {
    this.tempoIoService.getTeamsWithUserIds().subscribe(tempoTeams => {

      for (const tempoTeam of tempoTeams) {
        const users: User[] = [];
        for (const accountId of tempoTeam.memberUserIds) {
          const user = this.userMap[accountId];
          if (user) {
            users.push(user);
          }
        }
        tempoTeam.memberUsers = users.sort((a, b) => a.displayName.localeCompare(b.displayName, undefined, { sensitivity: 'base' }));
      }
      this.tempoTeams = tempoTeams.sort((a, b) => {
        if (!a.name.toUpperCase().startsWith('TEAM') && b.name.toUpperCase().startsWith('TEAM')) { return 1; }
        if (a.name.toUpperCase().startsWith('TEAM') && !b.name.toUpperCase().startsWith('TEAM')) { return -1; }
        if (!a.name.toUpperCase().startsWith('PROJEKTIONISTEN') && b.name.toUpperCase().startsWith('PROJEKTIONISTEN')) { return 1; }
        if (a.name.toUpperCase().startsWith('PROJEKTIONISTEN') && !b.name.toUpperCase().startsWith('PROJEKTIONISTEN')) { return -1; }
        return a.name.toUpperCase().localeCompare(b.name.toUpperCase(), undefined, {sensitivity: 'base'});
      });

    });
  }

  getFullProjectsForTableData(): Observable<any> {

    const observable = new Observable((observer) => {
      if (this.projectService.projects.length === 0) {
        const projectListSubscribtions = this.projectList.subscribe(results => {
          projectListSubscribtions.unsubscribe();
          this._getFullProjectsForTableData().subscribe(() => {
            observer.next('FULL_PROJECT_DATA_LOADED');
            observer.complete();
          });
        });
      } else {
        this._getFullProjectsForTableData().subscribe(() => {
          observer.next('FULL_PROJECT_DATA_LOADED');
          observer.complete();
        });
      }
    });

    return observable;
  }

  _getFullProjectsForTableData(): Observable<any> {

    const observable = new Observable((observer) => {
      const ar_oObservable: Observable<any>[] = [];
      for (const project of this.projectService.projects) {
        const ar_projectObservables = this.projectService.getFullProjectData(project);
        ar_oObservable.push(...ar_projectObservables);
      }
      ar_oObservable.push(timer(1000));

      console.log('ar_oObservable: ' + ar_oObservable.length);

      const allObservablesSubscribtions = forkJoin([...ar_oObservable]).subscribe((responses) => {
        console.log('Second Subscribtion ready!');

        allObservablesSubscribtions.unsubscribe();

        setTimeout(() => {
          this.setColumns();
        }, 500);

        observer.next('INTERNAL_FULL_PROJECT_DATA_LOADED');
        observer.complete();
      });

    });

    return observable;
  }

  getWorkloadForSelectedMonth(): void {
    if (this.userList.length !== 0) {
      const startMonth = this.selectedMonths[0];
      const endMonth = this.selectedMonths[this.selectedMonths.length - 1];

      for (const user of this.userList) {
        user.holiday_dates = [];
        user.sick_dates = [];
        user.holiday_column = {};
      }

      const insertItem = (ar_dates: any[], newItem: any) => {
        const index = ar_dates.findIndex(x => x.date === newItem.date);
        if (index < 0) {
          ar_dates.push(newItem);
        } else {
          ar_dates[index] = newItem;
        }
      };

      for (let iCount = 0; iCount < this.selectedMonths.length; iCount++) {
        const monthDescription = this.selectedMonths[iCount];

        this.tempoIoService.getWorklogsForIssueForMonth('POR-57;POR-58', monthDescription.date).subscribe(res => {

          const hmAccountIdHoliday = {};

          for (const item of res.results) {
            const accountId = item.author.accountId;
            hmAccountIdHoliday[accountId] = ((hmAccountIdHoliday[accountId]) ? hmAccountIdHoliday[accountId] : 0) +
                                            item.timeSpentSeconds * 1;

            const user = this.userMap[accountId];
            if (user) {
              const key = item.issue.key;
              const tempoWorklogId = item.tempoWorklogId;

              const newItem = {date: item.startDate, tempoWorklogId, timeSpentSeconds: item.timeSpentSeconds * 1};
              if (key === 'POR-57') {
                insertItem(user.holiday_dates, newItem);
              }
              if (key === 'POR-58') {
                insertItem(user.sick_dates, newItem);
              }
            }
          }

          for (const accountId of Object.keys(hmAccountIdHoliday)) {
            const user = this.userMap[accountId];

            if (user) {
              user.holiday_column[monthDescription.month] = hmAccountIdHoliday[accountId];
            }

          }

          if (iCount >= this.selectedMonths.length - 1) {
            this.reloadExpandedUsers();
          }

        });

      }

    }
  }

  getWorkloadForSelectedMonthByUser(user: User, silentMode: boolean = false,
                                    beforeCallback: any = null, afterCallback: any = null): void {
    const startMonth = this.selectedMonths[0];
    const endMonth = this.selectedMonths[this.selectedMonths.length - 1];

    // this.ressourceCube.setDataLoaded(user.accountId, false);
    if (!silentMode) {
      this.ressourceCube.setDataLoading(user.accountId);
    }

    // for (const monthDescription of this.selectedMonths) {
    this.tempoIoService.getUserScheduleForMonth(user.accountId, startMonth.date, endMonth.date).subscribe(res => {
        for (const month of Object.keys(res.sumWorkingDay)) {
          user.pot_column[month] = res.sumWorkingDay[month];
        }

        console.log(user.accountId + ' -> ' + JSON.stringify(res)); // user.pot_column[monthDescription.month]);

        this._ChangeDetectorRef.detectChanges();

      });

    this.tempoIoService.getWorklogsForMonth(user.accountId, startMonth.date, endMonth.date).subscribe(res => {

        if (beforeCallback) {
          beforeCallback();
        }

        const hmProjectIdWorkload = {};
        let accountId;

        const ar_oItem = res.results.filter((item) => item.issue.key !== 'POR-57' && item.issue.key !== 'POR-58');

        for (const item of ar_oItem) {
          const month = moment(item.startDate).format('YYYY_M');
          if (hmProjectIdWorkload[month] === undefined) {
            hmProjectIdWorkload[month] = {};
          }

          accountId = item.author.accountId;
          const issueId = item.issue.key;
          let projectId = issueId.split('-')[0];

          const actProject = this.projectService.projectsById[projectId];
          if (actProject) {
            for (const virtualGroup of actProject.virtualGroups) {
              if (virtualGroup.assosiated_issue_keys.indexOf(issueId) !== -1) {
                projectId = virtualGroup.id;
                break;
              }
            }
          }

          hmProjectIdWorkload[month][projectId] = ((hmProjectIdWorkload[month][projectId]) ? hmProjectIdWorkload[month][projectId] : 0) +
                                                  item.timeSpentSeconds * 1;
        }

        for (const month of Object.keys(hmProjectIdWorkload)) {

          this.ressourceCube.removeData(accountId, month);

          const monthWorkload = hmProjectIdWorkload[month];
          let sumProjectReal = 0;
          let sumProjectAll = 0;

          for (const projectId of Object.keys(monthWorkload)) {

            const data = new RessourceMonthData();
            data.timeSpentSeconds = monthWorkload[projectId];
            this.ressourceCube.setData(accountId, projectId, month, data);

            sumProjectAll += data.timeSpentSeconds ? data.timeSpentSeconds : 0;

            if (this.projectService.projectIds.includes(projectId)) {
              sumProjectReal += data.timeSpentSeconds ? data.timeSpentSeconds : 0;
            }
          }

          user.real_column[month] = sumProjectReal;
          user.rest_column[month] = sumProjectAll - sumProjectReal;
          user.all_column[month] = sumProjectAll;
        }

        this.ressourceCube.setDataLoaded(accountId);

        this._ChangeDetectorRef.detectChanges();

      }, (error) => {
        this.ressourceCube.setDataLoaded(user.accountId);
        this._ChangeDetectorRef.detectChanges();
      });

    this.tempoIoService.getPlansForMonth(user.accountId, startMonth.date, endMonth.date).subscribe(res => {

        const hmProjectIdPlan = {};
        const hmProjectIdPlanId = {};
        let accountId = user.accountId;

        const ar_oItem = res.results.filter((item) => item.planItem.type === 'PROJECT');

        for (const item of ar_oItem) {
          const month = moment(item.startDate).format('YYYY_M');
          if (hmProjectIdPlanId[month] === undefined) {
            hmProjectIdPlanId[month] = {};
          }
          if (hmProjectIdPlan[month] === undefined) {
            hmProjectIdPlan[month] = {};
          }

          const reAccountId = /.*?accountId=(.*?)/;
          const reProjectId = /.*?project\/(.*?)/;

          accountId = item.assignee.self.replace(reAccountId, '$1');

          let projectId = item.planItem.self.replace(reProjectId, '$1');

          if (item.description.indexOf('SECTION-ID') !== -1) {
            const reVirtualGroupId = /.*?SECTION-ID.(.*?)\].*?/;
            const virtualGroupId = item.description.replace(reVirtualGroupId, '$1');
            projectId = virtualGroupId;
          }

          if (hmProjectIdPlanId[month][projectId] === undefined) {
            hmProjectIdPlanId[month][projectId] = [];
          }

          hmProjectIdPlanId[month][projectId].push(item.id);

          for (const planValue of item.dates.values) {
            hmProjectIdPlan[month][projectId] = ((hmProjectIdPlan[month][projectId]) ? hmProjectIdPlan[month][projectId] : 0) +
            planValue.timePlannedSeconds * 1;
          }

        }

        for (const month of Object.keys(hmProjectIdPlan)) {
          const monthPlan = hmProjectIdPlan[month];
          let sumProjectReal = 0;
          let sumProjectAll = 0;

          for (const projectId of Object.keys(monthPlan)) {

            this.projectCollapsedMap[projectId] = false;

            const plan = new RessourceMonthPlan();
            plan.plannedSeconds = monthPlan[projectId];
            plan.ids = hmProjectIdPlanId[month][projectId];
            this.ressourceCube.setPlan(accountId, projectId, month, plan);

            sumProjectAll += plan.plannedSeconds ? plan.plannedSeconds : 0;

            const checkProjectId = (projectId.indexOf('___') === -1) ? projectId : projectId.split('___')[0];

            if (this.projectService.projectIds.includes(checkProjectId)) {
              sumProjectReal += plan.plannedSeconds ? plan.plannedSeconds : 0;
            }
          }

          user.real_plan_column[month] = sumProjectReal;
          user.rest_plan_column[month] = sumProjectAll - sumProjectReal;
          user.all_plan_column[month] = sumProjectAll;
        }

        if (afterCallback) {
          afterCallback();
        }

        this.ressourceCube.setDataLoaded(accountId);

        this.setColumns();

      }, (error) => {

        if (afterCallback) {
          afterCallback();
        }

        this.ressourceCube.setDataLoaded(user.accountId);
        this._ChangeDetectorRef.detectChanges();
      });

  }


  selectedColumn(column: string) {
    if (column !== this.selectedColumnName) {
      this.selectedColumnName = column;

      if (this.columnsToDisplayInfo[column].groupColName !== this.selectedGroupColumnName) {
        this.selectedGroupColumnName = this.columnsToDisplayInfo[column].groupColName;
      }

      this._ChangeDetectorRef.detectChanges();
    }
  }

  setColumns() {

    const columns = [];
    const columnsInfo = {};

    const groupColumns = [];
    const groupColumnsInfo = {};

    let colName = 'name';
    columns.push(colName);
    columnsInfo[colName] = {class: 'c-name', key: 'name', title: 'Name'};

    let groupColName = 'pg-' + colName;
    groupColumns.push(groupColName);
    groupColumnsInfo[groupColName] = {class: 'c-name', title: '', colspan: 1};

    const sClass = 'c-m';

    colName = 'pot_column';
    columns.push(colName);
    columnsInfo[colName] = {class: sClass, key: 'pot_column', title: 'Potential', isEditable: true};

    groupColName = 'pg-' + colName;
    groupColumns.push(groupColName);
    groupColumnsInfo[groupColName] = {class: sClass, title: '', colspan: 1};

    colName = 'holiday_column';
    columns.push(colName);
    columnsInfo[colName] = {class: sClass, key: 'holiday_column', title: 'Urlaub/Krank', isEditable: true};

    groupColName = 'pg-' + colName;
    groupColumns.push(groupColName);
    groupColumnsInfo[groupColName] = {class: sClass, title: '', colspan: 1};

    colName = 'available_column';
    columns.push(colName);
    columnsInfo[colName] = {class: sClass, key: 'available_column', title: 'verfügbar', isEditable: true};

    groupColName = 'pg-' + colName;
    groupColumns.push(groupColName);
    groupColumnsInfo[groupColName] = {class: sClass, title: '', colspan: 1};

    colName = 'real_column';
    columns.push(colName);
    columnsInfo[colName] = {class: sClass, key: 'real_column', title: 'gebucht auf Projekte', isEditable: true};

    groupColName = 'pg-' + colName;
    groupColumns.push(groupColName);
    groupColumnsInfo[groupColName] = {class: sClass, title: '', colspan: 1};

    colName = 'rest_column';
    columns.push(colName);
    columnsInfo[colName] = {class: sClass, key: 'rest_column', title: 'Intern/Orga/Anderes Projekt', isEditable: true};

    groupColName = 'pg-' + colName;
    groupColumns.push(groupColName);
    groupColumnsInfo[groupColName] = {class: sClass, title: '', colspan: 1};

    colName = 'all_column';
    columns.push(colName);
    columnsInfo[colName] = {class: sClass, key: 'all_column', title: 'gebucht gesamt', isEditable: true};

    groupColName = 'pg-' + colName;
    groupColumns.push(groupColName);
    groupColumnsInfo[groupColName] = {class: sClass, title: '', colspan: 1};

    colName = 'month_column';
    columns.push(colName);
    columnsInfo[colName] = {class: sClass, key: 'month_column', title: '', isEditable: true};

    groupColName = 'pg-' + colName;
    groupColumns.push(groupColName);
    groupColumnsInfo[groupColName] = {class: sClass, title: '', colspan: 1};

    let iCount = 1;
    for (const project of this.projectService.projects) {

      if (project.type === PROJECT_TYPE.GROUP) {

        let countVGs = 0;
        for (const subProject of project.subProjects) {
          countVGs += subProject.virtualGroups.length;
        }
        groupColName = 'pg-' + 'p_' + project.id;
        groupColumns.push(groupColName);
        groupColumnsInfo[groupColName] = {class: sClass, title: project.name,
                                          colspan: project.subProjects.length > 0 ? project.subProjects.length + countVGs : 1};

        for (const subProject of project.subProjects) {
          const sMonth = ((iCount < 10) ? '0' : '') + iCount;

          const colName = 'p_' + subProject.id; // 'm' + this.yearString + sMonth;
          columns.push(colName);

          columnsInfo[colName] = {class: sClass, key: this.yearString + '_' + iCount, title: subProject.getPartName(),
                                  project: subProject, tooltip: subProject.name, avatar: subProject.avatar,
                                  groupColName, isEditable: true};

          for (const virtualGroup of subProject.virtualGroups) {
            const colName = 'p_' + virtualGroup.id;
            columns.push(colName);

            const sColor = 'ressource-vg-' + virtualGroup.color.replace('#', '');

            columnsInfo[colName] = {class: sClass, colorClass: sColor, key: this.yearString + '_' + iCount,
                                    title: virtualGroup.getPartName(),
                                    project: virtualGroup, tooltip: virtualGroup.name, avatar: subProject.avatar,
                                    groupColName, isEditable: true};

            iCount = iCount + 1;

          }

          iCount = iCount + 1;
        }

      } else {
        const sMonth = ((iCount < 10) ? '0' : '') + iCount;

        const colName = 'p_' + project.id; // 'm' + this.yearString + sMonth;
        columns.push(colName);

        columnsInfo[colName] = {class: sClass, key: this.yearString + '_' + iCount, title: project.getPartName(),
                                project, tooltip: project.name, avatar: project.avatar, isEditable: true};

        for (const virtualGroup of project.virtualGroups) {
          const colName = 'p_' + virtualGroup.id;
          columns.push(colName);

          const sColor = 'ressource-vg-' + virtualGroup.color.replace('#', '');

          columnsInfo[colName] = {class: sClass, colorClass: sColor, key: this.yearString + '_' + iCount,
                                  title: virtualGroup.getPartName(),
                                  project: virtualGroup, tooltip: virtualGroup.name, avatar: project.avatar,
                                  isEditable: true};

          iCount = iCount + 1;
        }

        const countVGs = project.virtualGroups.length;

        groupColName = 'pg-' + colName;
        groupColumns.push(groupColName);
        groupColumnsInfo[groupColName] = {class: sClass, title: '', colspan: 1 + countVGs};

        iCount = iCount + 1;
      }
    }

    colName = 'real_plan_column';
    columns.push(colName);
    columnsInfo[colName] = {class: sClass, key: 'real_plan_column', title: 'noch nicht verplant', isEditable: true};

    /*
    groupColName = 'pg-' + colName;
    groupColumns.push(groupColName);
    groupColumnsInfo[groupColName] = {class: sClass, title: ''};
*/

    /*
    colName = 'year_' + this.yearString;
    columns.push(colName);
    columnsInfo[colName] = {class: sClass + ' column-year', key: this.yearString + '_year', title: '' + this.yearString, isEditable: false};
    */

    /*
    colName = 'tools';
    columns.push(colName);
    columnsInfo[colName] = {class: sClass, title: ''};

    groupColName = 'pg-' + colName;
    groupColumns.push(groupColName);
    groupColumnsInfo[groupColName] = {class: sClass, title: ''};
*/

    this.columnsToDisplay = [];
    this.columnsToDisplayInfo = [];
    this.groupColumnsToDisplay = [];
    this.groupColumnsToDisplayInfo = [];

    this._ChangeDetectorRef.detectChanges();

    this.columnsToDisplay = columns;
    this.columnsToDisplayInfo = columnsInfo;
    this.groupColumnsToDisplay = groupColumns;
    this.groupColumnsToDisplayInfo = groupColumnsInfo;

    this.oRessourcesTable.renderRows();

    this._ChangeDetectorRef.detectChanges();

  }

  getTooltipString(value: any): string {

    if (value === '-') { return value; }

    let returnString = '-';
    try {

      let sValue = value;
      sValue = (sValue as string).replace('.', '');
      sValue = (sValue as string).replace(',', '.');

      const nValue = 1 * sValue as number;

      switch (this.reportMode) {
        case REPORT_MODE.RESSOURCE_HOURS:
          returnString = value + ' h = ' + formatNumber( nValue / 8, 'de', '1.0-2') + ' PT';

          break;

        case REPORT_MODE.RESSOURCE_DAYS:
          returnString = formatNumber( nValue / 8, 'de', '1.0-2') + ' PT = ' + value + ' h';

          break;

        default:
          break;
      }

    } catch (error) {

    }
    return returnString;
  }

  dateRangeChange($event) {

    const test = this.selectedMonths;

    this.ar_selectedMonth = [];

    const startMonth = this.selectedMonths[0];
    const endMonth = this.selectedMonths[this.selectedMonths.length - 1];
    const endDate = new Date(new Date(new Date(endMonth.date).getFullYear(),
                    new Date(endMonth.date).getMonth() + 1, 1, 0, 0, 0, 0).getTime() - 1000);

    LocalStorageWorker.instance.set('ressource_startDate', startMonth.date.toString());
    LocalStorageWorker.instance.set('ressource_endDate', endDate.toString());

    this.getWorkloadForSelectedMonth();

    this.reloadExpandedUsers();

    this._ChangeDetectorRef.detectChanges();
  }

  reloadExpandedUsers() {
    for (const accountId of Object.keys(this.userExpandMap)) {
      const isExpanded = this.userExpandMap[accountId];
      if (isExpanded === true) {
        this.getWorkloadForSelectedMonthByUser(this.userMap[accountId]);
      }
    }
  }

  expandRow(user: User) {
    this.userExpandMap[user.accountId] = this.userExpandMap[user.accountId] !== true;

    try {
      LocalStorageWorker.instance.set('ressource_userExpandedMap', JSON.stringify(this.userExpandMap));
    } catch (error) {}

    if (this.userExpandMap[user.accountId] === true) {
      this.getWorkloadForSelectedMonthByUser(user, false, null, () => {  // afterCallback
        setTimeout(() => {
          this.toggleReportMode();
          this._ChangeDetectorRef.detectChanges();
          this.toggleReportMode();
          this._ChangeDetectorRef.detectChanges();
        }, 1000);
      });
    }
  }

  collapseProject(projectId) {
    if (this.projectService.projectsById[projectId] === undefined || this.projectService.projectsById[projectId] === null) {
      return;
    }

    try {
      this.projectCollapsedMap[projectId] = this.projectCollapsedMap[projectId] !== true;
      LocalStorageWorker.instance.set('ressource_projectCollapsedMap', JSON.stringify(this.projectCollapsedMap));
    } catch (error) {}

    this._ChangeDetectorRef.detectChanges();
  }


  cssClassPlannedTime(accountId, monthDescription) {
    const user = this.userMap[accountId];
    const monthKey = monthDescription.month;

    const available = ((user.pot_column[monthKey]) ? user.pot_column[monthKey] : 0) -
                      ((user.holiday_column[monthKey]) ? user.holiday_column[monthKey] : 0);

    if (user.real_plan_column[monthKey] === undefined || user.real_plan_column[monthKey] === 0) {
      return 'planned-green';
    }

    if (user.real_plan_column[monthKey] > available) {
      return 'planned-red';
    } else if (user.real_plan_column[monthKey] < available) {
      return 'planned-green';
    } else {
      return 'planned-white';
    }
  }

  getWorkloadCssClass(accountId, projectId, monthDescription) {

    const threshold1 = 0.2;
    const threshold2 = 0.3;

    const workloadSeconds = this.ressourceCube.getData(accountId, projectId, monthDescription.month).timeSpentSeconds || 0;
    const plannedSeconds = this.ressourceCube.getPlan(accountId, projectId, monthDescription.month).plannedSeconds || 0;

    if (workloadSeconds === plannedSeconds) {
      if (workloadSeconds === null || workloadSeconds === 0) {
        return '';
      }
      return 'workload-green bold';
    }
    if (workloadSeconds < plannedSeconds * (1.0 + threshold1) && workloadSeconds > plannedSeconds * (1.0 - threshold1)) {
      return 'workload-green';
    }
    if (workloadSeconds > plannedSeconds * (1.0 + threshold2) || workloadSeconds < plannedSeconds * (1.0 - threshold2)) {
      return 'workload-red';
    }
    return 'workload-yellow';
  }

  isPlannedTimeUpdated(accountId, projectId, monthDescription) {
    try {
      return this.updatedPlannedTimePosition[accountId + '_' + projectId + '_' + monthDescription.month] === true;
    } catch (error) {
      return false;
    }
  }

  reportModeLabel(): string {
    switch (this.reportMode) {
      case REPORT_MODE.RESSOURCE_HOURS:
        return 'h';
        break;
      case REPORT_MODE.RESSOURCE_DAYS:
        return 'PT';
        break;
      default:
        return '-';
        break;
    }
  }

  toggleReportMode() {
    this.reportMode = (this.reportMode === REPORT_MODE.RESSOURCE_HOURS) ? REPORT_MODE.RESSOURCE_DAYS : REPORT_MODE.RESSOURCE_HOURS;

    try {
      LocalStorageWorker.instance.set('ressource_reportMode', this.reportMode);
    } catch (error) {}

  }

  reCalcPlanForUser(accountId, monthDescription) {
    const user = this.userMap[accountId];

    const sumProjectAll = user.all_plan_column[monthDescription.month];
    const sumProjectReal = this.ressourceCube.getPlannedTimeForUser(accountId, monthDescription.month);

    user.real_plan_column[monthDescription.month] = sumProjectReal;
    user.rest_plan_column[monthDescription.month] = sumProjectAll - sumProjectReal;

  }

  getProjectId(column: string, replaceString: string = 'p_') {
    try {
      return column.replace(replaceString, '');
    } catch (error) {
      return '';
    }
  }

  handleBlur(accountId, projectId, monthDescription, cellRenderer) {

    this.updatedPlannedTimePosition[accountId + '_' + projectId + '_' + monthDescription.month] = true;
    cellRenderer._ChangeDetectorRef.detectChanges();

    const ressourceMonthPlan = this.ressourceCube.getPlan(accountId, projectId, monthDescription.month);

    this.reCalcPlanForUser(accountId, monthDescription);

    if (ressourceMonthPlan.plannedSeconds !== undefined && !isNaN(ressourceMonthPlan.plannedSeconds)) {
      const saveObject = this.columnsToDisplayInfo['p_' + projectId].project;

      this.tempoIoService.savePlan(this.ressourceCube, accountId, saveObject, monthDescription).subscribe(res => {
        console.log(res);

        this.getWorkloadForSelectedMonthByUser(this.userMap[accountId], true);

        this.updatedPlannedTimePosition[accountId + '_' + projectId + '_' + monthDescription.month] = false;
        cellRenderer._ChangeDetectorRef.detectChanges();

        const dataDescription: DataDescription = new DataDescription();
        dataDescription.type = DATA_TYPE.REFRESH_RESSOURCES_TABLE;
        dataDescription.id = accountId;
        dataDescription.body = {updatedKey: accountId + '_' + projectId + '_' + monthDescription.month};
        this.socketService.dataChange(dataDescription);

        if (cellRenderer.bClickNext) {
          this.clickNextRow(accountId, projectId, monthDescription);
        }
      });
    }
  }

  clickNextRow(accountId, projectId, monthDescription) {
    const sPart: string[] = monthDescription.month.split('_');
    const nextMonth = sPart[0] + '_' + (parseInt(sPart[1], 10) + 1);
    const element = document.getElementById(accountId + '_' + projectId + '_' + nextMonth);

    try {
      element.click();
    } catch (error) {}

  }

  saveProject(projectId: string) {
    let project = this.projectService.projectsById[projectId];

    if (projectId.indexOf('___') !== -1) {
      project = this.columnsToDisplayInfo['p_' + projectId].project.parentProject;
    }

    this.projectService.saveProject(project, () => {
      this._ChangeDetectorRef.detectChanges();
    });
  }

  setHolidaySickStartDate(accountId, monthDescription) {
    this.holidaySickStartUser = this.userMap[accountId];
    this.holidaySickStartDate = new Date(monthDescription.date);

    this.calendarHoliday.activeDate = this.holidaySickStartDate;
    this.calendarSick.activeDate = this.holidaySickStartDate;

    this.calendarHoliday.updateTodaysDate();
    this.calendarSick.updateTodaysDate();
  }

 isHolidaySelected = (event: any) => {
   try {
    return this.isSelected(event, this.holidaySickStartUser.holiday_dates, 'holiday');
   } catch (error) {
    return false;
   }
  }
  isSickSelected = (event: any) => {
    try {
      return this.isSelected(event, this.holidaySickStartUser.sick_dates, 'sick');
     } catch (error) {
      return false;
     }
  }

  isSelected(event: any, selected_dates: any[], label: string) {
    const date = event.getFullYear() + '-' + ('00' + (event.getMonth() + 1)).slice(-2) + '-' + ('00' + event.getDate()).slice(-2);
    const item = selected_dates.find(x => x.date === date);
    try {
      if (item.timeSpentSeconds === 0) {
        return null;
      } else if (item.timeSpentSeconds < REQUIRED_SECONDS_PER_DAY) {
        const hours = Math.round(item.timeSpentSeconds / SECONDS_PER_HOUR);
        return 'selected-' + label + ' strip-' + hours;
      } else {
        return 'selected-' + label;
      }
    } catch (error) {
      return null;
    }
  }
  select(event: any, selected_dates: any[], calendar: any) {
    const date = event.getFullYear() + '-' + ('00' + (event.getMonth() + 1)).slice(-2) + '-' + ('00' + event.getDate()).slice(-2);
    const item = selected_dates.find(x => x.date === date);
    if (item === undefined) {
      selected_dates.push({date, timeSpentSeconds: REQUIRED_SECONDS_PER_DAY});
    } else {
      if (item.oldTimeSpentSeconds === undefined) {
        item.oldTimeSpentSeconds = item.timeSpentSeconds;
      }
      item.timeSpentSeconds -= SECONDS_PER_HOUR;
      if (item.timeSpentSeconds < 0) {
        item.timeSpentSeconds = REQUIRED_SECONDS_PER_DAY;
      }
    }

    calendar.updateTodaysDate();
  }

  handleBlurMenu() {

    const ar_oObservable: Observable<any>[] = [];

    for (const item of this.holidaySickStartUser.holiday_dates) {
      if (item.tempoWorklogId === undefined ||
         (item.oldTimeSpentSeconds !== undefined && item.timeSpentSeconds !== item.oldTimeSpentSeconds)) {
        const observable = this.tempoIoService.saveWorklogForUser(item.tempoWorklogId,
                                                                  this.holidaySickStartUser.accountId,
                                                                  'POR-57', item.timeSpentSeconds,
                                                                  'URLAUBSBLOCKER',
                                                                  item.date);
        ar_oObservable.push(observable);
      }
    }
    for (const item of this.holidaySickStartUser.sick_dates) {
      if (item.tempoWorklogId === undefined ||
         (item.oldTimeSpentSeconds !== undefined && item.timeSpentSeconds !== item.oldTimeSpentSeconds)) {
            const observable = this.tempoIoService.saveWorklogForUser(item.tempoWorklogId,
                                                                      this.holidaySickStartUser.accountId,
                                                                      'POR-58',
                                                                      item.timeSpentSeconds,
                                                                      'KRANK',
                                                                      item.date);
            ar_oObservable.push(observable);
      }
    }

    const allObservablesSubscribtions = forkJoin([...ar_oObservable]).subscribe((err)  => {
      console.log('Holiday and Sick saved!');

      allObservablesSubscribtions.unsubscribe();
      this.getWorkloadForSelectedMonth();

    },
      () => {
      console.log('Holiday and Sick saved!');

      allObservablesSubscribtions.unsubscribe();
      this.getWorkloadForSelectedMonth();

    });

    console.log('COLLAPSE MENU');
  }

  compareSelection(o1: any, o2: any): boolean {
    try {
      return o1.id === environment.selectedVirtualGroup.selection.id;
    } catch (error) {
      console.log(error);
    }
    try {
      return o2.id === environment.selectedVirtualGroup.selection.id;
    } catch (error) {
      console.log(error);
    }
    return false;
  }

  isVirtualGroup(column: string) {
    return column.indexOf('___') !== -1;
  }

  getFillColor(color: string) {
    return (color === this.selectedVirtualGroup.color) ? '#FFFFFF' : color;
  }

  selectedVersion(version: JiraVersion) {
    try {
      if (this.selectedVirtualGroup.selection.type === CLASS_TYPE.JIRA_VERSION) {
        return version.id === this.selectedVirtualGroup.selection.id;
      }
    } catch (error) {
      console.log(error);
    }

    return false;
  }
  addOrEditVirtualGroup(column: string) {

    const _self = this;

    const actSelectedProject = this.columnsToDisplayInfo[column].project;
    if (actSelectedProject.type === CLASS_TYPE.VIRTUAL_GROUP) {
      this.selectedProject = actSelectedProject.parentProject;
      this.showVirtualGroupDeleteButton = true;
    } else {
      this.selectedProject = actSelectedProject;
      this.showVirtualGroupDeleteButton = false;
    }

    if (this.selectedProject !== undefined || this.selectedProject !== null) {
      this.projectService.getProjectEpics(this.selectedProject);
      this.projectService.getProjectComponentsAndVersions(this.selectedProject);
    }

    this.selectedVirtualGroup = this.columnsToDisplayInfo[column].project;
    if (this.selectedVirtualGroup.type !== CLASS_TYPE.VIRTUAL_GROUP)  {
      this.selectedVirtualGroup = VirtualGroup.create();
    }

    environment.selectedVirtualGroup = this.selectedVirtualGroup;

    const dialogRef = this.dialog.open(this.virtualGroupDialog);

    dialogRef.afterOpened().subscribe(result => {
      try {
        switch (this.selectedVirtualGroup.selection.type) {
          case CLASS_TYPE.JIRA_ISSUE:
            this.selectEpic.value = this.selectedVirtualGroup.selection;
            break;
          case CLASS_TYPE.JIRA_COMPONENT:
            this.selectComponent.value = this.selectedVirtualGroup.selection;
            break;
          case CLASS_TYPE.JIRA_VERSION:
            this.selectVersion.value = this.selectedVirtualGroup.selection;
            break;
          default:
            break;
        }
      } catch (error) {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);

      if (_self.selectedVirtualGroup === undefined || _self.selectedVirtualGroup === null) {
        _self.selectedVirtualGroup = VirtualGroup.create();
      }

      if (result === 'confirm') {

        if (_self.selectEpic.value !== undefined ||
            _self.selectComponent.value !== undefined ||
            _self.selectVersion.value !== undefined) {
          _self.selectedVirtualGroup.selection =
                        _self.selectEpic.value || _self.selectComponent.value || _self.selectVersion.value;
        }

        _self.selectedProject.addVirtualGroup(_self.selectedVirtualGroup);

        _self.saveProject(_self.selectedProject.id);

        console.log(_self.selectedVirtualGroup.name);

        _self.setColumns();
      } else if (result === 'delete') {
        this.deleteVirtualGroup();
      }

    });

  }

  deleteVirtualGroup() {
    const _self = this;

    const dialogRef = this.dialog.open(this.deleteDialog);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);

      if (result === 'confirm') {
        _self.selectedProject.deleteVirtualGroup(_self.selectedVirtualGroup);

        _self.saveProject(_self.selectedProject.id);

        _self.setColumns();

      }
    });

  }

  selectShowHideProject(projectId: string, value) {
    this.projectCollapsedMap[projectId] = !value;

    try {
      LocalStorageWorker.instance.set('ressource_projectCollapsedMap', JSON.stringify(this.projectCollapsedMap));
    } catch (error) {}

    this._ChangeDetectorRef.detectChanges();
  }

  selectShowHideAllProject(value) {
    for (const project of this.projectService.projects) {
      if (project.type === PROJECT_TYPE.GROUP) {
        for (const subProject of project.subProjects) {
          this.projectCollapsedMap[subProject.id] = value;
        }
      } else {
        this.projectCollapsedMap[project.id] = value;
      }
    }
    try {
      LocalStorageWorker.instance.set('ressource_projectCollapsedMap', JSON.stringify(this.projectCollapsedMap));
    } catch (error) {}

    this._ChangeDetectorRef.detectChanges();
  }

  allProjectsCollapsed() {
    let countAll = 0;
    let countCollapsed = 0;
    for (const project of this.projectService.projects) {
      if (project.type === PROJECT_TYPE.GROUP) {
        for (const subProject of project.subProjects) {
          countAll += 1;
          countCollapsed += this.projectCollapsedMap[subProject.id] === true ? 1 : 0;
        }
      } else {
        countAll += 1;
        countCollapsed += this.projectCollapsedMap[project.id] === true ? 1 : 0;
      }
    }
    return (countCollapsed === 0) ? 'unchecked' : (countAll > countCollapsed) ? 'mixed' : 'checked';
  }

  allTeamsShow() {
    return 'checked';
  }

  allUsersInTeamShow(tempoTeam: TempoTeam) {
    let countAll = 0;
    let countShow = 0;
    for (const user of tempoTeam.memberUsers) {
        countAll += 1;
        countShow += this.userShowMap[user.accountId] === true ? 1 : 0;
    }
    return (countShow === 0) ? 'unchecked' : (countAll > countShow) ? 'mixed' : 'checked';
  }


  selectShowHideTeams(tempoTeam: TempoTeam, value) {
    for (const user of tempoTeam.memberUsers) {
      this.userShowMap[user.accountId] = value;
    }

    try {
      LocalStorageWorker.instance.set('ressource_userShowMap', JSON.stringify(this.userShowMap));
    } catch (error) {}

    this._ChangeDetectorRef.detectChanges();

  }

  selectShowHideUsers(userId: string, value) {
    this.userShowMap[userId] = value;

    try {
      LocalStorageWorker.instance.set('ressource_userShowMap', JSON.stringify(this.userShowMap));
    } catch (error) {}

    this._ChangeDetectorRef.detectChanges();
  }

  hideShowProjects() {
    const _self = this;

    const dialogRef = this.dialog.open(this.hideShowProjectDialog);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);

      if (result === 'confirm') {

      }
    });

  }

  hideShowTeamsOrUsers() {
    const _self = this;

    this.getTeams();

    const dialogRef = this.dialog.open(this.hideShowTeamsOrUsersDialog);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);

      if (result === 'confirm') {

      }
    });

  }

  getColspan(pgColumn: string) {
    let colspan = this.groupColumnsToDisplayInfo[pgColumn].colspan;

    const projectId = pgColumn.replace('pg-p_', '');

    const project = this.projectService.projectsById[projectId];

    if (project) {
      if (project.type === PROJECT_TYPE.GROUP) {
        for (const subProject of project.subProjects) {
          colspan -= this.projectCollapsedMap[subProject.id] === true ? 1 : 0;
          for (const virtualGroup of subProject.virtualGroups) {
            colspan -= this.projectCollapsedMap[virtualGroup.parentProject.id] === true ? 1 : 0;
          }
          }
      } else {
        colspan -= this.projectCollapsedMap[project.id] === true ? 1 : 0;
        for (const virtualGroup of project.virtualGroups) {
          colspan -= this.projectCollapsedMap[virtualGroup.parentProject.id] === true ? 1 : 0;
        }
      }

    }

    return colspan;
  }
}
