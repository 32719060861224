import { ChangeDetectorRef, Component, EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { isArray } from 'rxjs/internal-compatibility';
import { JiraconnectorService } from 'src/app/jiraconnector';
import { CELL_TYPE, UNIT } from 'src/app/model/enums';
import { trackByFn } from 'src/app/model/globalFunctions';
import { Project } from 'src/app/model/Project';
import { Timesheet, TimeSheetRow } from 'src/app/model/Timesheet';
import { User } from 'src/app/model/User';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-time-statement-table',
  templateUrl: './time-statement-table.component.html',
  styleUrls: ['./time-statement-table.component.scss']
})
export class TimeStatementTableComponent implements OnInit {

  @Input() timestamp: number;

  @Input() userRow: User;
  @Input() timesheet: Timesheet;
  @Input() timesheets: any = {};

  @Input() holidayDates: any = {};

  private _fundingProjects: Project[];
  @Input()
  public get fundingProjectOrProjects(): Project[] | Project {
    return this._fundingProjects;
  }
  public set fundingProjectOrProjects(value: Project[] | Project) {
    if (isArray(value)) {
      this._fundingProjects = value;
    } else {
      this._fundingProjects = [];
      this._fundingProjects.push(value);
    }
  }

  @Output() refresh = new EventEmitter<any>();

  headerExpanded = false;

  public get fundingProjects(): Project[] {
    return this._fundingProjects;
  }

  get fundingTimeSheetRows(): TimeSheetRow[] {
    try {
      return this.timesheet.getFundingTimeSheetRows(this.fundingProjects as Project[]);
    } catch (error) {
      return [];
    }
  }

  public get fundingProjectIds(): string[] {
    const ar_sFundingProjectId: string[] = [];
    for (const fundingProject of this.fundingProjects as Project[]) {
        ar_sFundingProjectId.push(fundingProject.id);
    }

    return ar_sFundingProjectId;
  }

  get fundingProjectsAllId(): string {
    try {
      return this.fundingProjectIds.join('_');
    } catch (error) {
      return '';
    }
  }

  get printButtonLabel(): string {
    if (this.fundingProjects.length === 1) {
      return ' "' + this.fundingProjects[0].name + '"';
    } else {
      return ' "Zusammenfassung"';
    }
  }

  moment = moment;
  UNIT = UNIT;
  CELL_TYPE = CELL_TYPE;
  trackByFn = trackByFn;

  constructor(
    public _ChangeDetectorRef: ChangeDetectorRef,
    private _jiraconnector: JiraconnectorService,

  ) { }

  ngOnInit(): void {
  }

  filterRows(array, key: string, value: string, equals: boolean = false) {
    if (equals) {
      return array.filter(item => item[key] === value);
    } else {
      return array.filter(item => item[key] !== value);
    }
  }

  isHolidayClass(timesheet: Timesheet, column: string): boolean {
    try {
      const monthDate = new Date(timesheet.monthDate);
      const day = column.split('day-')[1];
      monthDate.setDate(parseFloat(day));
      const monthDateString = moment(monthDate).format('YYYY_MM_DD');
      if (this.holidayDates[monthDateString] === true) {
        return true;
      }
    } catch (error) {}

    return false;
  }

  isFundingTimeSheetRowEditable(timesheet: Timesheet, column: string, fundingTimeSheetRow: TimeSheetRow) {
    if (timesheet.columnsInfo[column].key.indexOf('day') !== -1) {
      if (('' + fundingTimeSheetRow.getValue('name')).indexOf('onstige') !== -1) {
        return false;
      }

      if (fundingTimeSheetRow.projectName.indexOf('umme') !== -1 ||
      fundingTimeSheetRow.projectName.indexOf('esamt') !== -1 ||
      fundingTimeSheetRow.projectName.indexOf('HOLIDAY') !== -1) {
        return true;
      }
      return true;
    } else {
      return false;
    }

  }

  handleRefresh() {
    this.refresh.emit();
  }

  toggleHeaderExpand() {
    this.headerExpanded = !this.headerExpanded;
  }

}
