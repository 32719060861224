

<form [class.sub-budget-input-form]="state!=VIEW_STATE.HIDE" (ngSubmit)="saveSubBudget(true);" >
  <button mat-raised-button *ngIf="state==VIEW_STATE.ADD">+ Neues Budget</button>

  <div *ngIf="state!=VIEW_STATE.ADD && state!=VIEW_STATE.HIDE">
    <mat-form-field>
      <mat-label>Budget-Bezeichnung</mat-label>
      <input matInput [(ngModel)]="oSubBudget.name" [ngModelOptions]="{standalone: true, updateOn:'blur'}" [errorStateMatcher]="matcher"
             placeholder="Neue Budget-Bezeichnung" (focusout)="saveSubBudget()">
      <mat-hint>Bezeichnung für das Sub Budget.</mat-hint>
      <mat-error *ngIf="subBudgetInputControl.hasError('email') && !subBudgetInputControl.hasError('required')">
        Bezeichnung eingeben.
      </mat-error>
      <mat-error *ngIf="subBudgetInputControl.hasError('required')">
        Bezeichnung ist <strong>ein Pflichtfeld</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="width-start-date">
        <mat-label>von</mat-label>
        <input matInput [matDatepicker]="pickerStartDate" placeholder="von" [(ngModel)]="oSubBudget.startDate" [ngModelOptions]="{standalone: true, updateOn:'blur'}" (ngModelChange)="saveSubBudget()">
        <mat-datepicker-toggle matSuffix [for] = "pickerStartDate"></mat-datepicker-toggle>
        <mat-datepicker [startAt]="oSubBudget.startDate" #pickerStartDate></mat-datepicker>
     
        <mat-hint>Start-Datum.</mat-hint>
        <mat-error *ngIf="subBudgetInputControl.hasError('email') && !subBudgetInputControl.hasError('required')">
          Start-Datum eingeben.
        </mat-error>
        <mat-error *ngIf="subBudgetInputControl.hasError('required')">
            Start-Datum ist <strong>ein Pflichtfeld</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="width-end-date">
        <mat-label>bis</mat-label>
        <input matInput [matDatepicker]="pickerEndDate" placeholder="bis" [(ngModel)]="oSubBudget.endDate" [ngModelOptions]="{standalone: true, updateOn:'blur'}" (ngModelChange)="saveSubBudget()">
        <mat-datepicker-toggle matSuffix [for] = "pickerEndDate"></mat-datepicker-toggle>
        <mat-datepicker [startAt]="oSubBudget.endDate" #pickerEndDate></mat-datepicker>
       
        <mat-hint>Ende-Datum.</mat-hint>
        <mat-error *ngIf="subBudgetInputControl.hasError('email') && !subBudgetInputControl.hasError('required')">
            Ende-Datum eingeben.
        </mat-error>
        <mat-error *ngIf="subBudgetInputControl.hasError('required')">
            Start-Datum ist <strong>ein Pflichtfeld</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="width-budget">
        <mat-label>Betrag (netto)</mat-label>
        <input matInput type="text" [ngModel]="oSubBudget.budget | currency:'EUR':'':'0.2':'de'" [ngModelOptions]="{standalone: true, updateOn:'blur'}" (ngModelChange)="oSubBudget.setBudget($event); saveSubBudget()" class="right-align">
        <span matPrefix></span>
        <span matSuffix>&nbsp;€</span>
      </mat-form-field>

      <mat-form-field class="width-vat">
        <mat-label>Umsatzsteuer</mat-label>
        <mat-select [(ngModel)]="oSubBudget.vat" [ngModelOptions]="{standalone: true, updateOn:'blur'}" (focusout)="saveSubBudget()">
          <mat-option value="0.0">ohne</mat-option>
          <mat-option value="0.05">5 %</mat-option>
          <mat-option value="0.07">7 %</mat-option>
          <mat-option value="0.16">16 %</mat-option>
          <mat-option value="0.19">19 %</mat-option>
      </mat-select>
      </mat-form-field>

      <mat-form-field class="width-pt">
        <mat-label>Zahlungsziel</mat-label>
        <input matInput type="number" class="right-align" [(ngModel)]="oSubBudget.clearingAddDays" [ngModelOptions]="{standalone: true, updateOn:'blur'}" (ngModelChange)="saveSubBudget()">
        <span matPrefix></span>
        <span matSuffix>&nbsp;Tage</span>
      </mat-form-field>

      <mat-form-field class="width-pt">
        <mat-label>Personentage</mat-label>
        <input matInput type="number" class="right-align" [(ngModel)]="oSubBudget.pt" [ngModelOptions]="{standalone: true, updateOn:'blur'}" (ngModelChange)="saveSubBudget()">
        <span matPrefix></span>
        <span matSuffix>&nbsp;PT</span>
      </mat-form-field>

      <mat-form-field class="width-prognose">
        <mat-label>Prognose</mat-label>
        <mat-select [(ngModel)]="oSubBudget.prognose" [ngModelOptions]="{standalone: true, updateOn:'blur'}" (focusout)="saveSubBudget()">
          <mat-option value="0.0">Potentiell (0-24%)</mat-option>
          <mat-option value="0.25">Angebahnt (25-49%)</mat-option>
          <mat-option value="0.50">Abgestimmt (50-74%)</mat-option>
          <mat-option value="0.75">Mündlich bestätigt (75-99%)</mat-option>
          <mat-option value="1.0">Bestätigt (100%)</mat-option>
      </mat-select>
      </mat-form-field>

      <mat-checkbox [(ngModel)]="oSubBudget.otherOperatingIncome" [ngModelOptions]="{standalone: true}" Checked="true" (ngModelChange)="saveSubBudget()">Forschung</mat-checkbox>

      <button mat-raised-button [disabled]="!edited">
        <span *ngIf="!showSpinner">OK</span>
        <mat-icon *ngIf="showSpinner"><mat-spinner color="primary" diameter="20"></mat-spinner></mat-icon>
      </button>
    </div>
  </form>

  <div *ngIf="state==VIEW_STATE.EDIT">
    <app-invoice-list [project]="project" [oSubBudget]="oSubBudget" [columnsToDisplay]="columnsToDisplay" [columnsToDisplayInfo]="columnsToDisplayInfo"></app-invoice-list>
  </div>