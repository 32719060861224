import { NgModule } from '@angular/core';
import { JiraconnectorService } from './jiraconnector.service';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule
  ],
  providers: [JiraconnectorService],
  bootstrap: []
})
export class JiraconnectorModule { }
