<div *ngIf="type === CELL_TYPE.EURO_VALUE">
    <mat-chip [class.color-red]="isRed" [class.color-yellow]="isYellow" [class.color-green]="isGreen"
        *ngIf="state === VIEW_STATE.SHOW" (click)="handleValueClick()">
        <span>{{valueString}}</span>
    </mat-chip>
    <mat-form-field *ngIf="state === VIEW_STATE.EDIT">
        <mat-label>Betrag</mat-label>
        <input matInput #valueInput type="text" [ngModel]="value | currency:'EUR':'':'0.2':'de'"
            [ngModelOptions]="{standalone: true, updateOn:'blur'}" (focus)="$event.target.select()" (blur)="handleBlur($event.target.value)"
            class="right-align">
        <span matPrefix>€&nbsp;</span>
        <span matSuffix></span>
    </mat-form-field>

</div>
<div *ngIf="type === CELL_TYPE.WORKLOAD_NUMBER">
    <mat-chip [class.color-red]="isRed" [class.color-yellow]="isYellow" [class.color-green]="isGreen"
        *ngIf="state === VIEW_STATE.SHOW" (click)="handleValueClick()">
        <span>{{valueString}}</span>
    </mat-chip>
    <mat-form-field [class.workload-max-width]="type === CELL_TYPE.WORKLOAD_NUMBER" *ngIf="state === VIEW_STATE.EDIT" style="margin-top: -12px;">
        <mat-label>{{(viewUnit === UNIT.HOURS)?'h':'PT'}}</mat-label>
        <input matInput #valueInput type="text" [ngModel]="value | number:'1.0-2':'de'"
            [ngModelOptions]="{standalone: true, updateOn:'blur'}" (focus)="$event.target.select()" (blur)="handleBlur($event.target.value)"
            class="right-align">
        <span matPrefix></span>
        <span matSuffix></span>
    </mat-form-field>

</div>

<div *ngIf="type === CELL_TYPE.PLANNED_NUMBER">
    <mat-chip [class.color-red]="isRed" [class.color-yellow]="isYellow" [class.color-green]="isGreen"
        *ngIf="state === VIEW_STATE.SHOW" (click)="handleValueClick()">
        <span *ngIf="showSpinner != true">{{valueString}}</span>
        <span *ngIf="showSpinner == true">
            <mat-spinner color="primary" diameter="16"></mat-spinner>
        </span>
    </mat-chip>
    <mat-form-field [class.planned-time-max-width]="type === CELL_TYPE.PLANNED_NUMBER" floatLabel="never" *ngIf="state === VIEW_STATE.EDIT" style="margin-top: -12px;">
        <input matInput #valueInput type="text" 
            [ngModel]="value | number:'1.0-2':'de'"
            [ngModelOptions]="{standalone: true, updateOn:'blur'}" 
            (focus)="$event.target.select()" 
            (blur)="handleBlur($event.target.value)" 
            (keypress)="handleKeyPress($event)" 
            (keyup.enter)="handleBlur($event.target.value, true)"
            class="right-align">
    </mat-form-field>

</div>

<div *ngIf="type === CELL_TYPE.TIMESHEET_NUMBER">
    <span *ngIf="state === VIEW_STATE.SHOW"
        (click)="handleValueClick(); $event.stopPropagation();">
        {{valueString}}
    </span>
    <mat-form-field [class.timesheet-max-width]="type === CELL_TYPE.TIMESHEET_NUMBER" floatLabel="never" *ngIf="state === VIEW_STATE.EDIT">
        <input matInput #valueInput type="text" [ngModel]="value | number:'1.0-2':'de'"
            [ngModelOptions]="{standalone: true, updateOn:'blur'}" (focus)="$event.target.select()" (blur)="handleBlur($event.target.value)"
            class="right-align">
    </mat-form-field>

</div>

<div *ngIf="type === CELL_TYPE.TIMESHEET_GROSS_SALARY || type === CELL_TYPE.TIMESHEET_TOTAL_WORKING_DAYS">
    <span *ngIf="state === VIEW_STATE.SHOW"
        (click)="handleValueClick()">
        {{valueString}}
    </span>
    <mat-form-field floatLabel="never" *ngIf="state === VIEW_STATE.EDIT">
        <input matInput #valueInput type="text" [ngModel]="value | number:'1.0-2':'de'"
            [ngModelOptions]="{standalone: true, updateOn:'blur'}" (focus)="$event.target.select()" (blur)="handleBlur($event.target.value)"
            class="right-align">
    </mat-form-field>

</div>

<span *ngIf="type === CELL_TYPE.STRING">
    <span *ngIf="state === VIEW_STATE.SHOW" (click)="handleValueClick()">{{valueString}}</span>
    <mat-form-field *ngIf="state === VIEW_STATE.EDIT">
        <mat-label>Bezeichnung</mat-label>
        <input matInput #valueInput type="text" [ngModel]="value"
            [ngModelOptions]="{standalone: true, updateOn:'blur'}" (blur)="handleBlur($event.target.value)"
            class="right-align">
        <span matPrefix></span>
        <span matSuffix></span>
    </mat-form-field>
</span>