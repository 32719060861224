import { Invoice } from './Invoice';


export class LiqudityTableRow {
  name: string;
  editable = true;

  stored_values: any = {};

  private invoices: any = {};

  get invoicesArray() {

    for (const key of Object.keys(this.invoices)) {
      try {
        this.invoices[key] = this.invoices[key].sort((a, b) => {
          if (!a.virtual && b.virtual) {
            return -1;
          }
          if (a.virtual && !b.virtual) {
            return 1;
          }
          if (!a.planed && b.planed) {
            return -1;
          }
          if (a.planed && !b.planed) {
            return 1;
          }
          if (a.documentNumber < b.documentNumber) {
            return -1;
          }
          if (a.documentNumber > b.documentNumber) {
            return 1;
          }
          if (a.clearingDate < b.clearingDate) {
            return -1;
          }
          if (a.clearingDate > b.clearingDate) {
            return 1;
          }
          return (a.name && b.name) ? a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }) : 0;
        });
      } catch (error) {
        // do nothing
      }
    }

    return [this.invoices];
  }

  orderNumber = '0';
  isBold = false;
  isItalic = false;
  showSum = true;

  isHeader = false;
  isExpandable = false;

  hasAddValue = false;

  subLiqudityTableRow: LiqudityTableRow[] = [];

  getValue(key: string): number {
    if (this.isHeader) {
      return 0;
    }

    if (!this.hasAddValue && key.indexOf('add') !== -1) {
      return 0;
    }

    try {
      if (key.indexOf('year') !== -1) {
        if (!this.showSum) {
          return null;
        }
      }
      if (key === 'name') {
        return null;
      }

      if (key !== 'name' && this.isExpandable && !this.hasInvoices()) {
        let sum = 0;
        for (const item of this.subLiqudityTableRow) {
          const subValue = item.getValue(key);
          if (subValue != null) {
            sum += subValue;
          }
        }
        return sum;
      }

      const stored_values_key = this.stored_values[key];
      if (stored_values_key != null && stored_values_key !== 0) {
        return stored_values_key;
      } else {
        return this.getLastStoredValue(key);
      }
    } catch (error) {
      return null;
    }
  }
  setValue(key: string, value: number) {
    this.stored_values[key] = value;
  }

  hasInvoices() {
    return Object.keys(this.invoices).length !== 0;
  }

  getInvoices(key: string) {
    return this.invoices[key];
  }
  setInvoices(key: string, ar_oInvoice: Invoice[]) {
    this.invoices[key] = ar_oInvoice;
  }

  toJSON() {
    const jsonObj = {
      name: this.name,
      editable: this.editable,
      stored_values: this.stored_values,
      orderNumber: this.orderNumber,
      isBold: this.isBold,
      showSum: this.showSum,
      isHeader: this.isHeader,
      isExpandable: this.isExpandable,
      subLiqudityTableRow: this.subLiqudityTableRow,
    };

    return jsonObj;
  }

  private getLastStoredValue(key: string): number {
    if (key === undefined || this.editable !== true) {
      return null;
    }

    const ar_sKeys = Object.keys(this.stored_values).reverse();

    ar_sKeys.sort((a, b) => {
      const ar_a = a.split('_');
      const ar_b = b.split('_');

      try {
        if (parseInt(ar_a[0], 10) > parseInt(ar_b[0], 10)) {
          return -1;
        }
        if (parseInt(ar_a[0], 10) < parseInt(ar_b[0], 10)) {
          return 1;
        }
        if (parseInt(ar_a[1], 10) > parseInt(ar_b[1], 10)) {
          return -1;
        }
        if (parseInt(ar_a[1], 10) < parseInt(ar_b[1], 10)) {
          return 1;
        }

        return 0;

      } catch (error) {
        return a.localeCompare(b, undefined, { sensitivity: 'base' });
      }
    });

    const ar_sKey = key.split('_');
    if (key.indexOf('add') !== -1) {
      return null;
    }

    if (key.indexOf('year') !== -1) {
      if (!this.showSum) {
        return null;
      }

      let sum = 0;
      for (let iCount = 1; iCount <= 12; iCount++) {
        const sKey = ar_sKey[0] + '_' + iCount;
        const stored_values_key = this.stored_values[sKey];
        if (stored_values_key != null && stored_values_key !== 0) {
          sum += this.stored_values[sKey];
        } else {
          sum += this.getLastStoredValue(sKey);
        }
      }
      return sum;
    }

    for (const actKey of ar_sKeys) {
      const ar_sActKey = actKey.split('_');
      if (actKey.indexOf('year') === -1) {
        if (ar_sKey[0] === ar_sActKey[0] && parseInt(ar_sActKey[1], 10) <= parseInt(ar_sKey[1], 10)) {
          return this.stored_values[actKey];
        }
        if (ar_sKey[0] > ar_sActKey[0]) {
          return this.stored_values[actKey];
        }
      }
    }
    return null;
  }

}
