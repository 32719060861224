<div>
    <mat-form-field class="funding-project-list">
        <mat-label>Stundennachweis für Forschungsprojekt(e)</mat-label>
        <mat-chip-list #fundingProjectList aria-label="Stundennachweis für Forschungsprojekt(e)">
          <mat-chip
            *ngFor="let fundingProjectId of fundingProjectIds; trackBy: trackByFn"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="removeFundingProject(fundingProjectId)">
            {{getFundingProjectById(fundingProjectId).name}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input
            placeholder="Forschungsprojekt ..."
            #fundingProjectInput
            [formControl]="fundingProjectCtrl"
            [matAutocomplete]="fundingProjectAuto"
            [matChipInputFor]="fundingProjectList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
        </mat-chip-list>
        <mat-autocomplete #fundingProjectAuto="matAutocomplete" (optionSelected)="selectedFundingProject($event)">
          <mat-option *ngFor="let fundingProjectId of filteredFundingProjects | async; trackBy: trackByFn" [value]="fundingProjectId">
            {{getFundingProjectById(fundingProjectId).name}} ({{getFundingProjectById(fundingProjectId).fundingNumber}})
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>      
</div>
