<div style="width: 100%; text-align: left; margin-bottom: 1em;" [class.hide-button]="selectedYear == undefined || selectedYear == ''">
    <button
        mat-raised-button
        printTitle="{{selectedYear}}_stundennachweis_{{userRow.lastName}}"
        [useExistingCss]="true"
        printSectionId="print-section-time-statement-group_all_{{userRow.accountId}}"
        ngxPrint>Drucken "Zusammenfassung" "{{selectedYear}}"
    </button>
</div>

<div id="print-section-time-statement-group_all_{{userRow.accountId}}" class="container section-to-print"> 
    <div *ngFor="let timesheet of selectedTimesheets; trackBy: trackByFn">
        <br>
        <app-time-statement-table 
            [userRow]="userRow" 
            [timesheet]="timesheet" 
            [fundingProjectOrProjects]="fundingProjects"
            [timesheets]="timesheets"
            [holidayDates]="holidayDates"
            (refresh)="handleRefresh()">
        </app-time-statement-table>
    </div>
</div>

<div *ngFor="let fundingProject of fundingProjects; trackBy: trackByFn">
    <br>
    <div style="width: 100%; text-align: left; margin-bottom: 1em;">
        <button
            mat-raised-button
            printTitle="{{selectedYear}}_{{fundingProject.name}}_stundennachweis_{{userRow.lastName}}"
            [useExistingCss]="true"
            printSectionId="print-section-time-statement-group_{{fundingProject.name}}_{{userRow.accountId}}"
            ngxPrint>Drucken "{{fundingProject.name}}" "{{selectedYear}}"
        </button>
    </div>

    <div id="print-section-time-statement-group_{{fundingProject.name}}_{{userRow.accountId}}" class="container section-to-print"> 
        <div *ngFor="let timesheet of selectedTimesheets; trackBy: trackByFn">
            <br>
            <app-time-statement-table 
                [userRow]="userRow" 
                [timesheet]="timesheet.clone()" 
                [fundingProjectOrProjects]="fundingProject"
                [timesheets]="timesheets"
                [holidayDates]="holidayDates"
                (refresh)="handleRefresh()">
            </app-time-statement-table>
        </div>
    </div>

</div>