import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { JiraconnectorService } from 'src/app/jiraconnector';

@Component({
  selector: 'app-jira-group-selector',
  templateUrl: './jira-group-selector.component.html',
  styleUrls: ['./jira-group-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JiraGroupSelectorComponent implements OnInit {

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  selectionCtrl = new FormControl();
  filteredSelectionIds: Observable<string[]>;
  selectionIds: string[] = [];

  private _groups: string[] = [];
  @Input()
  public get groups(): string[] {
    return this._groups;
  }
  public set groups(value: string[]) {
    this._groups = value;

    this.filteredSelectionIds = this.selectionCtrl.valueChanges.pipe(
      startWith(null),
      map((selectionId: string | null) =>
      selectionId ? this._filterSelection(selectionId) : this.groups.slice()));
  }

  @ViewChild('selectionInput', {static: false}) selectionInput: ElementRef<HTMLInputElement>;
  @ViewChild('selectionAuto', {static: false}) matAutocomplete: MatAutocomplete;

  constructor(
    public _ChangeDetectorRef: ChangeDetectorRef,

    private _jiraconnector: JiraconnectorService,
  ) {

  }

  ngOnInit(): void {
  }

  addSelection(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our Selection
    if ((value || '').trim()) {
      this.selectionIds.push(value.trim());
      this.putSelection();
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.selectionCtrl.setValue(null);
  }

  removeSelection(selectionId: string): void {
    const index = this.selectionIds.indexOf(selectionId);

    if (index >= 0) {
      this.selectionIds.splice(index, 1);
      this.putSelection();
      this.selectionInput.nativeElement.value = '';
      this.selectionInput.nativeElement.blur();
      this.selectionCtrl.setValue(null);
    }
  }

  putSelection() {
    this._ChangeDetectorRef.detectChanges();
  }

  selectedSelection(event: MatAutocompleteSelectedEvent): void {

    if (this.selectionIds.indexOf(event.option.value) === -1) {
      this.selectionIds.push(event.option.value);
      this.selectionInput.nativeElement.value = '';
      this.selectionInput.nativeElement.blur();
      this.selectionCtrl.setValue(null);
    }

    this.putSelection();
  }

  private _filterSelection(selectionId: string): string[] {
    const lowerSelectionId = selectionId.toLocaleLowerCase();

    return this.groups.filter(selection =>
        selection.toLocaleLowerCase().indexOf(lowerSelectionId) !== -1);
  }
}

