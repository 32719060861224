<div *ngIf="project.parentId != null">
    <div style="float: left; height: 56px;">
        Projekt-Gruppierung: <b>{{project.parentProject.name}}</b>&nbsp;<span>
            <button mat-raised-button (click)="removeProjectFromGroup(project); $event.stopPropagation();">
                <mat-icon>remove_circle</mat-icon>
            </button>
        </span>    
    </div>

    <div style="float: left">
        <form>

            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<mat-checkbox class="width-funding-project" [(ngModel)]="project.fundingProject" [ngModelOptions]="{standalone: true}" Checked="true" (ngModelChange)="saveProject(); $event.stopPropagation()">Forschungsprojekt</mat-checkbox>

            <mat-form-field class="width-funding-number">
                <mat-label>Pauschalzuschlag</mat-label>
                <input matInput [(ngModel)]="project.flatRateSurcharge" [ngModelOptions]="{standalone: true, updateOn:'blur'}" placeholder="Pauschalzuschlag" (focusout)="saveProject(); $event.stopPropagation()" (keyup.enter)="saveProject(); $event.stopPropagation()">
            </mat-form-field>
    
            <mat-form-field class="width-funding-number">
                <mat-label>Förderkennzeichen</mat-label>
                <input matInput [(ngModel)]="project.fundingNumber" [ngModelOptions]="{standalone: true, updateOn:'blur'}" placeholder="Förderkennzeichen" (focusout)="saveProject(); $event.stopPropagation()" (keyup.enter)="saveProject(); $event.stopPropagation()">
            </mat-form-field>

            <mat-form-field class="width-funding-title">
                <mat-label>Forschungsthema</mat-label>
                <textarea matInput 
                    cdkTextareaAutosize
                    [(ngModel)]="project.fundingTitle" 
                    [ngModelOptions]="{standalone: true, updateOn:'blur'}" 
                    placeholder="Forschungsthema" 
                    (focusout)="saveProject(); $event.stopPropagation()" 
                    (keyup.enter)="saveProject(); $event.stopPropagation()">
                </textarea>
            </mat-form-field>

        </form>
    </div>

</div>

<div *ngIf="project.parentId == null">
    <form (ngSubmit)="handleProjectGroup()">
        <mat-form-field class="full-width">
            <input type="text"
                placeholder="Projekt-Gruppierung"
                aria-label="string"
                matInput
                [formControl]="myControl"
                [matAutocomplete]="auto"
                (focusout)="handleProjectGroup();" 
                (change)="$event.stopPropagation()"
                >
            <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{option}}
            </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        &nbsp;&nbsp;<mat-checkbox class="width-funding-project" [(ngModel)]="project.fundingProject" [ngModelOptions]="{standalone: true}" Checked="true" (ngModelChange)="saveProject(); $event.stopPropagation()">Forschungsprojekt</mat-checkbox>

        <mat-form-field class="width-funding-number">
            <mat-label>Pauschalzuschlag</mat-label>
            <input matInput [(ngModel)]="project.flatRateSurcharge" [ngModelOptions]="{standalone: true, updateOn:'blur'}" placeholder="Pauschalzuschlag" (focusout)="saveProject(); $event.stopPropagation()" (keyup.enter)="saveProject(); $event.stopPropagation()">
        </mat-form-field>

        <mat-form-field class="width-funding-number">
            <mat-label>Förderkennzeichen</mat-label>
            <input matInput [(ngModel)]="project.fundingNumber" [ngModelOptions]="{standalone: true, updateOn:'blur'}" placeholder="Förderkennzeichen" (focusout)="saveProject(); $event.stopPropagation()" (keyup.enter)="saveProject(); $event.stopPropagation()">
        </mat-form-field>
  
        <mat-form-field class="width-funding-title">
            <mat-label>Forschungsthema</mat-label>
            <textarea matInput 
                cdkTextareaAutosize
                [(ngModel)]="project.fundingTitle" 
                [ngModelOptions]="{standalone: true, updateOn:'blur'}" 
                placeholder="Forschungsthema" 
                (focusout)="saveProject(); $event.stopPropagation()" 
                (keyup.enter)="saveProject(); $event.stopPropagation()">
            </textarea>
        </mat-form-field>

    </form>
</div>


<br/>

<ng-template #deleteDialog>
	<h2 matDialogTitle>Gruppierung entfernen?</h2>
	<mat-dialog-content>
		<p>Soll das Projekt aus der Gruppierung</p>
		<p><b>{{project.parentProject.name}}</b></p>
		<p>entfernt werden?</p>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-button matDialogClose="cancel">Abbrechen</button>
		<button mat-button matDialogClose="confirm" color="warn">Entfernen</button>
	</mat-dialog-actions>
</ng-template>