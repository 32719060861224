<div class="table-container mat-elevation-z8">
    <table mat-table #LiquidityTable [dataSource]="liquidityService.liquidityTable" multiTemplateDataRows>


        <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
            <th mat-header-cell *matHeaderCellDef class="text-center" [class.column-year]="column.indexOf('year') != -1"
                class="column-{{columnsToDisplayInfo[column].class}}">
                <div>
                    <span *ngIf="column == 'name'"><app-open-liquidity-chart-dialog></app-open-liquidity-chart-dialog>&nbsp;&nbsp;</span>
                    {{columnsToDisplayInfo[column].title}}
                </div>
            </th>
            <td mat-cell *matCellDef="let row" class="column-{{columnsToDisplayInfo[column].class}}"
                [class.text-center]="column != 'name'" [class.column-year]="column.indexOf('year') != -1" [class.bold]="row.isBold || row.isHeader" [class.italic]="row.isItalic">

                <div *ngIf="column == 'name'">
                    <span>
                        <mat-icon class="font-size-16" *ngIf="row.isExpandable && expandedElement !== row">
                            keyboard_arrow_right</mat-icon>
                        <mat-icon class="font-size-16" *ngIf="row.isExpandable && expandedElement === row">
                            keyboard_arrow_down</mat-icon>
                        <mat-icon class="font-size-16" *ngIf="!row.isExpandable"></mat-icon>
                    </span>
                    <span>
                        &nbsp;&nbsp;{{row.orderNumber}}&nbsp;&nbsp;{{row.name}}
                    </span>

                    <span *ngIf="showSpinner">
                        <mat-icon class="font-size-16">
                            <mat-spinner color="primary" diameter="16"></mat-spinner>
                        </mat-icon>
                    </span>

                </div>

                <div *ngIf="!row.isHeader && column != 'tools' && column != 'name' && (column.indexOf('add') == -1 || (column.indexOf('add') != -1 && row.hasAddValue))">
                    <app-cell-item-renderer [valueObject]="row" [key]="columnsToDisplayInfo[column].key" [editable]="(row.hasAddValue && column.indexOf('add') != -1) || (!row.isExpandable && row.editable && columnsToDisplayInfo[column].isEditable)" (refresh)="handleBlur()"></app-cell-item-renderer>
                </div>

                <button mat-button *ngIf="!row.isHeader && column == 'tools'">
                    <mat-icon></mat-icon>
                </button>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail" sticky>
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                <div class="liquidity-detail"  *ngIf="element.isExpandable">
                    <div class="inner-liquidity-detail" *ngIf='element === expandedElement'>

                        <div *ngIf='element.hasInvoices()'>
                            <table mat-table #SubInvoicesTable [dataSource]="element.invoicesArray" multiTemplateDataRows class="sub-liquidity-list">

                                <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
                                    <td mat-cell *matCellDef="let row" class="column-{{columnsToDisplayInfo[column].class}}"
                                        [class.text-center]="column != 'name'" [class.column-year]="column.indexOf('year') != -1" [class.bold]="row.isBold" [class.v-align-top]="true">
                        
                                        <div *ngIf="column == 'name'">
                                            <span>
                                                <mat-icon class="font-size-16"></mat-icon>
                                            </span>
                                        </div>
                        
                                        <div *ngIf="column != 'tools' && column != 'name' && column.indexOf('year') == -1">
                                            <div *ngFor="let invoice of row[columnsToDisplayInfo[column].key]; index as i" style="padding-bottom: 10px;">
                                                <mat-chip class="invoice-height" [class.planed]="invoice.planed" [class.color-gray]="invoice.virtual" [class.color-green]="invoice.paid && !invoice.planed"  [class.color-yellow]="invoice.notPaidJustLikeThat" [class.color-red]="invoice.notPaidYet"
                                                matTooltip="{{invoice.documentNumberString}} // {{invoice.projectName}}: {{invoice.name}} // Rech-Datum: {{invoice.invoiceDateString}}  // Bezahl-Datum: {{invoice.clearingDateString}} // {{invoice.vatString}} // {{invoice.nettoString}} // {{invoice.bruttoString}}">
                                                    <div class="invoice-truncate">
                                                        <mat-checkbox class="invoice-checkbox" 
                                                        Checked="true" (change)="saveInvoicePaid(invoice)" (click)="$event.stopPropagation();" *ngIf="invoice.planed !== true && invoice.virtual !== true"></mat-checkbox>
                                                        {{invoice.documentNumberString}}<br>{{invoice.projectName}}:&nbsp;{{invoice.name}}<br>{{invoice.nettoString}}<br><b>{{invoice.bruttoString}}</b></div>
                                                </mat-chip>
                                            </div>
                                        </div>
                        
                                        <button mat-button *ngIf="column == 'tools'">
                                            <mat-icon></mat-icon>
                                        </button>
                                    </td>
                                </ng-container>
                                            
                                <!-- Header and Row Declarations -->
                                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row">
                                </tr>
                        
                            </table>
    
                        </div>
                        <div *ngIf='!element.hasInvoices()'>
                            <table mat-table #SubLiquidityTable [dataSource]="element.subLiqudityTableRow" multiTemplateDataRows class="sub-liquidity-list">

                                <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
                                    <td mat-cell *matCellDef="let row" class="column-{{columnsToDisplayInfo[column].class}}"
                                        [class.text-center]="column != 'name'" [class.column-year]="column.indexOf('year') != -1" [class.bold]="row.isBold">
                        
                                        <div *ngIf="column == 'name'">
                                            <span>
                                                <mat-icon class="font-size-16" *ngIf="row.isExpandable && expandedSubElement !== row">
                                                    keyboard_arrow_right</mat-icon>
                                                <mat-icon class="font-size-16" *ngIf="row.isExpandable && expandedSubElement === row">
                                                    keyboard_arrow_down</mat-icon>
                                                <mat-icon class="font-size-16" *ngIf="!row.isExpandable"></mat-icon>
                                            </span>
                                            <span>
                                                &nbsp;&nbsp;{{row.orderNumber}}&nbsp;&nbsp;<app-cell-item-renderer [valueObject]="row" [key]="columnsToDisplayInfo[column].key" [type]="CELL_TYPE.STRING"></app-cell-item-renderer>
                                            </span>

                                        </div>
                        
                                        <div *ngIf="column != 'tools' && column != 'name' && (column.indexOf('add') == -1 || (column.indexOf('add') != -1 && row.hasAddValue))">
                                            <app-cell-item-renderer [valueObject]="row" [key]="columnsToDisplayInfo[column].key" [editable]="row.editable && columnsToDisplayInfo[column].isEditable" (refresh)="handleBlur()"></app-cell-item-renderer>
                                        </div>
                        
                                        <span class="span-tools" *ngIf="column == 'tools'">
                                            <button mat-button class="button-tools" (click)="moveUp(row); $event.stopPropagation();" [disabled]="isFirst(row)">
                                                <mat-icon *ngIf="!isFirst(row)">keyboard_arrow_up</mat-icon>
                                                <mat-icon *ngIf="isFirst(row)"></mat-icon>
                                            </button>
                                            <button mat-button class="button-tools" (click)="moveDown(row); $event.stopPropagation();" [disabled]="isLast(row)">
                                                <mat-icon *ngIf="!isLast(row)">keyboard_arrow_down</mat-icon>
                                                <mat-icon *ngIf="isLast(row)"></mat-icon>
                                            </button>
                                            <button mat-button class="button-tools" (click)="deleteRow(row); $event.stopPropagation();">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </span>
                                    </td>
                                </ng-container>
                                          
                                <ng-container matColumnDef="expandedSubDetail" sticky>
                                    
                                    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                                        <div class="liquidity-detail"  *ngIf="element.isExpandable">
                                            <div class="inner-liquidity-detail" *ngIf='element === expandedSubElement'>

                                                <div *ngIf='element.hasInvoices()'>
                                                    <table mat-table #SubSubInvoicesTable [dataSource]="element.invoicesArray" multiTemplateDataRows class="sub-liquidity-list">
                        
                                                        <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
                                                            <td mat-cell *matCellDef="let row" class="column-{{columnsToDisplayInfo[column].class}}"
                                                                [class.text-center]="column != 'name'" [class.column-year]="column.indexOf('year') != -1" [class.bold]="row.isBold" [class.v-align-top]="true">
                                                
                                                                <div *ngIf="column == 'name'">
                                                                    <span>
                                                                        <mat-icon class="font-size-16"></mat-icon>
                                                                    </span>
                                                                </div>
                                                
                                                                <div *ngIf="column != 'tools' && column != 'name' && column.indexOf('year') == -1">
                                                                    <div *ngFor="let invoice of row[columnsToDisplayInfo[column].key]; index as i" style="padding-bottom: 10px;">
                                                                        <mat-chip class="invoice-height" [class.planed]="invoice.planed" [class.color-gray]="invoice.virtual" [class.color-green]="invoice.paid"  [class.color-yellow]="invoice.notPaidJustLikeThat" [class.color-red]="invoice.notPaidYet"
                                                                        matTooltip="{{invoice.documentNumberString}} // {{invoice.projectName}}: {{invoice.name}} // Bezahl-Datum: {{invoice.clearingDateString}} // {{invoice.vatString}} // {{invoice.nettoString}} // {{invoice.bruttoString}}">
                                                                            <div class="invoice-truncate">
                                                                                <mat-checkbox class="invoice-checkbox" [(ngModel)]="invoice.paid" [ngModelOptions]="{standalone: true}" 
                                                                                Checked="true" (ngModelChange)="saveInvoicePaid(invoice)" (click)="$event.stopPropagation();" *ngIf="invoice.planed !== true && invoice.virtual !== true"></mat-checkbox>
                                                                                {{invoice.documentNumberString}}<br>{{invoice.projectName}}:&nbsp;{{invoice.name}}<br>{{invoice.nettoString}}<br><b>{{invoice.bruttoString}}</b></div>
                                                                        </mat-chip>
                                                                    </div>
                                                                </div>
                                                
                                                                <button mat-button *ngIf="column == 'tools'">
                                                                    <mat-icon></mat-icon>
                                                                </button>
                                                            </td>
                                                        </ng-container>
                                                                    
                                                        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row">
                                                        </tr>
                                                
                                                    </table>
                            
                                                </div>
                        
                                            </div>
                                        </div>
                                    </td>
                                    
                                </ng-container>

                                <!-- Header and Row Declarations -->
                                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
                                    [class.example-expanded-row]="expandedSubElement === element"
                                    [class.header-row]="element.isHeader"
                                    (click)="expandedSubElement = expandedSubElement === element ? null : element; this._ChangeDetectorRef.detectChanges();">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: ['expandedSubDetail']" class="example-detail-row"></tr>
                        
                            </table>
                        
                            <button mat-raised-button (click)="addRow(element)">+</button>
                        </div>
                    </div>
                </div>
            </td>
        </ng-container>

        <!-- Header and Row Declarations -->
        <div>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true" ></tr>
        </div>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
            [class.example-expanded-row]="expandedElement === element"
            [class.header-row]="element.isHeader"
            (click)="expandRow(element); this._ChangeDetectorRef.detectChanges();">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

    </table>
</div>

<ng-template #deleteDialog>
    <h2 matDialogTitle>Löschen?</h2>
    <mat-dialog-content>
    <p>Soll dieser Eintrag</p> 
    <p><b>{{selectedSubLiquidityTableRow.orderNumber}}&nbsp;&nbsp;{{selectedSubLiquidityTableRow.name}}</b></p> 
    <p>wirklich aus der Liste gelöscht werden?</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
    <button mat-button matDialogClose="cancel">Abbrechen</button>
    <button mat-button matDialogClose="confirm" color="warn">Löschen</button>
    </mat-dialog-actions>
  </ng-template>

  <ng-template #actClearingDateDialog>
    <h2 matDialogTitle>Bezahldatum setzen?</h2>
    <mat-dialog-content>
    <p>Soll der Status auf</p> 
    
    <mat-checkbox class="invoice-checkbox"  [(ngModel)]="selectedInvoice.paid" [ngModelOptions]="{standalone: true}" Checked="true" (click)="$event.stopPropagation();"></mat-checkbox>
    <p>und das Bezahldatum auf</p> 

    <mat-form-field class="width-start-date">
        <mat-label>Bez-Datum</mat-label>
        <input matInput [matDatepicker]="pickerClearingDate" placeholder="Bez-Datum" [(ngModel)]="selectedInvoice.clearingDate" [ngModelOptions]="{standalone: true, updateOn:'blur'}">
        <mat-datepicker-toggle matSuffix [for] = "pickerClearingDate"></mat-datepicker-toggle>
        <mat-datepicker [startAt]="selectedInvoice.clearingDate" #pickerClearingDate></mat-datepicker>
    </mat-form-field>

    <p>gesetzt werden?</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
    <button mat-button matDialogClose="cancel">Nein</button>
    <button mat-button matDialogClose="confirm" color="warn">Ja</button>
    </mat-dialog-actions>
  </ng-template>  