import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { SECONDS_PER_HOUR, HOURS_PER_WORKING_DAY } from 'src/app/model/constants';
import { CELL_TYPE, UNIT, VIEW_STATE } from 'src/app/model/enums';
import { formatCurrency, formatNumber } from '@angular/common';
import { LiquidityService } from 'src/app/services/liquidity.service';

@Component({
  selector: 'app-cell-item-renderer',
  templateUrl: './cell-item-renderer.component.html',
  styleUrls: ['./cell-item-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CellItemRendererComponent implements OnInit {

  @ViewChild('valueInput') valueInputElement: ElementRef;

  @Input() public type: CELL_TYPE = CELL_TYPE.EURO_VALUE;
  @Input() public editable = true;
  @Input() public valueObject: any;
  @Input() public key: any;
  @Input() public projectId: any;
  @Input() public month: any;
  @Input() public threshold1: number;
  @Input() public threshold2: number;
  @Input() public viewUnit: UNIT = UNIT.HOURS;

  @Output() refresh = new EventEmitter<any>();

  @Input() public showSpinner = false;
  private _updated = false;
  @Input() public get updated() {
    return this._updated;
  }
  public set updated(value: boolean) {
    this._updated = value;
    if (this._updated === true) {
      this._ChangeDetectorRef.detectChanges();
    }
  }

  public bClickNext = false;

  public state: VIEW_STATE = VIEW_STATE.SHOW;

  VIEW_STATE = VIEW_STATE;
  CELL_TYPE = CELL_TYPE;
  UNIT = UNIT;

  constructor(
    private _ChangeDetectorRef: ChangeDetectorRef,
    public liquidityService: LiquidityService
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this._ChangeDetectorRef.detectChanges();
    }, 50);
  }

  get value(): any {
    if (this.type === CELL_TYPE.WORKLOAD_NUMBER) {
      let value = this.valueObject.getWorkload(this.key);
      if (value === undefined || value === null) {
        value = 0;
      }

      switch (this.viewUnit) {
        case UNIT.DAYS:
          value = value / 8;
          break;

        default:
          break;
      }

      return value;

    } else if (this.type === CELL_TYPE.TIMESHEET_NUMBER) {
      let value = this.valueObject.getValue(this.key);
      if (value === undefined || value === null) {
        value = 0;
      }

      switch (this.viewUnit) {
        case UNIT.DAYS:
          value = value / 8;
          break;

        default:
          break;
      }

      return value;

    } else if (this.type === CELL_TYPE.PLANNED_NUMBER) {
      const monthPlan = this.valueObject.getPlan(this.key, this.projectId, this.month);
      let value = monthPlan.getPlannedConverted(this.viewUnit);
      if (value === undefined || value === null) {
        value = 0;
      }
      return value;
    } else if (this.type === CELL_TYPE.TIMESHEET_GROSS_SALARY || this.type === CELL_TYPE.TIMESHEET_TOTAL_WORKING_DAYS) {

      const value = this.valueObject[this.key];
      return value;

    } else {

      let value = this.valueObject.getValue(this.key);
      if (value === undefined || value === null) {
        value = this.valueObject[this.key];
      }
      return value;
    }
  }

  get valueString(): string {
    try {
      if ((this.type === CELL_TYPE.TIMESHEET_GROSS_SALARY || this.type === CELL_TYPE.TIMESHEET_TOTAL_WORKING_DAYS) && typeof this.value === 'number') {
        return (this.value === undefined || this.value === null
                                         || isNaN(this.value)
                                         || (this.value <= 0.005 && this.value >= -0.005)) ? '0' : formatNumber(
          this.value,
          'de',
          '1.0-2',
        );
      }
      if (this.type === CELL_TYPE.TIMESHEET_NUMBER && typeof this.value === 'number') {
        return (this.value === undefined || this.value === null
                                         || isNaN(this.value)
                                         || (this.value <= 0.005 && this.value >= -0.005)) ? '0' : formatNumber(
          this.value,
          'de',
          '1.0-2',
        );
      }
      if (this.type === CELL_TYPE.WORKLOAD_NUMBER && typeof this.value === 'number') {
        return (this.value === undefined || this.value === null
                                         || isNaN(this.value)
                                         || (this.value <= 0.005 && this.value >= -0.005)) ? '-' : formatNumber(
          this.value,
          'de',
          '1.0-2',
        );
      }
      if (this.type === CELL_TYPE.PLANNED_NUMBER && typeof this.value === 'number') {
        return (this.value === undefined || this.value === null
                                         || isNaN(this.value)
                                         || (this.value <= 0.005 && this.value >= -0.005)) ? '-' : formatNumber(
          this.value,
          'de',
          '1.0-2',
        );
      }
      if (this.type === CELL_TYPE.EURO_VALUE && typeof this.value === 'number') {

        return (this.value === undefined || this.value === null
                                         || isNaN(this.value)
                                         || (this.value <= 0.005 && this.value >= -0.005)) ? '' : formatCurrency(
          this.value,
          'de',
          '€',
          'EUR',
          '0.2',
        );
      }
      if (this.type === CELL_TYPE.STRING) {
        console.log(JSON.stringify(this.valueObject) + ':: ' + this.key + ' -> ' + this.value);
      }
      return this.value;
    } catch (error) {
      return '-';
    }
  }

  set valueString(value: string) {
    //
  }

  get isRed(): boolean {
    if (this.type === CELL_TYPE.WORKLOAD_NUMBER) {
      if (this.valueObject.getWorkload(this.key) * SECONDS_PER_HOUR < (1 * this.threshold1)) {
        return true;
      }
    }
    return false;
  }
  get isYellow(): boolean {
    if (this.type === CELL_TYPE.WORKLOAD_NUMBER) {
      if (this.valueObject.getWorkload(this.key) * SECONDS_PER_HOUR > (1 * this.threshold1)) {
        return true;
      }
    }
    return false;
  }
  get isGreen(): boolean {
    return false;
  }

  handleValueClick() {
    if (this.editable) {
      setTimeout(() => {
        this.state = VIEW_STATE.EDIT;
        this._ChangeDetectorRef.detectChanges();
      }, 100);
      setTimeout(() => {
        this.valueInputElement.nativeElement.focus();
        this.valueInputElement.nativeElement.select();
        this._ChangeDetectorRef.detectChanges();
      }, 200);
    }
  }

  handleBlur(value: any, bClickNext: boolean = false) {
    this.bClickNext = bClickNext;

    this.state = VIEW_STATE.SHOW;

    this._ChangeDetectorRef.detectChanges();

    if (this.type === CELL_TYPE.WORKLOAD_NUMBER) {
      try {
        value = (value as string).replace('.', '');
        value = (value as string).replace(',', '.');

        // Number.parseFloat(value as string);
        console.log('VALUE: ' + value);
        console.log('VALUE-NUMBER: ' + Number.parseFloat(value as string));
        //        this.valueObject.setWorkload(this.key, 1 * (value as number));

        if (isNaN(1 * (value as number))) {
          value = this.convertDurationToSeconds(value);
        } else {
          switch (this.viewUnit) {
            case UNIT.DAYS:
              value = (value as number) * HOURS_PER_WORKING_DAY;
              break;

            default:
              break;
          }
        }

        this.valueObject.setManuallyPlanned(this.key, 1 * (value as number) * SECONDS_PER_HOUR);
      } catch (error) {
        console.log(error);
      }
    } else     if (this.type === CELL_TYPE.TIMESHEET_NUMBER) {
      try {
        value = (value as string).replace('.', '');
        value = (value as string).replace(',', '.');

        // Number.parseFloat(value as string);
        console.log('VALUE: ' + value);
        console.log('VALUE-NUMBER: ' + Number.parseFloat(value as string));
        //        this.valueObject.setWorkload(this.key, 1 * (value as number));

        if (value === undefined || value === null || value === '' || (value as string).trim() === '' ) {
          this.valueObject.setManuallyData(this.key, null);
        } else if (isNaN(1 * (value as number))) {
          value = this.convertDurationToSeconds(value);
        } else {
          switch (this.viewUnit) {
            case UNIT.DAYS:
              value = (value as number) * HOURS_PER_WORKING_DAY;
              break;

            default:
              break;
          }
        }

        if (value === undefined || value === null || value === '' || (value as string).trim() === '' ) {
          this.valueObject.setManuallyData(this.key, null);
        } else {
          this.valueObject.setManuallyData(this.key, 1 * (value as number) * SECONDS_PER_HOUR);
        }
      } catch (error) {
        console.log(error);
      }

    } else if (this.type === CELL_TYPE.PLANNED_NUMBER) {
        try {
          value = (value as string).replace('.', '');
          value = (value as string).replace(',', '.');

          // Number.parseFloat(value as string);
          console.log('VALUE: ' + value);
          console.log('VALUE-NUMBER: ' + Number.parseFloat(value as string));

          if (isNaN(1 * (value as number))) {
            value = this.convertDurationToSeconds(value);
          } else {
            switch (this.viewUnit) {
              case UNIT.DAYS:
                value = (value as number) * HOURS_PER_WORKING_DAY;
                break;

              default:
                break;
            }
          }

          this.valueObject.setPlan(this.key, this.projectId, this.month, 1 * (value as number) * SECONDS_PER_HOUR);

          this.valueString = '';

          this._ChangeDetectorRef.detectChanges();

        } catch (error) {
          console.log(error);
        }

    } else if (this.type === CELL_TYPE.TIMESHEET_GROSS_SALARY || this.type === CELL_TYPE.TIMESHEET_TOTAL_WORKING_DAYS) {

      try {
        value = (value as string).replace('.', '');
        value = (value as string).replace(',', '.');

        // Number.parseFloat(value as string);
        console.log('VALUE: ' + value);
        console.log('VALUE-NUMBER: ' + Number.parseFloat(value as string));
        this.valueObject[this.key] = 1 * (value as number);
      } catch (error) {
        console.log(error);
      }

    } else {

      try {
        if (this.type === CELL_TYPE.EURO_VALUE) {
          value = (value as string).replace('.', '');
          value = (value as string).replace(',', '.');

          // Number.parseFloat(value as string);
          console.log('VALUE: ' + value);
          console.log('VALUE-NUMBER: ' + Number.parseFloat(value as string));
          this.valueObject.setValue(this.key, 1 * (value as number));
        } else if (this.type === CELL_TYPE.STRING) {
          this.valueObject[this.key] = value;
        }
      } catch (error) {
        console.log(error);
      }
      this.liquidityService.refreshSums();

    }

    this.refresh.emit(this);

  }

  convertDurationToSeconds(sValue: string): number {
    let seconds = 0;
    let current = '';

    function isNumeric(s) {
      return !isNaN(s - parseFloat(s));
    }

    for (const c of sValue) {

        if (isNumeric(c)){
            current = current + c;
        } else if (c === '.' || c === ',') {
            current = current + '.';
        } else if (!c.trim().length) {
            continue;
        } else {
            let multiplier;

            switch (c) {
                case 's': multiplier = 1; break;      // seconds
                case 'm': multiplier = 60; break;     // minutes
                case 'h': multiplier = 3600; break;   // hours
                case 'd': multiplier = SECONDS_PER_HOUR * 8; break;  // days
                case 'w': multiplier = SECONDS_PER_HOUR * 8 * 5; break; // weeks
                default:

            }

            try {
              seconds += parseFloat(current) * multiplier;
            } catch (error) {}
            current = '';
        }
    }

    if (current !== '') {
      return 0;
    }

    return seconds / SECONDS_PER_HOUR;
  }

  handleKeyPress(event: any) {
    const charCode = !event.charCode ? event.which : event.charCode;
    if ([0, 8].indexOf(charCode) !== -1) {
      return;
    }
  }

}
