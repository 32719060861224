<div [class.dev-background]="!environment.production" class="add-project">
	<button mat-raised-button *ngIf="environment.production !== true" (click)="copyProdToDev()">Daten von Prod kopieren
		...</button>
</div>
<div class="connected-user">
	<span>{{this._jiraconnector.tempoOauthToken}}</span>
	<span>Zusammenarbeit:&nbsp;&nbsp;</span>
	<span *ngFor="let user of socketService.connectedUsers">
		<img src="{{user.avatarUrl}}" matTooltip="{{user.displayName}}">
	</span>
</div>

<div [class.dev-background]="!environment.production">
	<mat-tab-group mat-align-tabs="end" (selectedTabChange)="onSelectedTabChange($event)" [(selectedIndex)]="tabIndex">

		<mat-tab label="Socket">
			<div *ngFor="let message of messages">
				{{message}}
			</div>

			<input [(ngModel)]="message" (keyup)="$event.keyCode == 13 && sendMessage()" />
			<button (click)="sendMessage()">Send</button>

		</mat-tab>

		<mat-tab>
			<ng-template mat-tab-label>
			
				<mat-label>Monate {{selectedDateMonthViewLabel}}</mat-label>
				<mat-select #selectDateMonth [(ngModel)]="selectedDateMonthView" name="dateMonth" (selectionChange)="changeMonthDate($event)">
					<mat-option *ngFor="let dateMonth of dateMonths" [value]="dateMonth.value">
						{{dateMonth.viewValue}}
					</mat-option>
				</mat-select>
			
			</ng-template>

			<app-project-list [projectList]="this.projectService.projectSubject"
				[selectedDateMonthView]="selectedDateMonthView"
				[reportMode]="REPORT_MODE.MONTH_FULL_YEAR"></app-project-list>

		</mat-tab>
		<!--
		<mat-tab label="Quartale 2020">

			<app-project-list [projectList]="this.projectService.projectSubject" [reportMode]="REPORT_MODE.QUARTER_FULL_YEAR"></app-project-list>

		</mat-tab>
		-->
		<mat-tab id="liquidityTable">

			<ng-template mat-tab-label>
			
				<mat-label>Liquiditätstabelle {{selectedDateLiquidityViewLabel}}</mat-label>
				<mat-select #selectDateLiquidity [(ngModel)]="selectedDateLiquidityView" name="dateLiquidity" (selectionChange)="changeLiquidityDate($event)">
					<mat-option *ngFor="let dateLiquidity of datesLiquidity" [value]="dateLiquidity.value">
						{{dateLiquidity.viewValue}}
					</mat-option>
				</mat-select>
			
			</ng-template>

			<!--<ng-template matTabContent>-->
			<app-liquidity-table #LiquidityTable 
				[projectList]="this.projectService.projectSubject"
				[selectedDateLiquidityView]="selectedDateLiquidityView"
				>
			</app-liquidity-table>
			<!--</ng-template>-->
		</mat-tab>

		<mat-tab label="Offene Posten" id="allInvoicesList">
			<!--<ng-template matTabContent>-->
			<app-all-invoices-list #AllInvoicesList></app-all-invoices-list>
			<!--</ng-template>-->
		</mat-tab>

		<mat-tab label="Ressourcentabelle" id="ressourcesTable">
			<!--<ng-template matTabContent>-->
			<app-ressources-table #RessourcesTable [projectList]="this.projectService.projectSubject">
			</app-ressources-table>
			<!--</ng-template>-->
		</mat-tab>

		<mat-tab label="Stundennachweise" id="timesheetsTable">
			<!--<ng-template matTabContent>-->
			<app-timesheets-table #TimesheetsTable>
			</app-timesheets-table>
			<!--</ng-template>-->
		</mat-tab>

		<mat-tab label="Settings" id="settings" *ngIf="socketService.sessionUser && socketService.sessionUser.hasGroup('p-cockpit-ceos')">
			<!--<ng-template matTabContent>-->
			<app-settings #Settings>
			</app-settings>
			<!--</ng-template>-->
		</mat-tab>

	</mat-tab-group>
</div>

<br/>

V{{environment.appVersion}}
<mat-chip-list></mat-chip-list>

<router-outlet></router-outlet>