<div class="table-container mat-elevation-z8">
    <table mat-table #ProjectTable [dataSource]="projectList" multiTemplateDataRows [class.sub-project-list]="isSubProjectList !== false">


        <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
            <th mat-header-cell *matHeaderCellDef [class.text-center]="column != 'name'" [class.column-year]="column.indexOf('year') != -1"
                class="column-{{columnsToDisplayInfo[column].class}}">
                <div *ngIf='isSubProjectList === false'>
                    <div>{{columnsToDisplayInfo[column].title}}</div>
                    <app-select-project-dialog #selectProjectDialog  *ngIf="column == 'name'"></app-select-project-dialog>
                    <mat-chip
                        [class.chip-color-green]="getOverAllValue(columnsToDisplayInfo[column].key) > 200000"
                        *ngIf="column != 'name' && column != 'tools'">
                        <span>{{getOverAllValue(columnsToDisplayInfo[column].key) | currency:'EUR':'€':'0.2':'de'}}</span>
                    </mat-chip>
                </div>
            </th>
            <td mat-cell *matCellDef="let row" class="column-{{columnsToDisplayInfo[column].class}}"
                [class.text-center]="column != 'name'" [class.column-year]="column.indexOf('year') != -1">

                <div *ngIf="column == 'name'">
                    <span>
                        <mat-icon class="font-size-16" *ngIf="expandedElement !== row">
                            keyboard_arrow_right</mat-icon>
                        <mat-icon class="font-size-16" *ngIf="expandedElement === row">
                            keyboard_arrow_down</mat-icon>
                    </span>

                    <span class='aui-avatar aui-avatar-xsmall' *ngIf='row.avatar != ""'>
                        <span class='aui-avatar-inner'>
                            <img src='{{row.avatar}}' alt='Project Avatar'>
                        </span>
                    </span>
                    <span>
                        &nbsp;&nbsp;{{row.name}}<span *ngIf='row.type != PROJECT_TYPE.GROUP'>&nbsp;({{row.id}})</span>&nbsp;<b>({{row.sum_invoices_nettoString}})</b>&nbsp;
                    </span>

                    <span *ngIf="showSpinner && expandedElement == row">
                        <mat-icon class="font-size-16">
                            <mat-spinner color="primary" diameter="16"></mat-spinner>
                        </mat-icon>
                    </span>

                </div>

                <div *ngIf="column != 'tools' && column != 'name'" [class.bold]="column.indexOf('year') != -1">
                    <app-cell-item-renderer [valueObject]="row" [key]="columnsToDisplayInfo[column].key" [editable]="false"></app-cell-item-renderer>
                    <!--
                    <mat-chip
                        [class.chip-color-green]="row.getValue(columnsToDisplayInfo[column].key) > 200000">
                        <span  *ngIf="row.getValue(columnsToDisplayInfo[column].key) !== 0">{{row.getValue(columnsToDisplayInfo[column].key) | currency:'EUR':'€':'0.2':'de'}}</span>
                    </mat-chip>
                    -->
                </div>

                <button mat-button (click)="removeProjectFromList(row); $event.stopPropagation();"
                    *ngIf="column == 'tools'">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail" sticky>
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length" [class.project-group]="element === expandedElement && element.type == PROJECT_TYPE.GROUP">
                <div class="project-detail" >
                    <app-sub-budget-list [oProjectTable]="oProjectTable" [project]="element" [columnsToDisplay]="columnsToDisplay"
                        [columnsToDisplayInfo]="columnsToDisplayInfo" *ngIf="element === expandedElement && element.type != PROJECT_TYPE.GROUP"></app-sub-budget-list>

                    <app-project-list [isSubProjectList]="true" [projectList]="element.subProjects"
                        [selectedDateMonthView]="selectedDateMonthView"
                        [reportMode]="reportMode" *ngIf="element === expandedElement && element.type == PROJECT_TYPE.GROUP"></app-project-list>

                </div>
            </td>
        </ng-container>

        <!-- Header and Row Declarations -->
        <div  *ngIf='isSubProjectList === false'>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true" ></tr>
        </div>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
            [class.example-expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element; getSelectedProjectData(expandedElement);  this._ChangeDetectorRef.detectChanges();">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

    </table>
</div>

<ng-template #deleteDialog>
	<h2 matDialogTitle>Löschen?</h2>
	<mat-dialog-content>
		<p>Soll das Projekt</p>
		<p><b>{{selectedProject.name}}&nbsp;({{selectedProject.id}})</b></p>
		<p>wirklich aus der Liste gelöscht werden?</p>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-button matDialogClose="cancel">Abbrechen</button>
		<button mat-button matDialogClose="confirm" color="warn">Löschen</button>
	</mat-dialog-actions>
</ng-template>
