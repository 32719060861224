import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DataDescription } from '../model/DataDescription';
import { SubBudget } from '../model/SubBudget';
import { PROJECT_TYPE, DATA_TYPE, CLASS_TYPE } from '../model/enums';
import { Project } from '../model/Project';
import { ProjectShort } from '../model/ProjectShort';
import { Invoice } from '../model/Invoice';
import { JiraconnectorService } from '../jiraconnector';
import { SocketService } from './socket.service';
import { JiraIssue } from '../model/JiraIssue';
import { JiraComponent } from '../model/JiraComponent';
import { JiraVersion } from '../model/JiraVersion';
import { VirtualGroup } from '../model/VirtualGroup';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  public projects: Project[] = [];
  public projectsById = {};
  public projectSubject = new Subject<Project[]>();
  public projectIds: string[] = [];
  public projectGroups: Project[] = [];

  // public get fundingProjects(): Project[] {
  //   return this.projects.filter((item) => item.fundingProject === true);
  // }

  private _fundingProjects: Project[];
  public get fundingProjects(): Project[] {
    try {

      if (this._fundingProjects === undefined || this._fundingProjects === null) {

        const ar_Project = this.projects.filter((project) => {
          return project.fundingProject === true;
        });

        for (const project of this.projects) {
          const ar_SubProject = project.subProjects.filter((project) => {
            return project.fundingProject === true;
          });
          ar_Project.push(...ar_SubProject);
        }

        ar_Project.sort((a, b) => {
          try {
              if (!a.name.startsWith('Summe') && b.name.startsWith('Summe')) { return -1; }
              if (a.name.startsWith('Summe') && !b.name.startsWith('Summe')) { return 1; }
              if (!a.name.startsWith('[') && b.name.startsWith('[')) { return -1; }
              if (a.name.startsWith('[') && !b.name.startsWith('[')) { return 1; }
              return a.name.localeCompare(b.name, undefined, {sensitivity: 'base'});
          } catch (error) {
              return -1;
          }
        });

        this._fundingProjects = ar_Project;
      }

    } catch (error) {}

    return this._fundingProjects;
  }

  public get fundingProjectIds(): string[] {
    const ar_sFundingProjectId: string[] = [];
    for (const fundingProject of this.fundingProjects) {
        ar_sFundingProjectId.push(fundingProject.id);
    }

    return ar_sFundingProjectId;
  }

  constructor(
    private _jiraconnector: JiraconnectorService,
    private socketService: SocketService
  ) {}

  get invoices(): Invoice[] {
    const ar_oInvoices: Invoice[] = [];

    for (const project of this.projects) {
      try {
        ar_oInvoices.push(...project.get_invoices('ALL', false));
      } catch (error) { }
    }

    return ar_oInvoices;
  }
  set invoices(invoices: Invoice[]) {
    //
  }

  public getProjectsFromDataStore(): void {
    const oResult = this._jiraconnector.getCockpitProjectList();
    oResult.subscribe((json: any) => {
      const results = [];
      for (const item of json.value) {

        // console.debug(item);

        let oProject: Project = new Project();
        Object.assign(oProject, { name: item.name,
                                  id: item.id,
                                  parentId: item.parentId,
                                  preview_values: item.preview_values,
                                  manually_planned: item.manually_planned,
                                  avatar: item.avatar,
                                  fundingProject: item.fundingProject,
                                  fundingNumber: item.fundingNumber,
                                  fundingTitle: item.fundingTitle,
                                  subBudgets: [],
                                  subProjects: [] });

        // oProject.name = 'XXX';
        // oProject.id = '' + item.id;

        const oExistingProject: Project = this.projectsById[item.id];

        if (oExistingProject !== undefined && oExistingProject.type === PROJECT_TYPE.FULL) {
          Object.assign(oProject, { name: item.name,
            id: oProject.id,
            parentId: oProject.parentId,
            preview_values: oProject.preview_values,
            manually_planned: oProject.manually_planned,
            avatar: oProject.avatar,
            fundingProject: oProject.fundingProject,
            fundingNumber: oProject.fundingNumber,
            fundingTitle: oProject.fundingTitle,
          });

          oProject = oExistingProject;
        }

        if (item.virtualGroups !== undefined) {
          const ar_virtualGroups: VirtualGroup[] = [];
          for (const oItem of item.virtualGroups) {
            const oVirtualGroup = VirtualGroup.create(oItem);
            oVirtualGroup.parentProject = oProject;

            ar_virtualGroups.push(oVirtualGroup);

          }
          oProject.virtualGroups = ar_virtualGroups;
        }

        results.push(oProject);

        this.projectsById[item.id] = oProject;

        if (this.projectIds.indexOf(item.id) === -1) {
          this.projectIds.push(item.id);
        }

      }
/*
      for (const actProject of results) {
        const index = this.projects.findIndex((item) => item.id === actProject.id && item.type === PROJECT_TYPE.SHORT);
        if (index === -1) {
          this.projects.push(actProject);
        }
      }
      this.projects = this.projects.sort((a, b) => {
        if (!a.name.startsWith('[') && b.name.startsWith('[')) { return -1; }
        if (a.name.startsWith('[') && !b.name.startsWith('[')) { return 1; }
        return a.name.localeCompare(b.name, undefined, {sensitivity: 'base'});
      });
*/

      this.projects.length = 0;
      this.projects.push(...results.sort((a, b) => {
        if (!a.name.startsWith('[') && b.name.startsWith('[')) { return -1; }
        if (a.name.startsWith('[') && !b.name.startsWith('[')) { return 1; }
        return a.name.localeCompare(b.name, undefined, {sensitivity: 'base'});
      }));

      for (const item of this.projects) {
        if (item.parentId != null) {
          item.parentProject = this.projectsById[item.parentId];

          if (item.parentProject != null) {
            item.parentProject.setType(PROJECT_TYPE.GROUP);
            item.parentProject.subProjects.push(item);
          }
        }
      }

      this.projectGroups.length = 0;
      this.projectGroups = this.projects.filter((project: Project) => project.subProjects.length > 0);

      this.projects = this.projects.filter((project: Project) => project.parentId === null);

      for (const project of this.projects) {
        for (const subProject of project.subProjects) {
          this.projectsById[subProject.id] = subProject;
        }
        this.projectsById[project.id] = project;
      }

      this._fundingProjects = undefined;
      this.projectSubject.next(this.projects);

      this.invoices = [];

      // this.subscribeProjectsObserver();
    });

  }

  public subscribeProjectsObserver(): void {
    this.projectSubject.subscribe(() => {
      this.saveProjectsToDataStore();
    });
  }

  public addProjectsToList(result: any): void {
    // console.debug('addProjectsToList: ' + JSON.stringify(result));

    const ar_project: Project[] = [];
    for (const item of result) {
      if (this.projectIds.indexOf(item.id) === -1) {
        ar_project.push(item);
        this.projectIds.push(item.id);
      }
    }

    this.projects.push(...ar_project);
    this.projects.sort((a, b) => {
      if (!a.name.startsWith('[') && b.name.startsWith('[')) { return -1; }
      if (a.name.startsWith('[') && !b.name.startsWith('[')) { return 1; }
      return a.name.localeCompare(b.name, undefined, {sensitivity: 'base'});
    }
    );

    this._fundingProjects = undefined;
    this.projectSubject.next(this.projects);

  }

  public removeProjectFromList(project: Project): void {
    // console.debug('Delete: ' + project);
    this.projects.splice(this.projects.findIndex(item => item.id === project.id), 1);

    this._fundingProjects = undefined;
    this.projectSubject.next(this.projects);
  }

  public saveProjectsToDataStore() {
    // console.debug(this.projects);

    const projectsShort: ProjectShort[] = [];
    for (const item of this.projects) {
      try {
        const sum_netto = item.sum_invoices_netto;
        if (item.type !== PROJECT_TYPE.GROUP) {
          item.setType(PROJECT_TYPE.SHORT);
        }
        // console.debug('sum_netto: ' + sum_netto);
      } catch (error) {
        // do nothing
      }
      projectsShort.push(new ProjectShort(item));

      for (const subItem of item.subProjects) {
        try {
          const sum_netto = subItem.sum_invoices_netto;
          subItem.setType(PROJECT_TYPE.SHORT);
          // console.debug('sum_netto: ' + sum_netto);
        } catch (error) {
          // do nothing
        }
        projectsShort.push(new ProjectShort(subItem));
      }

    }
    // console.debug('projectsShort: ' + JSON.stringify(projectsShort));

    const oResult = this._jiraconnector.putCockpitProjectList(projectsShort);
    oResult.subscribe((res) => {
      // console.debug('cockpit-projekt-list: ' + res);

      const dataDescription: DataDescription = new DataDescription();
      dataDescription.type = DATA_TYPE.PROJECT_LIST;
      this.socketService.dataChange(dataDescription);

    });
  }

  public saveProject(project: Project, callback?: () => void) {
    if (project.type === PROJECT_TYPE.FULL) {

      const oResult = this._jiraconnector.putProjectProperty(project.id, 'p-cockpit-project-data', project);
      oResult.subscribe((res: any) => {
        console.log('cockpit-projekt-list: ' + res);

        this.saveProjectsToDataStore();

        if (callback) { callback(); }
      });

    } else {
      this.saveProjectsToDataStore();

      if (callback) { callback(); }
    }

  }

  public setProjectGroupForProject(groupNameOrProject: string | Project, project: Project) {
    let oProjectGroup: Project = null;
    if (typeof groupNameOrProject === 'string') {
      oProjectGroup = this.projectGroups.find(obj => {
        return obj.name === groupNameOrProject;
      });
      if (oProjectGroup === undefined || oProjectGroup === null) {
        oProjectGroup = new Project();
        oProjectGroup.id = 'PG--' + (this.projectGroups.length + 1);
        oProjectGroup.name = groupNameOrProject;
        oProjectGroup.setType(PROJECT_TYPE.GROUP);

        this.projects.push(oProjectGroup);
      }

    } else {
      oProjectGroup = groupNameOrProject;
    }

    if (oProjectGroup !== null) {
      project.parentId = oProjectGroup.id;
      project.parentProject = oProjectGroup;
      oProjectGroup.subProjects.push(project);

      this.projectGroups.length = 0;
      this.projectGroups = this.projects.filter((project: Project) => project.subProjects.length > 0);

      this.projects = this.projects.filter((project: Project) => project.parentId === null);

      // this.projects.sort((a, b) => a.name.localeCompare(b.name, undefined, {sensitivity: 'base'}));
      this.projects.sort((a, b) => {
        if (!a.name.startsWith('[') && b.name.startsWith('[')) { return -1; }
        if (a.name.startsWith('[') && !b.name.startsWith('[')) { return 1; }
        return a.name.localeCompare(b.name, undefined, {sensitivity: 'base'});
      });

      this._fundingProjects = undefined;
      this.projectSubject.next(this.projects);
    }

  }

  public removeProjectFromGroup(project: Project): void {
    // console.debug('Remove: ' + project);

    const parentProject = project.parentProject;
    parentProject.subProjects.splice(parentProject.subProjects.findIndex(item => item.id === project.id), 1);

    project.parentId = null;
    project.parentProject = null;

    if (parentProject.subProjects.length === 0) {
      this.projectGroups.splice(this.projectGroups.findIndex(item => item.id === parentProject.id), 1);
      this.projects.splice(this.projects.findIndex(item => item.id === parentProject.id), 1);
      this.projectIds.splice(this.projectIds.findIndex(item => item === parentProject.id), 1);
    }

    if (this.projectIds.indexOf(project.id) === -1) {
      this.projectIds.push(project.id);
    }

    this.projects.push(project);

    // this.projects.sort((a, b) => a.name.localeCompare(b.name, undefined, {sensitivity: 'base'}));
    this.projects.sort((a, b) => {
      if (!a.name.startsWith('[') && b.name.startsWith('[')) { return -1; }
      if (a.name.startsWith('[') && !b.name.startsWith('[')) { return 1; }
      return a.name.localeCompare(b.name, undefined, {sensitivity: 'base'});
    });

    this._fundingProjects = undefined;
    this.projectSubject.next(this.projects);
  }

  public getFullProjectData(project: Project): Observable<any>[] {
    const ar_oObservable: Observable<any>[] = [];

    project = this.projectsById[project.id];

    console.log('GET PROJECT-DATA: ' + project.name);

    if (project != null) {
      for (const subProject of project.subProjects) {
        const ar_projectData = this.getFullProjectData(subProject);
        ar_oObservable.push(...ar_projectData);
      }

      if (project.type === PROJECT_TYPE.SHORT) {
        const projectData = this._jiraconnector.getProjectProperty(project.id, 'p-cockpit-project-data');
        // ar_oObservable.push(projectData);

        const observable = new Observable((observer) => {

          const projectDataSubscribe =  projectData.subscribe(res => {
            projectDataSubscribe.unsubscribe();

            project.setType(PROJECT_TYPE.FULL);

            const ar_subBudget: SubBudget[] = [];
            if (res.value && res.value.subBudgets) {
              for (const oItem of res.value.subBudgets) {
                const oSubBudget = new SubBudget();
                Object.assign(oSubBudget, oItem);
                ar_subBudget.push(oSubBudget);
              }
            }
            project.subBudgets = ar_subBudget;

            const ar_virtualGroups: VirtualGroup[] = [];
            if (res.value && res.value.virtualGroups) {
              for (const oItem of res.value.virtualGroups) {
                const oVirtualGroup = VirtualGroup.create(oItem);
                oVirtualGroup.parentProject = project;

                ar_virtualGroups.push(oVirtualGroup);

              }
            }
            project.virtualGroups = ar_virtualGroups;

            for (const virtualGroup of project.virtualGroups) {
              this.getIssueKeysForVirtualGroup(virtualGroup).subscribe(res => {
                console.log(virtualGroup);
              });
            }

            this.projectsById[project.id] = project;

            observer.next(res);
            observer.complete();

          }, error => {
            console.log(project.name + ': Keine detaillierten Daten vorhanden.');

            observer.next(error);
            observer.complete();
          }, () => {
            console.log(project.name + ': Keine detaillierten Daten vorhanden.');

            observer.next('');
            observer.complete();
          }
          );
        });
        ar_oObservable.push(observable);

      }

    }
    return ar_oObservable;
  }

  getIssueKeysForVirtualGroup(virtualGroup: VirtualGroup): Observable<any> {
    const observable = new Observable((observer) => {

      let subscribeObserver;
      switch (virtualGroup.selection.type) {
        case CLASS_TYPE.JIRA_ISSUE:
          subscribeObserver = this._jiraconnector.getSearchIssueKeys('project = ' +
                              virtualGroup.parentProject.id + ' AND (issuetype = Epos OR issuetype = Epic)');
          break;

        case CLASS_TYPE.JIRA_VERSION:
          subscribeObserver = this._jiraconnector.getSearchIssueKeys('project = ' +
                              virtualGroup.parentProject.id + ' AND fixVersion = "' + virtualGroup.name + '"');
          break;

        case CLASS_TYPE.JIRA_VERSION:
          subscribeObserver = this._jiraconnector.getSearchIssueKeys('project = ' +
                              virtualGroup.parentProject.id + ' AND component = "' + virtualGroup.name + '"');
          break;

        default:
          break;
      }

      subscribeObserver.subscribe((json: any) => {
        const ar_oIssueKeys: string[] = [];
        for (const jsonIssue of json.issues) {
          ar_oIssueKeys.push(jsonIssue.key);
        }
        virtualGroup.assosiated_issue_keys = [...ar_oIssueKeys];

        observer.next(json);
        observer.complete();
      });

    });

    return observable;
  }

  getProjectEpics(project: Project) {
    this._jiraconnector.getSearch('project = ' + project.id + ' AND (issuetype = Epos OR issuetype = Epic)').subscribe((json: any) => {
      const ar_oIssue: JiraIssue[] = [];
      for (const jsonIssue of json.issues) {
        const issue = new JiraIssue();
        issue.id = jsonIssue.key;
        issue.name = jsonIssue.fields.summary;

        ar_oIssue.push(issue);
      }

      project.epics = ar_oIssue.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
    });
  }

  getProjectComponentsAndVersions(project: Project) {
    this._jiraconnector.getProject(project.id).subscribe((json: any) => {
      const ar_oComponent: JiraComponent[] = [];
      for (const jsonComponent of json.components) {
        const component = new JiraComponent();
        component.id = jsonComponent.id;
        component.name = jsonComponent.name;

        ar_oComponent.push(component);
      }

      project.components = ar_oComponent.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));

      const ar_oVersion: JiraVersion[] = [];
      for (const jsonVersion of json.versions) {
        const version = new JiraVersion();
        version.id = jsonVersion.id;
        version.name = jsonVersion.name;

        ar_oVersion.push(version);
      }

      project.versions = ar_oVersion.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));

    });
  }

  copyProdToDev() {
    this._jiraconnector.getCockpitProjectList('').subscribe((json: any) => {
      this._jiraconnector.putPCockpitProperty('p-cockpit-project-list', json.value).subscribe(complete => {
        this.getProjectsFromDataStore();

        this.projectSubject.subscribe(complete => {

          const ar_oAllProjects: Project[] = [];

          for (const project of this.projects) {
            ar_oAllProjects.push(project);
            for (const subProject of project.subProjects) {
              ar_oAllProjects.push(subProject);
            }
          }

          for (const project of ar_oAllProjects) {
            this._jiraconnector.getProjectProperty(project.id, 'p-cockpit-project-data', '').subscribe((json: any) => {
              this._jiraconnector.putProjectProperty(project.id, 'p-cockpit-project-data', json.value).subscribe(complete => {
                // do nothing
              });
            });
          }
        });
      });
    });
  }

}
