import { SECONDS_PER_MINUTE } from './constants';
import { CLASS_TYPE, WORKLOAD_UNIT } from './enums';
import { JiraComponent } from './JiraComponent';
import { JiraIssue } from './JiraIssue';
import { JiraVersion } from './JiraVersion';
import { Project } from './Project';

export class VirtualGroup {

    get name(): string {
        try {
            return this.selection.name;
        } catch (error) {
            return 'EMPTY';
        }
    }
    set name(value: string) {
        //
    }

    get id(): string {
        return this.parentProject.id + '___' + this.name.replace(' ', '').replace(/[^\w\s]/g, '').toUpperCase();
    }
    set id(value: string) {
        //
    }
    type = CLASS_TYPE.VIRTUAL_GROUP;

    color = '#C3CFD9';

    parentProject: Project = null;

    selection: JiraIssue | JiraComponent | JiraVersion;

    manually_planned: any = {};

    assosiated_issue_keys: string[] = [];

    static create(obj: any = null): VirtualGroup {
        const virtualGroup = new VirtualGroup();
        if (obj) {
            Object.assign(virtualGroup, obj);

            try {
                switch (obj.selection.type) {
                    case CLASS_TYPE.JIRA_ISSUE:
                      const oJiraIssue = new JiraIssue();
                      Object.assign(oJiraIssue, obj.selection);
                      virtualGroup.selection = oJiraIssue;
                      break;

                    case CLASS_TYPE.JIRA_COMPONENT:
                      const oJiraComponent = new JiraComponent();
                      Object.assign(oJiraComponent, obj.selection);
                      virtualGroup.selection = oJiraComponent;
                      break;

                    case CLASS_TYPE.JIRA_VERSION:
                      const oJiraVersion = new JiraVersion();
                      Object.assign(oJiraVersion, obj.selection);
                      virtualGroup.selection = oJiraVersion;
                      break;

                    default:
                      break;
                  }

            } catch (error) {

            }

        }
        return virtualGroup;
    }

    getPartName(maxPartLength: number = 15): string {
        if (this.name.length < maxPartLength) {
            return this.name;
        } else {
            return this.name.substr(0, maxPartLength) + '...';
        }
    }

    getWorkload(key: string, workload_unit: WORKLOAD_UNIT = WORKLOAD_UNIT.MINUTES_PER_WORKING_HOUR) {
        if (this.manually_planned[key] !== undefined) {
            return (1 * this.manually_planned[key]) / SECONDS_PER_MINUTE / workload_unit;
        } else {
            return 0;
        }
    }

    setManuallyPlanned(key: string, value: number) {
        this.manually_planned[key] = value;
    }

    toJSON() {
        const jsonObj = {
            id: this.id,
            name: this.name,
            color: this.color,
            selection: this.selection,
            manually_planned: this.manually_planned,
        };

        return jsonObj;
      }

}

