<div class="dialog-content-min-width">
  <h2 mat-dialog-title>Projekte hinzufügen</h2>

  <form class="dialog-content-form">
    <div>
      <mat-form-field class="dialog-content-field">
        <input matInput #searchField placeholder="Suche" [(ngModel)]="searchInput" name="search" (input)="search(searchField.value)"/>
        <div class="dialog-content-spinner">
          <mat-spinner [diameter]="25" *ngIf="state == VIEW_STATE.PENDING"></mat-spinner>
        </div>
      </mat-form-field>
      
    </div>
  </form>
  
  <mat-dialog-content class="mat-typography">
      <mat-selection-list #projectList [(ngModel)]="selectedOptions" (ngModelChange)="onNgModelChange($event)">
          <mat-list-option *ngFor="let project of projects" checkboxPosition="before" [value]="project">
            {{project.name}} ({{project.id}})
          </mat-list-option>
        </mat-selection-list>
        
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <mat-paginator [length]="100" #oPaginator
      [pageSize]="50"
      [pageSizeOptions]="[10, 25, 50]">
    </mat-paginator>
    <button mat-button mat-dialog-close>Abbrechen</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial (click)="insertProjects()">Hinzufügen</button>
  </mat-dialog-actions>
</div>

