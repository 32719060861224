<table mat-table [dataSource]="timesheet.timeSheetRows" multiTemplateDataRows>

    <ng-container matColumnDef="{{column}}" *ngFor="let column of timesheet.columnIds">
        <th mat-header-cell *matHeaderCellDef  
            [class.text-center]="column.indexOf('name') == -1" 
            [class.text-left]="column.indexOf('name') != -1"
            [class.weekend]="timesheet.isDayWeekend(timesheet.columnsInfo[column].title) == true" 
            [class.holiday]="isHolidayClass(timesheet, column)"
            class="column-{{timesheet.columnsInfo[column].class}}">
            <div [class.text-left]="column.indexOf('name') != -1">
                {{timesheet.columnsInfo[column].title}}
            </div>
        </th>

        <td mat-cell *matCellDef="let row" 
            [class.text-center]="column.indexOf('name') == -1" 
            [class.weekend]="timesheet.isDayWeekend(timesheet.columnsInfo[column].title) == true"
            [class.holiday]="isHolidayClass(timesheet, column)"
            [class.bold]="row.projectName.indexOf('rlaub') != -1 || row.projectName.indexOf('rank') != -1 || row.projectName.indexOf('umme') != -1 || column.indexOf('SUM') != -1">
            <div *ngIf="column.indexOf('name') != -1" class="text-left" style="white-space: nowrap; width: 29em">
                <div class="text-left vertical-middle" style="float: left; width: 50%; margin: 5% auto;">
                    {{row.projectName}}
                </div>

                <div style="margin-right: 1em; float: right; width: 40%" *ngIf="row.projectName.indexOf('umme') == -1 && row.projectName.indexOf('Krank') == -1 && row.projectName.indexOf('Urlaub') == -1">

                    <app-timesheet-row-funding-selector
                        [timesheetRow]="row"
                        [fundingProjects]="fundingProjects"
                        (refresh)="handleRefreshRow();">
                    </app-timesheet-row-funding-selector>
                    
                </div>
            </div>
            <div *ngIf="column.indexOf('name') == -1">
                {{row.getValue(timesheet.columnsInfo[column].key)}}
            </div>

        </td>

    </ng-container>

    <tr mat-header-row *matHeaderRowDef="timesheet.columnIds" ></tr>
    <tr mat-row *matRowDef="let element; columns: timesheet.columnIds;" class="example-element-row"></tr>

</table>



